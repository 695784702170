import React, { useEffect, useState } from 'react';
import { Card, CardContent, Checkbox, IconButton, Typography, Menu, MenuItem, Dialog, DialogTitle, DialogContent, DialogActions, Avatar, Table, TableBody, TableRow, TableCell } from '@material-ui/core';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { ShareOptionModal, DeleteOptionModal, DeleteFolderOptionModal, DownloadOptionModal, RestoreOptionModal, PermanentDeleteOptionModal, RestoreFolderOptionModal, PermanentDeleteFolderOptionModal } from './MyDocumentsCardsModal';  // Make sure the path is correct
import { useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { sharedDocumentStatus } from '../redux/coauthor/coauthor.action';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from '@material-ui/core';
import { checkDocAcceptance, getAllDocStatus, getDocumentbyId, updateDocumentStatus } from '../services/document';
import { Button } from 'react-bootstrap';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import TopicIcon from '@mui/icons-material/Topic';
import swal from "sweetalert";
import blogServices, { getBlogById, publishMyBlogs } from '../services/blogServices';
import { toast } from 'react-toastify';
import '../css/local.css'
import { getTeam } from '../services/userService';
import CircleIcon from '@mui/icons-material/Circle';
import PeopleIcon from '@mui/icons-material/People';
import "./MyDocumentsCards.css"
import { checkFeatureAccess } from './featureAccess';
import { addUserLogs } from '../services/userLogServices';
import { useCheckFeatureAccess } from './CheckFeature';
import { useCheckSubscriptionPlanAccess } from './SubscriptionAccess';






const LightTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.common.white,
        color: 'blue',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}))(Tooltip);




export const DocumentListCard = ({ handleFolderClick, document, description, title, onCardCheckboxChange, getAllDocuments, type, isCoAuthor, toStatus, userProfileColor, SubAdmin, statusHistory, restrictCollaboration }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);
    const [isPermanentDeleteModalOpen, setIsPermanentDeleteModalOpen] = useState(false);
    const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
    const [isRestoreFolderModalOpen, setIsRestoreFolderModalOpen] = useState(false);
    const [isPermanentDeleteFolderModalOpen, setIsPermanentDeleteFolderModalOpen] = useState(false);


    const [selectedId, setSelectedId] = useState("");
    const userDetails = useSelector((user) => user)
    const isSubAdmin = userDetails.user.currentUser.isSubAdmin

    const [openUsersStatusDialog, setOpenUsersStatusDialog] = useState(false);
    const [currentStatusIndex, setCurrentStatusIndex] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState('Shared');
    const [isEditMode, setIsEditMode] = useState(false);
    const [acceptStatus, setAcceptStatus] = useState("");
    const [rejected, setRejected] = useState(false);
    const [currentMode, setCurrentMode] = useState("Shared");
    const [sharedMode, setSharedMode] = useState([]);
    const [acceptedMode, setAcceptedMode] = useState([]);
    const [pendingMode, setPendingMode] = useState([]);
    const [rejectedMode, setRejectedMode] = useState([]);
    const [currentModeCount, setCurrentModeCount] = useState("");
    const [currentModeUser, setCurrentModeUser] = useState([]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [activeStatusIndex, setActiveStatusIndex] = useState(0);
    const currentNavItem = useSelector((state) => state.manageCoauthorNavReducer.currentCoauthorNavItem);
    console.log(type, toStatus, "typecheck")
    let statusSequence;
    if (type == "MyDocumentsSubadmin") {
        statusSequence = [
            'Open',
            'Pending Acceptance',
            'Review In Progress',
            'Review Completed',
            'Accepted',
            'Approved',
            'Rejected'
        ];
    } else if (type == "SharedWithMe") {
        statusSequence = [
            'Open',
            'Pending Acceptance',
            'Review In Progress',
            'Review Completed',
            'Approved',
            'Rejected'
        ];
    }

    const accessToShare = useCheckFeatureAccess(currentNavItem, 'share')
    const accessToDelete = useCheckFeatureAccess(currentNavItem, 'delete')
    const accessToAcceptRejectDoc = useCheckFeatureAccess(currentNavItem, 'AcceptRejectDoc')
    const accessToEditSharedDoc = useCheckFeatureAccess(currentNavItem, 'EditSharedDoc')
    const accessToCollaboration = useCheckFeatureAccess(currentNavItem, 'Collaboration')
    const accessToComplate = useCheckFeatureAccess(currentNavItem, 'complete')
    const accessToPublishAsBlog = useCheckFeatureAccess(currentNavItem, 'publishAsBlog')
    const accessToRestore = useCheckFeatureAccess(currentNavItem, 'restore')
    const accessToPermanantDelete = useCheckFeatureAccess(currentNavItem, 'deletePermanantly')


    // Find the index of the current toStatus in the statusSequence array
    useEffect(() => {
        if (statusSequence) {
            const index = statusSequence.indexOf(toStatus);
            if (index >= 0) {
                setActiveStatusIndex(index);
            }
        }
    }, [toStatus]);

    // Function to get color based on status.
    const getStatusColor = (status) => {
        switch (status) {
            case 'Pending Acceptance':
                return '#f5b706';
            case 'Review In Progress':
                return '#ea40bc';
            case 'Review Completed':
                return '#a506f5';
            case 'In progress':
                return '#a506f5';
            case 'Completed':
                return '#08b512';
            case 'Approved':
                return '#FF5733';
            case 'Rejected':
                return 'red';
            case 'Accepted':
                return 'green';
            case 'Open':
                return 'blue';
            default:
                return '#000000';
        }
    };

    // Create filteredStatusSequence to include only relevant statuses.
    // Create filteredStatusSequence to include only relevant statuses.
    let filteredStatusSequence;
    if (statusSequence) {
        filteredStatusSequence = statusSequence.filter((status, index) => {
            if (toStatus === 'Rejected') {
                // Exclude "Approved" when "Rejected" is the toStatus
                return index <= activeStatusIndex && status !== 'Approved';
            } else if (toStatus === 'Approved') {
                // Include up to and including "Approved"
                return index <= activeStatusIndex;
            }
            // For any other status, include everything
            return true;
        });
    }


    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    let Ownername = document.ownerName
    let documentId = document._id

    const history = useHistory();
    const dispatch = useDispatch();

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    };

    // Function to determine how to display the updated date
    const getUpdatedDateDisplay = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        if (date.toDateString() === today.toDateString()) {
            return <p style={{ color: 'grey' }}><b>Last updated today</b></p>;
        } else if (date.toDateString() === yesterday.toDateString()) {
            return <p style={{ color: 'grey' }}><b>Last updated yesterday</b></p>;
        } else {
            return <p style={{ color: '#1976d2' }}><b className="dateLabelcolor">Updated:</b> {formatDate(dateString)}</p>;
        }
    };
    const accessToDocumentScreenCollaboration = useCheckSubscriptionPlanAccess("Collaboration")
    const accessToCoAuthorScreenCollaboration = useCheckSubscriptionPlanAccess("Collaboration")

    const handleMenuItemClick = (action) => {
        setSelectedId(document._id)
        if (action === 'Share') {

            if (currentNavItem == "Documents") {
                if (!accessToDocumentScreenCollaboration) {
                    restrictCollaboration()
                    return
                }

            }
            if (currentNavItem == "coAuthor") {
                if (!accessToCoAuthorScreenCollaboration) {
                    restrictCollaboration()
                    return
                }

            }
            setIsShareModalOpen(true);
        }
        if (action === 'Delete') {
            setIsDeleteModalOpen(true);
        }
        if (action === 'Download') {
            setIsDownloadModalOpen(true);
        }
        if (action === 'Restore') {
            setIsRestoreModalOpen(true);
        }
        if (action === 'PermanentDelete') {
            setIsPermanentDeleteModalOpen(true);
        }
        if (action === 'DeleteFolder') {
            setIsDeleteFolderModalOpen(true);
        }
        if (action === 'RestoreFolder') {
            setIsRestoreFolderModalOpen(true);
        }
        if (action === 'PermanentDeleteFolder') {
            setIsPermanentDeleteFolderModalOpen(true);
        }

        handleMenuClose();
    };

    const closeShareModal = () => {
        setIsShareModalOpen(false);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const closeDeleteFolderModal = () => {
        setIsDeleteFolderModalOpen(false);
    };

    const closeDownloadModal = () => {
        setIsDownloadModalOpen(false);
    };

    const closeRestoreModal = () => {
        setIsRestoreModalOpen(false);
    };

    const closeRestoreFolderModal = () => {
        setIsRestoreFolderModalOpen(false);
    };

    const closePermanentDeleteModal = () => {
        setIsPermanentDeleteModalOpen(false);
    };

    const closePermanentDeleteFolderModal = () => {
        setIsPermanentDeleteFolderModalOpen(false);
    };


    const handleStatusButtonClick = (status) => {
        setSelectedStatus(status);
        switch (status) {
            case 'Shared':
                setCurrentModeCount(sharedMode.sharedCount)
                setCurrentMode('Shared');
                setCurrentModeUser(sharedMode.sharedUsers);
                break;
            case 'Accepted':
                setCurrentModeCount(acceptedMode.acceptedCount)
                setCurrentMode('Accepted');
                setCurrentModeUser(acceptedMode.acceptedUsers);
                break;
            case 'Rejected':
                setCurrentModeCount(rejectedMode.rejectedCount)
                setCurrentMode('Rejected');
                setCurrentModeUser(rejectedMode.rejectedUsers);
                break;
            case 'Pending':
                setCurrentModeCount(pendingMode.pendingCount)
                setCurrentMode('Pending');
                setCurrentModeUser(pendingMode.pendingUsers);
                break;
            default:
                break;
        }
    };

    const handleRejectClick = () => {
        setAcceptStatus("false");
        handleFetchStatus("false");
        setRejected(true);
    };

    const handleCheckClick = () => {
        setIsEditMode(true);
        setAcceptStatus("true");
        handleFetchStatus("true");
    };

    const handleFetchStatus = async (status) => {
        let payload = {
            docId: document._id,
            acceptanceStatus: status,
        }
        let response = await checkDocAcceptance(payload);
        if (response.status == 200) {
            getAllDocuments()
            const data = {
                userId: sessionStorage.getItem("userId"),
                organizationId: sessionStorage.getItem("organizationId"),
                actionType: status == true ? "acceptDoc" : "rejetDoc",
            };

            const addLogs = await addUserLogs(data);
            if (addLogs.status !== 200) {
                toast.error(addLogs.message);
            }
        }
    }

    const handleComplete = async () => {
        let payload = {
            docId: document._id,
        }
        let response = await updateDocumentStatus(payload);
        console.log("response", response);
        // if (response.status == 200){
        //     getAllDocuments()
        // }
    }



    const handleUsersStatusOpen = async () => {
        setOpenUsersStatusDialog(true);
        let payload = {
            docId: document._id,
        }
        let data = await getAllDocStatus(payload);
        setSharedMode(data?.shared)
        setCurrentModeUser(data?.shared?.sharedUsers)
        setCurrentModeCount(data?.shared?.sharedCount)
        setAcceptedMode(data?.accepted)
        setRejectedMode(data?.rejected)
        setPendingMode(data?.pending)
    };


    const handleCardClick = () => {
        if (isSubAdmin) {
            return
        }
        if (type === "MyDocuments") {
            dispatch(sharedDocumentStatus(false))
            history.push(`/viewmydocuments/${document._id}`, { data: isCoAuthor });
        }
        if (type === "SharedWithMe") {

            dispatch(sharedDocumentStatus(true))
            history.push(`/viewmydocuments/${document._id}`, { data: isCoAuthor });
        }
    };

    const handleUsersStatusClose = () => {
        setOpenUsersStatusDialog(false);
    };




    const handleTeamCollab = async (data) => {

        let check = await getDocumentbyId(data._id);
        if (check.status === 200) {
            if (check.data.document.activeDoc === true) {
                history.push({
                    pathname: "/collaboration",
                    state: { document: data },
                });
            } else {
                alert("Document has been deleted");
                getAllDocuments();
            }
        } else {
            alert("Document has been deleted");
            getAllDocuments();
        }

    };

    const handlePublish = async (data) => {
        swal({
            title: "Send Blog for Admin Approval",
            text:
                "Are you sure ? You want to send the blog for Admin approval in-order to publish this blog",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willPublish) => {
            if (willPublish) {
                console.log("willPublish", willPublish, data);
                let payload = {
                    documentName: data.documentName,
                    documentType: "blog"
                }
                const documentId = await blogServices.saveBlogs(payload);

                const textWithoutHTML = data.onlyDocumentContent.replace(/<[^>]*>/g, '');
                const textWithNewlines = textWithoutHTML.replace(/<br\s*\/?>/gi, '\n');
                console.log("textWithoutHTML", textWithoutHTML, textWithNewlines);
                const formattedText = {
                    blocks: [
                        {
                            key: "9ukr0",
                            text: textWithNewlines,
                            type: "unstyled",
                            depth: 0,
                            inlineStyleRanges: [],
                            entityRanges: [],
                            data: {}
                        }
                    ],
                    entityMap: {}
                };
                const formattedContent = JSON.stringify(formattedText)
                console.log("fomattedtext", formattedText, formattedContent);

                if (documentId !== null) {
                    let autoBlogPayload = {
                        documentName: data.documentName,
                        documentId: documentId,
                        onlyDocumentContent: data.onlyDocumentContent,
                        formattedDocumentContent: data.onlyDocumentContent,
                    }
                    const { blog, status } = await blogServices.autoSaveBlog(autoBlogPayload);
                    console.log("blogstatus", blog._id, status);
                    if (status === 200) {
                        let blogId = await getBlogById(blog._id);
                        console.log("blogid", blogId);
                        if (blogId.status === 200) {
                            if (blogId.data.activeDoc === true) {
                                let responseBlog = await publishMyBlogs(blog._id);
                                toast.success("Blog sent for approval!");
                                console.log(responseBlog);
                            }
                            //   getAllBlog("my");
                        }
                    }
                }
            }
            console.log("documentId1", documentId);
            handleMenuClose();

        });
    };

    return (
        <Card
            className='cardStyle'
            style={{
                borderRadius: '8px',
                marginBottom: '10px',
                backgroundColor: 'white',
                cursor: 'pointer',
                // height: '110px'
            }}
        >

            <CardContent className='bgCardColor' style={{ position: 'relative', padding: '5px' }}>

                {(type !== "SharedWithMe") && !SubAdmin &&
                    <Checkbox
                        style={{ position: 'absolute', top: '5px', left: '12px' }}
                        checked={document.isChecked || false}
                        onChange={onCardCheckboxChange}
                    />}
                {(document.documentType === "folder") ?
                    <div style={{ marginLeft: '50px', paddingTop: '13px', display: 'flex' }} onClick={() => type !== "DeletedDocuments" && handleFolderClick(document._id)}>
                        <TopicIcon fontSize='large' />
                        <Typography variant="body1" style={{ marginTop: "4px", marginLeft: '15px' }}><b>{document.folderName}</b></Typography>
                    </div>
                    :
                    <div onClick={() => handleCardClick()}>
                        <div style={{ marginLeft: (type !== "SharedWithMe") ? '40px' : '18px', marginTop: '10px' }} >
                            <Typography variant="body1"><b>{document.documentName}</b></Typography>
                        </div>
                        <Typography variant="body2" style={{ fontSize: '12px', marginLeft: '18px', marginTop: "3px", width: "50%" }}>{description.slice(0, 120).replace(/<[^>]*>/g, '')}</Typography>

                        {(type == "MyDocuments" || type == "SharedWithMe") && <>
                            <div style={{ display: 'flex', marginTop: '3px', marginLeft: '15px' }}>
                                <CircleIcon style={{
                                    color:
                                        toStatus === 'Pending Acceptance' ? '#f5b706' :
                                            toStatus === 'Review In Progress' ? '#a506f5' :
                                                toStatus === 'Review Completed' ? '#a506f5' :
                                                    toStatus === 'Completed' ? '#08b512' :
                                                        toStatus === 'In progress' ? '#a506f5' :
                                                            toStatus === 'Rejected' ? 'red' :
                                                                toStatus === 'View Only' ? 'blue' :
                                                                    toStatus === 'Approved' ? '#FF5733' :
                                                                        toStatus === 'Published' ? '#ea40bc' :
                                                                            toStatus === 'Deleted' ? '#1976d2' :
                                                                                toStatus === 'Open' ? 'blue' : '#000000'
                                }} />
                                <Typography variant="body1" style={{
                                    fontSize: '12px', marginLeft: '7px', marginTop: '1px',
                                }}>
                                    <b>{toStatus}</b>
                                </Typography>
                            </div>
                        </>}


                        {type === "MyDocumentsSubadmin" && (
                            <div style={{ display: 'flex', marginTop: "20px" }}>
                                {document?.statusHistory?.map((statusObj, index) => {
                                    const { status, timestamp } = statusObj;

                                    return (
                                        <div
                                            key={statusObj._id}
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                marginTop: '3px',
                                                marginLeft: '15px',
                                                opacity: filteredStatusSequence.includes(status) ? 1 : 0.5, // Show only if the status is part of filteredStatusSequence
                                            }}
                                        >
                                            <CircleIcon
                                                style={{
                                                    color: getStatusColor(status),
                                                }}
                                            />
                                            <div style={{ marginLeft: '7px' }}>
                                                <Typography
                                                    variant="body1"
                                                    style={{
                                                        fontSize: '12px',
                                                        fontWeight: status === toStatus ? 'bold' : 'normal',
                                                    }}
                                                >
                                                    {status}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    style={{
                                                        fontSize: '10px',
                                                        color: '#666',
                                                    }}
                                                >
                                                    {new Date(timestamp).toLocaleString()}
                                                </Typography>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        )}



                        <Typography variant="body1" style={{
                            fontSize: '10px', marginLeft: '18px', marginTop: '3px',
                            color:
                                toStatus === 'Pending Acceptance' ? '#f5b706' :
                                    toStatus === 'Review In Progress' ? '#a506f5' :
                                        toStatus === 'Review Completed' ? '#a506f5' :
                                            toStatus === 'Completed' ? '#08b512' :
                                                toStatus === 'In progress' ? '#a506f5' :
                                                    toStatus === 'Rejected' ? 'red' :
                                                        toStatus === 'View Only' ? 'blue' :
                                                            toStatus === 'Approved' ? '#FF5733' :
                                                                toStatus === 'Published' ? '#ea40bc' :
                                                                    toStatus === 'Deleted' ? '#1976d2' :
                                                                        toStatus === 'Open' ? 'blue' : '#000000'
                        }}><b>{Ownername}</b></Typography>
                    </div>
                }

                {type !== "SharedWithMe" ?

                    <>
                        {type !== "DeletedDocuments" && (
                            (document.documentType !== "folder") &&
                            <LightTooltip title="User Details">
                                <IconButton style={{ position: 'absolute', top: '18px', right: '55px' }} onClick={handleUsersStatusOpen}>
                                    <PeopleIcon style={{
                                        width: '20px', height: '20px',
                                        color: 'blue'
                                    }} />

                                </IconButton>
                            </LightTooltip>

                        )}
                        {type !== "SharedWithMe" && (
                            toStatus === "Completed" || toStatus === "Rejected" || toStatus === "" && (

                                <IconButton disabled={!accessToDelete} style={{ position: 'absolute', top: '18px', right: '10px' }} onClick={() => handleMenuItemClick('DeleteFolder')}>
                                    <DeleteIcon style={{
                                        color:
                                            !accessToDelete ? "gray" : (
                                                toStatus === 'Pending Acceptance' ? '#f5b706' :
                                                    toStatus === 'Review In Progress' ? '#a506f5' :
                                                        toStatus === 'Review Completed' ? '#a506f5' :
                                                            toStatus === 'Completed' ? '#08b512' :
                                                                toStatus === 'In progress' ? '#a506f5' :
                                                                    toStatus === 'Rejected' ? 'red' :
                                                                        toStatus === 'View Only' ? 'blue' :
                                                                            toStatus === 'Approved' ? '#FF5733' :
                                                                                toStatus === 'Published' ? '#ea40bc' :
                                                                                    toStatus === 'Deleted' ? '#1976d2' :
                                                                                        toStatus === 'Open' ? 'blue' : '#000000'
                                            )
                                    }} />
                                </IconButton>
                            )

                        )
                        }

                        {(document.documentType === "folder") ?
                            (type === "DeletedDocuments" ? (
                                <IconButton style={{ position: 'absolute', top: '18px', right: '10px' }} onClick={handleMenuClick}>
                                    <MoreVertIcon className='MoreVertIcon' />
                                </IconButton>
                            ) : (
                                <IconButton disabled={!accessToDelete} style={{ position: 'absolute', top: '18px', right: '10px', color: "red" }} onClick={() => handleMenuItemClick('DeleteFolder')}>
                                    <LightTooltip title="Delete">
                                        <DeleteIcon style={{
                                            color:
                                                !accessToDelete ? "gray" : (
                                                    toStatus === 'Pending Acceptance' ? '#f5b706' :
                                                        toStatus === 'Review In Progress' ? '#a506f5' :
                                                            toStatus === 'Review Completed' ? '#a506f5' :
                                                                toStatus === 'Completed' ? '#08b512' :
                                                                    toStatus === 'In progress' ? '#a506f5' :
                                                                        toStatus === 'Rejected' ? 'red' :
                                                                            toStatus === 'View Only' ? 'blue' :
                                                                                toStatus === 'Approved' ? '#FF5733' :
                                                                                    toStatus === 'Published' ? '#ea40bc' :
                                                                                        toStatus === 'Deleted' ? '#1976d2' :
                                                                                            toStatus === 'Open' ? 'blue' : "red"
                                                )
                                        }} />
                                    </LightTooltip>
                                </IconButton>
                            ))
                            :
                            (!SubAdmin &&
                                <IconButton style={{ position: 'absolute', top: '18px', right: '10px' }} onClick={handleMenuClick}>
                                    <MoreVertIcon className='MoreVertIcon' />
                                </IconButton>
                            )
                        }
                    </>
                    :
                    (toStatus !== "Rejected" && !SubAdmin && (
                        !isEditMode && toStatus !== "In progress" && !document.readOnly && toStatus !== "Completed" ? (
                            <LightTooltip title="Reject">
                                <IconButton disabled={!accessToAcceptRejectDoc} onClick={handleRejectClick} style={{ position: 'absolute', right: '10px', top: '23px', color: accessToAcceptRejectDoc ? 'red' : "gray" }}>
                                    <ClearIcon />
                                </IconButton>
                            </LightTooltip>
                        ) : ""
                        // (
                        //     <LightTooltip title="Delete">
                        //         <IconButton style={{ position: 'absolute', top: '18px', right: '10px' }} onClick={() => handleMenuItemClick('Delete')}>
                        //             <DeleteIcon />
                        //         </IconButton>
                        //     </LightTooltip>
                        // )
                    ))}

                {/* {toStatus === "Rejected" && (
                                        <LightTooltip title="Delete">
                                            <IconButton style={{ position: 'absolute', top: '18px', right: '10px' }} onClick={() => handleMenuItemClick('Delete')}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </LightTooltip>
                                    )} */}


                {type === "MyDocuments" && !SubAdmin && windowWidth > 351 && (
                    document.activeDoc && document.pendingDocuments?.length > 0 ? (

                        <LightTooltip title="Collaboration">
                            <i
                                className="fa fa-asterisk blurb-ripple-out point"
                                style={{ fontSize: "11px", color: accessToCollaboration ? "#00e673" : "gray", position: 'absolute', top: '36px', right: '120px' }}
                                onClick={() => accessToCollaboration && handleTeamCollab(document)}
                                aria-hidden="true"
                            ></i>


                        </LightTooltip>
                    ) : (
                        ""
                    )
                )}



                {type === "SharedWithMe" && toStatus !== "Rejected" && !SubAdmin && (
                    document.readOnly ? (
                        <LightTooltip title="Read only">
                            <IconButton style={{ position: 'absolute', top: '20px', right: '60px', color: 'grey' }} onClick={() => handleCardClick()}>
                                <RemoveRedEyeIcon style={{
                                    color:
                                        toStatus === 'Pending Acceptance' ? '#f5b706' :
                                            toStatus === 'Review In Progress' ? '#a506f5' :
                                                toStatus === 'Review Completed' ? '#a506f5' :
                                                    toStatus === 'Completed' ? '#08b512' :
                                                        toStatus === 'In progress' ? '#a506f5' :
                                                            toStatus === 'Rejected' ? 'red' :
                                                                toStatus === 'View Only' ? 'blue' :
                                                                    toStatus === 'Approved' ? '#FF5733' :
                                                                        toStatus === 'Published' ? '#ea40bc' :
                                                                            toStatus === 'Deleted' ? '#1976d2' :
                                                                                toStatus === 'Open' ? 'blue' : '#000000'
                                }} />
                            </IconButton>
                        </LightTooltip>)
                        :
                        (
                            !isEditMode && toStatus === "Pending Acceptance" ? (
                                <LightTooltip title="Accept">
                                    <IconButton disabled={!accessToAcceptRejectDoc} onClick={handleCheckClick} style={{ position: 'absolute', top: '23px', right: '60px', color: accessToAcceptRejectDoc ? 'green' : "gray" }}>
                                        <CheckIcon />
                                    </IconButton >
                                </LightTooltip>
                            ) : (
                                toStatus !== "Completed" && (
                                    <LightTooltip title="Edit access">
                                        <IconButton disabled={!accessToEditSharedDoc} style={{ position: 'absolute', top: '20px', right: '60px', color: 'grey' }} onClick={() => handleCardClick()}>
                                            <EditIcon style={{
                                                color:
                                                    !accessToEditSharedDoc ? 'gray' : (toStatus === 'Pending Acceptance' ? '#f5b706' :
                                                        toStatus === 'Review In Progress' ? '#a506f5' :
                                                            toStatus === 'Review Completed' ? '#a506f5' :
                                                                toStatus === 'Completed' ? '#08b512' :
                                                                    toStatus === 'In progress' ? '#a506f5' :
                                                                        toStatus === 'Rejected' ? 'red' :
                                                                            toStatus === 'View Only' ? 'blue' :
                                                                                toStatus === 'Approved' ? '#FF5733' :
                                                                                    toStatus === 'Published' ? '#ea40bc' :
                                                                                        toStatus === 'Deleted' ? '#1976d2' :
                                                                                            toStatus === 'Open' ? 'blue' : '#000000'
                                                    )
                                            }} />
                                        </IconButton>
                                    </LightTooltip>
                                )
                            )))
                }

                {/* Menu */}
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    {type === "MyDocuments" &&
                        (<>
                            {toStatus !== "Review In Progress" && toStatus !== "Review Completed" && toStatus !== "Published" && toStatus !== "Approved" && !isSubAdmin &&
                                <MenuItem onClick={() => handleMenuItemClick('Share')} disabled={!accessToShare}>Share</MenuItem>
                            }
                            <MenuItem onClick={() => handleMenuItemClick('Download')}>Download</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Delete')} disabled={!accessToDelete}>Delete</MenuItem>
                            {toStatus === "Approved" &&
                                <MenuItem onClick={handleComplete} disabled={!accessToComplate}>Complete</MenuItem>
                            }
                            {toStatus === "Completed" &&
                                <MenuItem onClick={() => handlePublish(document)} disabled={!accessToPublishAsBlog}>Publish</MenuItem>
                            }
                        </>
                        )}

                    {type === "SharedWithMe" && (
                        <>
                            <MenuItem onClick={() => handleMenuItemClick('Download')}>Download</MenuItem>
                            <MenuItem onClick={() => handleMenuItemClick('Delete')}>Delete</MenuItem>
                        </>
                    )}

                    {type === "DeletedDocuments" &&
                        (<>
                            <MenuItem disabled={!accessToRestore} onClick={() => { document.documentType === "folder" ? handleMenuItemClick('RestoreFolder') : handleMenuItemClick('Restore') }}>Restore</MenuItem>
                            <MenuItem disabled={!accessToPermanantDelete} onClick={() => { document.documentType === "folder" ? handleMenuItemClick('PermanentDeleteFolder') : handleMenuItemClick('PermanentDelete') }}>Delete Permanently</MenuItem>
                        </>)}
                </Menu>

                <Dialog open={openUsersStatusDialog} onClose={handleUsersStatusClose}>
                    <DialogContent>
                        <DialogActions style={{ display: "flex", justifyContent: "space-between", flexDirection: windowWidth < 426 ? "column" : "row", padding: '0px', }}>
                            <div style={{ width: windowWidth < 426 ? "100%" : "49%", display: "flex", padding: '0px', justifyContent: "space-between", marginBottom: windowWidth < 426 ? "8px" : "0px", }}>
                                <Button
                                    style={{ backgroundColor: selectedStatus === 'Shared' ? '#3f51b5' : 'initial', color: selectedStatus === 'Shared' ? 'white' : "#3f51b5", width: "49%", border: '1.5px solid #3f51b5', boxShadow: "none" }}
                                    onClick={() => handleStatusButtonClick('Shared')}
                                >
                                    Shared
                                </Button>
                                <Button
                                    style={{ backgroundColor: selectedStatus === 'Accepted' ? '#08b512' : 'initial', color: selectedStatus === 'Accepted' ? 'white' : "#08b512", width: "49%", border: '1.5px solid #08b512', boxShadow: "none" }}
                                    onClick={() => handleStatusButtonClick('Accepted')}
                                >
                                    Accepted
                                </Button>
                            </div>
                            <div style={{ width: windowWidth < 426 ? "100%" : "49%", display: "flex", padding: '0px', justifyContent: "space-between", marginLeft: "0px" }}>
                                <Button
                                    style={{ backgroundColor: selectedStatus === 'Rejected' ? 'red' : "initial", color: selectedStatus === 'Rejected' ? 'white' : "red", width: "49%", border: '1.5px solid red', boxShadow: "none" }}
                                    onClick={() => handleStatusButtonClick('Rejected')}
                                >
                                    Rejected
                                </Button>
                                <Button className='userProfile'
                                    style={{ backgroundColor: selectedStatus === 'Pending' ? '#f5b706' : "initial", color: selectedStatus === 'Pending' ? 'white' : "#f5b706", width: "49%", border: '1.5px solid #f5b706', boxShadow: "none" }}
                                    onClick={() => handleStatusButtonClick('Pending')}
                                >
                                    Pending
                                </Button>
                            </div>
                        </DialogActions>
                        <div style={{ display: 'flex', marginTop: '10px' }}>
                            <Typography variant="body1" style={{ fontSize: '14px' }}> {selectedStatus} User Details</Typography>

                            <Typography variant="body1" style={{ fontSize: '14px', marginLeft: 'auto' }}>Count:{currentModeCount}</Typography>
                        </div>
                        <Table style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}>
                            <TableBody>
                                <TableRow>
                                    <TableCell><b>Name</b></TableCell>
                                    <TableCell><b>Role</b></TableCell>
                                </TableRow>
                                {currentModeUser?.map((user, index) => (
                                    <TableRow key={index}>
                                        <TableCell>{user.username}</TableCell>
                                        <TableCell>{user.userRole}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleUsersStatusClose}>Cancel</Button>
                    </DialogActions>
                </Dialog>

                {isShareModalOpen && <ShareOptionModal show={isShareModalOpen} onHide={closeShareModal} id={selectedId} />}
                <DeleteOptionModal show={isDeleteModalOpen} onHide={closeDeleteModal} id={selectedId} getAllDocuments={getAllDocuments} />
                <DeleteFolderOptionModal show={isDeleteFolderModalOpen} onHide={closeDeleteFolderModal} id={selectedId} getAllDocuments={getAllDocuments} />
                <DownloadOptionModal show={isDownloadModalOpen} onHide={closeDownloadModal} id={selectedId} />
                <RestoreOptionModal show={isRestoreModalOpen} onHide={closeRestoreModal} id={selectedId} getAllDocuments={getAllDocuments} />
                <PermanentDeleteOptionModal show={isPermanentDeleteModalOpen} onHide={closePermanentDeleteModal} id={selectedId} getAllDocuments={getAllDocuments} />
                <RestoreFolderOptionModal show={isRestoreFolderModalOpen} onHide={closeRestoreFolderModal} id={selectedId} getAllDocuments={getAllDocuments} />
                <PermanentDeleteFolderOptionModal show={isPermanentDeleteFolderModalOpen} onHide={closePermanentDeleteFolderModal} id={selectedId} getAllDocuments={getAllDocuments} />
                {/* <div style={{position:'absolute', bottom:'35px', right:'200px'}}>
                                    <span style={{fontSize:'10px', display:'flex'}}><p style={{color:'blue'}}><b style={{color:'black'}}>Created:</b> {formatDate(document.creationDate)}</p></span>
                                    <span style={{fontSize:'10px', display:'flex'}}> {getUpdatedDateDisplay(document.date)}</span>
                </div> */}
            </CardContent>
            {windowWidth <= 800 && document.documentType !== "folder" &&
                <div style={{ marginLeft: "20px", marginBottom: "18px" }}>
                    <span style={{ fontSize: '10px', display: 'flex' }}><p style={{ color: '#1976d2' }}><b className="dateLabelcolor" >Created:</b> {formatDate(document.creationDate)}</p></span>
                    <span style={{ fontSize: '10px', display: 'flex' }}> {getUpdatedDateDisplay(document.date)}</span>
                </div>
            }
            {windowWidth > 800 && document.documentType !== "folder" &&
                <div style={{ position: 'absolute', bottom: '45%', right: '250px', }}>
                    <span style={{ fontSize: '10px', display: 'flex' }}><p style={{ color: '#1976d2' }}><b className="dateLabelcolor" >Created:</b> {formatDate(document.creationDate)}</p></span>
                    <span style={{ fontSize: '10px', display: 'flex' }}> {getUpdatedDateDisplay(document.date)}</span>
                </div>
            }

            {windowWidth < 352 &&
                <>
                    {type === "MyDocuments" && !SubAdmin && (
                        document.activeDoc && document.pendingDocuments?.length > 0 ? (

                            <LightTooltip title="Collaboration">
                                <i
                                    className="fa fa-asterisk blurb-ripple-out point"
                                    style={{ fontSize: "11px", color: accessToCollaboration ? "#00e673" : "gray", position: 'absolute', top: '140px', right: '60px' }}
                                    onClick={() => accessToCollaboration && handleTeamCollab(document)}
                                    aria-hidden="true"
                                ></i>


                            </LightTooltip>
                        ) : (
                            ""
                        )
                    )}
                </>
            }
        </Card>
    );
};



export const DocumentGridCard = ({ handleFolderClick, document, description, onCardCheckboxChange, getAllDocuments, type, isCoAuthor, toStatus, setMyDocs, userProfileColor, SubAdmin, statusHistory, restrictCollaboration }) => {

    const [anchorEl, setAnchorEl] = useState(null);
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);
    const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false);
    const [isPermanentDeleteModalOpen, setIsPermanentDeleteModalOpen] = useState(false);
    const [isDeleteFolderModalOpen, setIsDeleteFolderModalOpen] = useState(false);
    const [isRestoreFolderModalOpen, setIsRestoreFolderModalOpen] = useState(false);
    const [isPermanentDeleteFolderModalOpen, setIsPermanentDeleteFolderModalOpen] = useState(false);

    const [selectedId, setSelectedId] = useState("");
    const userDetails = useSelector((user) => user)
    const isSubAdmin = userDetails.user.currentUser.isSubAdmin

    const [openUsersStatusDialog, setOpenUsersStatusDialog] = useState(false);
    const [currentStatusIndex, setCurrentStatusIndex] = useState(0);
    const [selectedStatus, setSelectedStatus] = useState('Shared');
    const [isEditMode, setIsEditMode] = useState(false);
    const [acceptStatus, setAcceptStatus] = useState("");
    const [rejected, setRejected] = useState(false);
    const [currentMode, setCurrentMode] = useState("Shared");
    const [sharedMode, setSharedMode] = useState([]);
    const [acceptedMode, setAcceptedMode] = useState([]);
    const [pendingMode, setPendingMode] = useState([]);
    const [rejectedMode, setRejectedMode] = useState([]);
    const [currentModeCount, setCurrentModeCount] = useState("");
    const [currentModeUser, setCurrentModeUser] = useState([]);
    const { leftNavbarOpen } = useSelector(state => state.handleModelAndSideBavBar);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const currentNavItem = useSelector((state) => state.manageCoauthorNavReducer.currentCoauthorNavItem);
    useEffect(() => {
        const handleResize = () => setWindowWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    console.log(leftNavbarOpen, "))leftNavbarOpen")

    const history = useHistory();
    const dispatch = useDispatch();

    let Ownername = document.ownerName
    let documentId = document._id
    let readOnly = document.readOnly


    const handleUsersStatusClose = () => {
        setOpenUsersStatusDialog(false);
    };

    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'short' });
        const year = date.getFullYear();
        return `${day} ${month}, ${year}`;
    };

    // Function to determine how to display the updated date
    const getUpdatedDateDisplay = (dateString) => {
        const date = new Date(dateString);
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);

        if (date.toDateString() === today.toDateString()) {
            return <p style={{ color: 'grey' }}><b>Last updated today</b></p>;
        } else if (date.toDateString() === yesterday.toDateString()) {
            return <p style={{ color: 'grey' }}><b>Last updated yesterday</b></p>;
        } else {
            return <p style={{ color: '#1976d2' }}><b className="dateLabelcolor">Updated:</b> {formatDate(dateString)}</p>;
        }
    };

    const accessToDocumentScreenCollaboration = useCheckSubscriptionPlanAccess("Collaboration")
    const accessToCoAuthorScreenCollaboration = useCheckSubscriptionPlanAccess("Collaboration")


    const handleMenuItemClick = (action) => {
        setSelectedId(documentId)
        if (action === 'Share') {
            if (currentNavItem == "Documents") {
                if (!accessToDocumentScreenCollaboration) {
                    restrictCollaboration()
                    return
                }

            }
            if (currentNavItem == "coAuthor") {
                if (!accessToCoAuthorScreenCollaboration) {
                    restrictCollaboration()
                    return
                }

            }
            setIsShareModalOpen(true);
        } if (action === "Delete") {
            setIsDeleteModalOpen(true)
        } if (action === 'Download') {
            setIsDownloadModalOpen(true);
        } if (action === 'Restore') {
            setIsRestoreModalOpen(true);
        } if (action === 'PermanentDelete') {
            setIsPermanentDeleteModalOpen(true);
        } if (action === 'DeleteFolder') {
            setIsDeleteFolderModalOpen(true);
        } if (action === 'RestoreFolder') {
            setIsRestoreFolderModalOpen(true);
        } if (action === 'PermanentDeleteFolder') {
            setIsPermanentDeleteFolderModalOpen(true);
        }
        handleMenuClose();
    };

    const closeShareModal = () => {
        setIsShareModalOpen(false);
        handleFetchStatus()
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const closeDeleteFolderModal = () => {
        setIsDeleteFolderModalOpen(false);
    };

    const closeDownloadModal = () => {
        setIsDownloadModalOpen(false);
    };

    const closeRestoreModal = () => {
        setIsRestoreModalOpen(false);
    };

    const closeRestoreFolderModal = () => {
        setIsRestoreFolderModalOpen(false);
    };

    const closePermanentDeleteModal = () => {
        setIsPermanentDeleteModalOpen(false);
    };

    const closePermanentDeleteFolderModal = () => {
        setIsPermanentDeleteFolderModalOpen(false);
    };

    const handleStatusButtonClick = (status) => {
        setSelectedStatus(status);
        switch (status) {
            case 'Shared':
                setCurrentModeCount(sharedMode.sharedCount)
                setCurrentMode('Shared');
                setCurrentModeUser(sharedMode.sharedUsers);
                break;
            case 'Accepted':
                setCurrentModeCount(acceptedMode.acceptedCount)
                setCurrentMode('Accepted');
                setCurrentModeUser(acceptedMode.acceptedUsers);
                break;
            case 'Rejected':
                setCurrentModeCount(rejectedMode.rejectedCount)
                setCurrentMode('Rejected');
                setCurrentModeUser(rejectedMode.rejectedUsers);
                break;
            case 'Pending':
                setCurrentModeCount(pendingMode.pendingCount)
                setCurrentMode('Pending');
                setCurrentModeUser(pendingMode.pendingUsers);
                break;
            default:
                break;
        }
    };

    const handleRejectClick = () => {
        setAcceptStatus("false");
        handleFetchStatus("false");
        setRejected(true);
    };

    const handleCheckClick = () => {
        setIsEditMode(true);
        setAcceptStatus("true");
        handleFetchStatus("true");
    };

    const handleFetchStatus = async (status) => {
        let payload = {
            docId: documentId,
            acceptanceStatus: status,
        }
        let response = await checkDocAcceptance(payload);
        if (response.status == 200) {
            getAllDocuments()
            const data = {
                userId: sessionStorage.getItem("userId"),
                organizationId: sessionStorage.getItem("organizationId"),
                actionType: status ? "acceptDoc" : "rejetDoc",
            };

            const addLogs = await addUserLogs(data);
            if (addLogs.status !== 200) {
                toast.error(addLogs.message);
            }
        }
    }

    const handleComplete = async () => {
        swal({
            title: "Complete",
            text: "Are you sure you want complete this document?",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (confirm) => {
            if (confirm) {
                let payload = {
                    docId: documentId,
                };
                let response = await updateDocumentStatus(payload);
                console.log("response", response);
                if (response.status === 200) {
                    getAllDocuments();
                    setAnchorEl(null);
                }
            }
        });
    };

    // const handleComplete = async () => {
    //     let payload = {
    //         docId : documentId,
    //     }
    //     let response = await updateDocumentStatus(payload);
    //     console.log("response", response);
    //     if (response.status == 200){
    //         getAllDocuments()
    //         setAnchorEl(null);
    //     }
    // }

    const handleUsersStatusOpen = async () => {
        setOpenUsersStatusDialog(true);
        let payload = {
            docId: documentId,
        }
        let data = await getAllDocStatus(payload);
        setSharedMode(data?.shared)
        setCurrentModeUser(data?.shared?.sharedUsers)
        setCurrentModeCount(data?.shared?.sharedCount)
        setAcceptedMode(data?.accepted)
        setRejectedMode(data?.rejected)
        setPendingMode(data?.pending)
    };

    // const handleCardClick = () => {
    //     if (isSubAdmin) {
    //         return
    //     }
    //     if (type === "MyDocuments") {
    //         dispatch(sharedDocumentStatus(false))
    //         history.push(`/viewmydocuments/${documentId}`, { data: isCoAuthor });
    //     }
    //     if (type === "SharedWithMe") {
    //         dispatch(sharedDocumentStatus(true))
    //         history.push(`/viewmydocuments/${documentId}`, { data: isCoAuthor });
    //     }
    // };

    const handleTeamCollab = async (data) => {
        let check = await getDocumentbyId(data._id);
        console.log("check", check);
        if (check.status === 200) {
            if (check.data.document.activeDoc === true) {
                history.push({
                    pathname: "/collaboration",
                    state: { document: data, userProfileColor: userProfileColor },
                });
                console.log("datafromcards", data);
            } else {
                getAllDocuments();
            }
        } else {
            getAllDocuments();
        }
    };
    const handleCardClick = (hideCorrection = false) => {
        console.log("cardClick_")
        if (isSubAdmin) {
            return
        }
        if (type === "MyDocuments") {
            dispatch(sharedDocumentStatus(false))
            history.push(`/viewmydocuments/${document._id}`, { data: isCoAuthor, });
        }
        if (type === "SharedWithMe") {
            console.log("cardClick_")
            dispatch(sharedDocumentStatus(true))
            history.push(`/viewmydocuments/${document._id}`, { data: isCoAuthor, hidecorrectnessbar: hideCorrection });
        }
    };

    const handlePublish = async (data) => {
        swal({
            title: "Send Blog for Admin Approval",
            text:
                "Are you sure ? You want to send the blog for Admin approval in-order to publish this blog",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willPublish) => {
            if (willPublish) {
                console.log("willPublish", willPublish, data);
                let payload = {
                    documentName: data.documentName,
                    documentType: "blog"
                }
                const documentId = await blogServices.saveBlogs(payload);

                const textWithoutHTML = data.onlyDocumentContent.replace(/<[^>]*>/g, '');
                const textWithNewlines = textWithoutHTML.replace(/<br\s*\/?>/gi, '\n');
                console.log("textWithoutHTML", textWithoutHTML, textWithNewlines);
                const formattedText = {
                    blocks: [
                        {
                            key: "9ukr0",
                            text: textWithNewlines,
                            type: "unstyled",
                            depth: 0,
                            inlineStyleRanges: [],
                            entityRanges: [],
                            data: {}
                        }
                    ],
                    entityMap: {}
                };
                const formattedContent = JSON.stringify(formattedText)
                console.log("fomattedtext", formattedText, formattedContent);

                if (documentId !== null) {
                    let autoBlogPayload = {
                        documentName: data.documentName,
                        documentId: documentId,
                        onlyDocumentContent: data.onlyDocumentContent,
                        formattedDocumentContent: data.onlyDocumentContent,
                    }
                    const { blog, status } = await blogServices.autoSaveBlog(autoBlogPayload);
                    console.log("blogstatus", blog._id, status);
                    if (status === 200) {
                        let blogId = await getBlogById(blog._id);
                        console.log("blogid", blogId);
                        if (blogId.status === 200) {
                            if (blogId.data.activeDoc === true) {
                                let responseBlog = await publishMyBlogs(blog._id);
                                toast.success("Blog sent for approval!");
                                console.log(responseBlog);
                            }
                            //   getAllBlog("my");
                        }
                    }
                }
            }
            console.log("documentId1", documentId);
            handleMenuClose();

        });
    };
    //     //   blog auto save
    //       autoSaveDocument = async () => {
    //         const { document, documentId } = this.state;
    //         if (!documentId && editorId) {
    //           if (docTitle) {
    //             const docs = {
    //               documentName: document.documentName,
    //               documentType: "blog",
    //             };
    //             this.setState({ saved: true }, () => {
    //               this.autoDocumentCreation(docs);
    //             });
    //           } else if (documentId) {
    //             const data = {
    //               documentId: documentId,
    //               documentName: docTitle,
    //             };
    //             this.setState({ saved: true }, () => {
    //               this.autoSaveContent(data);
    //             });
    //           }
    //         } else {
    //           // this.autoDocumentDeletion(data);
    //         }
    //       };

    //   autoDocumentCreation = async (document) => {
    //     console.log("autoDocumentCreation1", document);
    //     if (this.props.documentType !== "blog") {
    //       const documentId = await documentService.saveDocs(document);
    //       console.log(documentId);
    //       this.setState({ documentId, saved: false });
    //     } else {
    //       const documentId = await blogService.saveBlogs(document);
    //       this.setState({ documentId, saved: false });
    //     }
    //   };
    // s

    //1151 992

    const accessToShare = useCheckFeatureAccess(currentNavItem, 'share')
    const accessToDelete = useCheckFeatureAccess(currentNavItem, 'delete')
    const accessToAcceptRejectDoc = useCheckFeatureAccess(currentNavItem, 'AcceptRejectDoc')
    const accessToEditSharedDoc = useCheckFeatureAccess(currentNavItem, 'EditSharedDoc')
    const accessToCollaboration = useCheckFeatureAccess(currentNavItem, 'Collaboration')
    const accessToComplate = useCheckFeatureAccess(currentNavItem, 'complete')
    const accessToPublishAsBlog = useCheckFeatureAccess(currentNavItem, 'publishAsBlog')
    const accessToRestore = useCheckFeatureAccess(currentNavItem, 'restore')
    const accessToPermanantDelete = useCheckFeatureAccess(currentNavItem, 'deletePermanantly')

    return (
        <>
            <Card className='cardStyle' style={{ height: leftNavbarOpen && (windowWidth > 992 && windowWidth < 1152) ? '220px' : '195px', borderRadius: '20px', display: 'flex', flexDirection: 'column', cursor: 'pointer' }}>
                <CardContent className='bgCardColor' style={{ position: 'relative', padding: '5px', flex: 1 }}  >


                    <div style={{ display: 'flex' }}>
                        <div>
                            {(type !== "SharedWithMe") && !SubAdmin &&
                                <Checkbox checked={document.isChecked || false} onChange={onCardCheckboxChange} />
                            }
                        </div>


                        {(document.documentType === "folder") ?
                            <div style={{ position: 'relative', width: '100%', height: '100%' }} onClick={() => handleFolderClick(document._id)}>
                                <div style={{ position: 'absolute', left: '50%', transform: 'translate(-90%, 65%)', textAlign: 'center' }}>
                                    <TopicIcon fontSize='large' />
                                    <Typography variant="h6">{document.folderName}</Typography>
                                </div>
                            </div>
                            :

                            <div style={{ marginTop: '8px' }} onClick={() => handleCardClick(false)}>

                                <div>
                                    <Typography variant="body1" style={{ fontWeight: 'bold', overflow: 'hidden', marginLeft: (type === "SharedWithMe") ? '12px' : '0px' }}>{document.documentName?.length > 20 ? `${document.documentName?.slice(0, 20)}...` : document.documentName}</Typography>
                                </div>

                                {/* <div> <Typography variant="body2" style={{ marginTop:'5px', fontSize: '10px' }}>{description.slice(0, 120).replace(/<[^>]*>/g, '')}</Typography></div> */}

                            </div>



                        }
                    </div>
                    {(document.documentType !== "folder") &&
                        <>
                            <div onClick={() => handleCardClick(false)}> <Typography variant="body2" style={{ marginTop: '5px', fontSize: '10px', marginLeft: '12px' }}>{description.slice(0, 100).replace(/<[^>]*>/g, '')}</Typography></div>


                            <div style={{ display: 'flex', flexDirection: 'column' }} >
                                <div style={{ display: 'flex', marginLeft: '10px', position: 'absolute', bottom: '28px' }}>
                                    <CircleIcon style={{
                                        color:
                                            toStatus === 'Pending Acceptance' ? '#f5b706' :
                                                toStatus === 'Review In Progress' ? '#a506f5' :
                                                    toStatus === 'Review Completed' ? '#a506f5' :
                                                        toStatus === 'Completed' ? '#08b512' :
                                                            toStatus === 'In progress' ? '#a506f5' :
                                                                toStatus === 'Rejected' ? 'red' :
                                                                    toStatus === 'View Only' ? 'blue' :
                                                                        toStatus === 'Approved' ? '#FF5733' :
                                                                            toStatus === 'Published' ? '#ea40bc' :
                                                                                toStatus === 'Deleted' ? '#1976d2' :
                                                                                    toStatus === 'Open' ? 'blue' : '#000000'
                                    }} />
                                    <Typography variant="body1" style={{
                                        fontSize: '12px', marginLeft: '10px', marginTop: '2px', fontWeight: 'bold'
                                    }}>
                                        {toStatus}
                                    </Typography>
                                </div>
                                <div style={{
                                    display: 'flex',
                                    position: 'absolute',
                                    bottom: leftNavbarOpen && (windowWidth > 992 && windowWidth < 1152) ? '0px' : '8px',
                                    marginLeft: '11px'
                                }}>
                                    <span style={{ fontSize: '10px', display: 'flex' }}><p style={{ color: '#1976d2' }}><b className="dateLabelcolor" >Created:</b> {formatDate(document.creationDate)}</p></span>
                                    <span style={{
                                        fontSize: '10px',
                                        display: 'flex',
                                        marginLeft: '15px'
                                    }}> {getUpdatedDateDisplay(document.date)}</span>
                                </div>
                            </div>
                        </>
                    }
                </CardContent>
                <Card className='innerCardBorder' style={{ borderRadius: '10px', width: '100%', height: '50px' }}>
                    <CardContent className='bgCardColor'>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {type === "SharedWithMe" &&
                                <div>
                                    <Typography variant="body1" style={{
                                        fontSize: '10px', color:
                                            toStatus === 'Pending Acceptance' ? '#f5b706' :
                                                toStatus === 'Review In Progress' ? '#a506f5' :
                                                    toStatus === 'Review Completed' ? '#a506f5' :
                                                        toStatus === 'Completed' ? '#08b512' :
                                                            toStatus === 'In progress' ? '#a506f5' :
                                                                toStatus === 'Rejected' ? 'red' :
                                                                    toStatus === 'View Only' ? 'blue' :
                                                                        toStatus === 'Approved' ? '#FF5733' :
                                                                            toStatus === 'Published' ? '#ea40bc' :
                                                                                toStatus === 'Deleted' ? '#1976d2' :
                                                                                    toStatus === 'Open' ? 'blue' : '#000000'
                                    }}><b>{Ownername}</b></Typography>

                                </div>
                            }
                            <div className='bgCardColor' style={{ display: 'flex' }}>
                                <div>

                                    {type !== "SharedWithMe" ? (
                                        <>
                                            {type !== "DeletedDocuments" && (
                                                (document.documentType !== "folder") &&
                                                <div style={{ display: 'flex' }}>
                                                    <div>
                                                        <LightTooltip title="User Details">
                                                            <IconButton style={{ position: 'absolute', left: '20px', bottom: '5px' }} onClick={handleUsersStatusOpen}>
                                                                <PeopleIcon style={{ width: '20px', height: '20px', color: 'blue' }} />
                                                            </IconButton>
                                                        </LightTooltip>
                                                    </div>
                                                    {isSubAdmin &&
                                                        <div style={{ marginLeft: '30px' }}>
                                                            <Typography variant="body1" style={{
                                                                fontSize: '10px', color:
                                                                    toStatus === 'Pending Acceptance' ? '#f5b706' :
                                                                        toStatus === 'Review In Progress' ? '#a506f5' :
                                                                            toStatus === 'Review Completed' ? '#a506f5' :
                                                                                toStatus === 'Completed' ? '#08b512' :
                                                                                    toStatus === 'In progress' ? '#a506f5' :
                                                                                        toStatus === 'Rejected' ? 'red' :
                                                                                            toStatus === 'View Only' ? 'blue' :
                                                                                                toStatus === 'Approved' ? '#FF5733' :
                                                                                                    toStatus === 'Published' ? '#ea40bc' :
                                                                                                        toStatus === 'Deleted' ? '#1976d2' :
                                                                                                            toStatus === 'Open' ? 'blue' : '#000000'
                                                            }}><b>{Ownername}</b></Typography>

                                                        </div>
                                                    }
                                                </div>
                                            )}

                                            {document.documentType === "folder" ? (
                                                type === "DeletedDocuments" ? (
                                                    <IconButton style={{ position: 'absolute', right: '30px', bottom: '5px' }} onClick={handleMenuClick}>
                                                        <MoreVertIcon
                                                            className='MoreVertIcon'
                                                        />
                                                    </IconButton>
                                                ) : (
                                                    <IconButton disabled={!accessToDelete} style={{ position: 'absolute', right: '30px', bottom: '5px' }} onClick={() => handleMenuItemClick('DeleteFolder')}>
                                                        <LightTooltip title="Delete">
                                                            <DeleteIcon style={{
                                                                color:
                                                                    !accessToDelete ? "gray" : (
                                                                        toStatus === 'Pending Acceptance' ? '#f5b706' :
                                                                            toStatus === 'Review In Progress' ? '#a506f5' :
                                                                                toStatus === 'Review Completed' ? '#a506f5' :
                                                                                    toStatus === 'Completed' ? '#08b512' :
                                                                                        toStatus === 'In progress' ? '#a506f5' :
                                                                                            toStatus === 'Rejected' ? 'red' :
                                                                                                toStatus === 'View Only' ? 'blue' :
                                                                                                    toStatus === 'Approved' ? '#FF5733' :
                                                                                                        toStatus === 'Published' ? '#ea40bc' :
                                                                                                            toStatus === 'Deleted' ? '#1976d2' :
                                                                                                                toStatus === 'Open' ? 'blue' : "red"
                                                                    )
                                                            }} />
                                                        </LightTooltip>
                                                    </IconButton>
                                                )
                                            ) : (!SubAdmin &&
                                                <IconButton style={{ position: 'absolute', right: '30px', bottom: '5px' }} onClick={handleMenuClick}>
                                                    <MoreVertIcon className='MoreVertIcon' />
                                                </IconButton>
                                            )}

                                        </>
                                    ) : (
                                        (toStatus !== "Rejected" && !SubAdmin && (
                                            !isEditMode && toStatus !== "In progress" && !readOnly && toStatus !== "Completed" ? (
                                                <LightTooltip title="Reject">
                                                    <IconButton disabled={!accessToAcceptRejectDoc} onClick={handleRejectClick} style={{ position: 'absolute', right: '17px', bottom: '5px', color: accessToAcceptRejectDoc ? 'red' : "gray" }}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </LightTooltip>
                                            ) : (""
                                                // <LightTooltip title="Delete">
                                                //     <IconButton style={{ position: 'absolute', right: '17px', bottom: '3px' }} onClick={() => handleMenuItemClick('Delete')}>
                                                //         <DeleteIcon style={{
                                                //             color:
                                                //             toStatus === 'Pending Acceptance' ? '#f5b706' :
                                                //             toStatus === 'Review In Progress' ? '#a506f5' :
                                                //             toStatus === 'Review Completed' ? '#a506f5' :
                                                //             toStatus === 'Completed' ? '#08b512' :
                                                //             toStatus === 'In progress' ? '#a506f5' :
                                                //             toStatus === 'Rejected' ? 'red' :
                                                //             toStatus === 'View Only' ? 'blue' :
                                                //             toStatus === 'Approved' ? '#FF5733' :
                                                //             toStatus === 'Published' ? '#ea40bc' :
                                                //             toStatus === 'Deleted' ? '#1976d2' :
                                                //             toStatus === 'Open' ? 'blue' : '#000000'
                                                //                                 }} />
                                                //                             </IconButton>
                                                //                         </LightTooltip>
                                            ))))
                                    }
                                    {/* {type !== "MyDocuments" && toStatus === "Rejected" && (
            <LightTooltip title="Delete">
                <IconButton style={{ position: 'absolute', right: '17px', bottom: '3px' }} onClick={() => handleMenuItemClick('Delete')}>
                    <DeleteIcon style={{
                        color:
                        toStatus === 'Pending Acceptance' ? '#f5b706' :
                        toStatus === 'Review In Progress' ? '#a506f5' :
                        toStatus === 'Review Completed' ? '#a506f5' :
                        toStatus === 'Completed' ? '#08b512' :
                        toStatus === 'In progress' ? '#a506f5' :
                        toStatus === 'Rejected' ? 'red' :
                        toStatus === 'View Only' ? 'blue' :
                        toStatus === 'Approved' ? '#FF5733' :
                        toStatus === 'Published' ? '#ea40bc' :
                        toStatus === 'Deleted' ? '#1976d2' :
                        toStatus === 'Open' ? 'blue' : '#000000'
                                                }} />
                                            </IconButton>
                                        </LightTooltip>
                                    )} */}
                                </div>
                                {!SubAdmin &&
                                    <div>
                                        {type === "MyDocuments" && (
                                            document.activeDoc && document.pendingDocuments?.length > 0 ? (
                                                <LightTooltip title="Collaboration">
                                                    <i
                                                        className="fa fa-asterisk blurb-ripple-out point"
                                                        style={{ fontSize: "11px", color: accessToCollaboration ? "#00e673" : "gray", position: 'absolute', bottom: '21px', right: '85px' }}
                                                        onClick={() => accessToCollaboration && handleTeamCollab(document)}
                                                        aria-hidden="true"
                                                    ></i></LightTooltip>
                                            ) : (
                                                ""
                                            )
                                        )}
                                        {type === "SharedWithMe" && toStatus !== "Rejected" && (
                                            readOnly ? (<LightTooltip title="Read only">
                                                <IconButton onClick={() => handleCardClick(true)} style={{ position: 'absolute', bottom: '3px', right: '65px', color: 'grey' }}>
                                                    <RemoveRedEyeIcon style={{
                                                        color:
                                                            toStatus === 'Pending Acceptance' ? '#f5b706' :
                                                                toStatus === 'Review In Progress' ? '#a506f5' :
                                                                    toStatus === 'Review Completed' ? '#a506f5' :
                                                                        toStatus === 'Completed' ? '#08b512' :
                                                                            toStatus === 'In progress' ? '#a506f5' :
                                                                                toStatus === 'Rejected' ? 'red' :
                                                                                    toStatus === 'View Only' ? 'blue' :
                                                                                        toStatus === 'Approved' ? '#FF5733' :
                                                                                            toStatus === 'Published' ? '#ea40bc' :
                                                                                                toStatus === 'Deleted' ? '#1976d2' :
                                                                                                    toStatus === 'Open' ? 'blue' : '#000000'
                                                    }} /></IconButton></LightTooltip>) : (
                                                !isEditMode && toStatus !== "In progress" ? (
                                                    toStatus !== "Completed" && (
                                                        <LightTooltip title="Accept">
                                                            <IconButton disabled={!accessToAcceptRejectDoc} onClick={handleCheckClick} style={{ position: 'absolute', bottom: '5px', right: '65px', color: accessToAcceptRejectDoc ? 'green' : "gray" }}>
                                                                <CheckIcon />
                                                            </IconButton >
                                                        </LightTooltip>
                                                    )
                                                ) : (
                                                    <LightTooltip title="Edit access">
                                                        <IconButton disabled={!accessToEditSharedDoc} style={{ cursor: accessToEditSharedDoc ? "pointer" : "not-allowed", position: 'absolute', bottom: '4px', right: '65px', color: 'grey' }} onClick={() => handleCardClick(false)}>
                                                            <EditIcon style={{
                                                                color: !accessToEditSharedDoc ? 'gray' : (
                                                                    toStatus === 'Pending Acceptance' ? '#f5b706' :
                                                                        toStatus === 'Review In Progress' ? '#a506f5' :
                                                                            toStatus === 'Review Completed' ? '#a506f5' :
                                                                                toStatus === 'Completed' ? '#08b512' :
                                                                                    toStatus === 'In progress' ? '#a506f5' :
                                                                                        toStatus === 'Rejected' ? 'red' :
                                                                                            toStatus === 'View Only' ? 'blue' :
                                                                                                toStatus === 'Approved' ? '#FF5733' :
                                                                                                    toStatus === 'Published' ? '#ea40bc' :
                                                                                                        toStatus === 'Deleted' ? '#1976d2' :
                                                                                                            toStatus === 'Open' ? 'blue' : '#000000'
                                                                ),
                                                                cursor: accessToEditSharedDoc ? "pointer" : "not-allowed"
                                                            }} />
                                                        </IconButton>
                                                    </LightTooltip>
                                                )))
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                            {type === "MyDocuments" &&
                                (<>
                                    {toStatus !== "Review In Progress" && toStatus !== "Review Completed" && toStatus !== "Published" && toStatus !== "Approved" && !isSubAdmin &&
                                        <MenuItem onClick={() => handleMenuItemClick('Share')} disabled={!accessToShare}>Share</MenuItem>
                                    }
                                    <MenuItem onClick={() => handleMenuItemClick('Download')}>Download</MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick('Delete')} disabled={!accessToDelete}>Delete</MenuItem>
                                    {toStatus === "Approved" &&
                                        <MenuItem onClick={handleComplete} disabled={!accessToComplate}>Complete</MenuItem>
                                    }
                                    {toStatus === "Completed" &&
                                        <MenuItem onClick={() => handlePublish(document)} disabled={!accessToPublishAsBlog}>Publish</MenuItem>
                                    }
                                </>
                                )}
                            {type === "SharedWithMe" && (
                                <>
                                    <MenuItem onClick={() => handleMenuItemClick('Download')}>Download</MenuItem>
                                    <MenuItem onClick={() => handleMenuItemClick('Delete')}>Delete</MenuItem>
                                </>
                            )}

                            {type === "DeletedDocuments" &&
                                (<>
                                    <MenuItem disabled={!accessToRestore} onClick={() => { document.documentType === "folder" ? handleMenuItemClick('RestoreFolder') : handleMenuItemClick('Restore') }}>Restore</MenuItem>
                                    <MenuItem disabled={!accessToPermanantDelete} onClick={() => { document.documentType === "folder" ? handleMenuItemClick('PermanentDeleteFolder') : handleMenuItemClick('PermanentDelete') }}>Delete Permanently</MenuItem>
                                </>)}
                        </Menu>

                        <Dialog open={openUsersStatusDialog} onClose={handleUsersStatusClose}>
                            <DialogContent>
                                <DialogActions style={{ display: "flex", justifyContent: "space-between", flexDirection: windowWidth < 426 ? "column" : "row", padding: '0px', }}>
                                    <div style={{ width: windowWidth < 426 ? "100%" : "49%", display: "flex", padding: '0px', justifyContent: "space-between", marginBottom: windowWidth < 426 ? "8px" : "0px", }}>
                                        <Button
                                            style={{ backgroundColor: selectedStatus === 'Shared' ? '#3f51b5' : 'initial', color: selectedStatus === 'Shared' ? 'white' : "#3f51b5", width: "49%", border: '1.5px solid #3f51b5', boxShadow: "none" }}
                                            onClick={() => handleStatusButtonClick('Shared')}
                                        >
                                            Shared
                                        </Button>
                                        <Button
                                            style={{ backgroundColor: selectedStatus === 'Accepted' ? '#08b512' : 'initial', color: selectedStatus === 'Accepted' ? 'white' : "#08b512", width: "49%", border: '1.5px solid #08b512', boxShadow: "none" }}
                                            onClick={() => handleStatusButtonClick('Accepted')}
                                        >
                                            Accepted
                                        </Button>
                                    </div>
                                    <div style={{ width: windowWidth < 426 ? "100%" : "49%", display: "flex", padding: '0px', justifyContent: "space-between", marginLeft: "0px" }}>
                                        <Button
                                            style={{ backgroundColor: selectedStatus === 'Rejected' ? 'red' : "initial", color: selectedStatus === 'Rejected' ? 'white' : "red", width: "49%", border: '1.5px solid red', boxShadow: "none" }}
                                            onClick={() => handleStatusButtonClick('Rejected')}
                                        >
                                            Rejected
                                        </Button>
                                        <Button className='userProfile'
                                            style={{ backgroundColor: selectedStatus === 'Pending' ? '#f5b706' : "initial", color: selectedStatus === 'Pending' ? 'white' : "#f5b706", width: "49%", border: '1.5px solid #f5b706', boxShadow: "none" }}
                                            onClick={() => handleStatusButtonClick('Pending')}
                                        >
                                            Pending
                                        </Button>
                                    </div>
                                </DialogActions>
                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                    <Typography variant="body1" style={{ fontSize: '14px' }}> {selectedStatus} User Details</Typography>

                                    <Typography variant="body1" style={{ fontSize: '14px', marginLeft: 'auto' }}>Count:{currentModeCount}</Typography>
                                </div>
                                <Table style={{ border: '1px solid rgba(0, 0, 0, 0.2)' }}>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell><b>Name</b></TableCell>
                                            <TableCell><b>Role</b></TableCell>
                                        </TableRow>
                                        {currentModeUser?.map((user, index) => (
                                            <TableRow key={index}>
                                                <TableCell>{user.username}</TableCell>
                                                <TableCell>{user.userRole}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleUsersStatusClose}>Cancel</Button>
                            </DialogActions>
                        </Dialog>
                    </CardContent>
                </Card>
            </Card>
            <ShareOptionModal setMyDocs={setMyDocs} handleFetchStatus={handleFetchStatus} show={isShareModalOpen} onHide={closeShareModal} id={selectedId} />
            <DeleteOptionModal show={isDeleteModalOpen} onHide={closeDeleteModal} id={selectedId} getAllDocuments={getAllDocuments} />
            <DeleteFolderOptionModal show={isDeleteFolderModalOpen} onHide={closeDeleteFolderModal} id={selectedId} getAllDocuments={getAllDocuments} />
            <DownloadOptionModal show={isDownloadModalOpen} onHide={closeDownloadModal} id={selectedId} />
            <RestoreOptionModal show={isRestoreModalOpen} onHide={closeRestoreModal} id={selectedId} getAllDocuments={getAllDocuments} />
            <RestoreFolderOptionModal show={isRestoreFolderModalOpen} onHide={closeRestoreFolderModal} id={selectedId} getAllDocuments={getAllDocuments} />
            <PermanentDeleteOptionModal show={isPermanentDeleteModalOpen} onHide={closePermanentDeleteModal} id={selectedId} getAllDocuments={getAllDocuments} />
            <PermanentDeleteFolderOptionModal show={isPermanentDeleteFolderModalOpen} onHide={closePermanentDeleteFolderModal} id={selectedId} getAllDocuments={getAllDocuments} />
        </>
    );
};