import React from "react";
import "./App.css";
import { ToastContainer } from "react-toastify";
import { Route, Switch, Redirect } from "react-router-dom";
import Home from "./components/Home";
import DashBoard from "./components/pages/DashBoardPage";

import AlertsPage from "./components/pages/AlertsPage";
import DocumentPreviewPage from "./components/pages/DocumentPreviewPage";
import TeamMembersPage from "./components/pages/TeamMembersPage";
import PricingPage from "./components/pages/PricingPage";

import FAQPage from "./components/pages/FAQPage";
import MyDocumentsPage from "./components/pages/MyDocumentsPage";
import ViewMyDocumentsPage from "./components/pages/ViewMyDocumentsPage";
import MyDocumentsNewProjectPage from "./components/pages/MyDocumentsNewProjectPage";

import AccountSettingsPage from "./components/pages/AccountSettingsPage2";
import AdminDashBoard from "./components/pages/AdminDashboardPage";
import UserInvoice from "./components/pages/UserInvoice";
import ManageUserPage from "./components/pages/ManageUserPage";
import ManageOrganizationPage from "./components/pages/ManageOrganizationPage";
import PaymentPage from "./components/pages/PaymentPage";
import ReviewRatingPage from "./components/pages/ReviewRatingPage";
import NewsletterPage from "./components/pages/NewsletterPage";
import ManageInvoicesPage from "./components/pages/ManageInvoicePage";
import NetworkDetector from "./components/HigherOrderComponents/NetworkDetector";



import ResetPassPage from "./components/pages/ResetPassPage";
import Logout from "./components/Logout";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Disclaimer from "./components/Disclaimer";
import TermsofService from "./components/TermsofService";
import Reports from "./components/pages/ReportsPage";
import DocTags from "./components/pages/DocTagsPage";

import TeamCollaborationPage from "./components/pages/TeamCollaborationPage";
import PriceDetails from "./components/pages/PriceDetails";

import PaymentReport from "./components/PaymentReport";

import SubAdminManagerUserPage from "./components/pages/SubAdminSideBarPage";
import Addsubcription from "./components/pages/Addsubcription";

import CitationPage from "./components/pages/CitationPage";
import CoAuthorPage from "./components/pages/CoAuthorPage";
import CoAuthorNewProjectPage from "./components/pages/CoAuthorNewProjectPage";



import CompareDocumentPage from './components/pages/CompareDocumentPage';
import ParaphrasePage from "./components/pages/ParaphrasePage";

import PlagiarismPage from "./components/pages/PlagiarismPage";
import TranslatorPage from "./components/pages/TranslatorPage";

import HomePriceDetails from "./components/HomePriceDetails";
import MyCitationsList from "./components/pages/MyCitationListPage";
import BlogSection from "./components/pages/BlogSectionPage";
import AfterRegistration from "./components/pages/AfterRegistrationPage";
import SummeriserPage from "./components/Forms/SummeriserPage";
import SafeRoute from './components/HigherOrderComponents/UserProtectedRoutes';
import AdminSafeRoute from './components/HigherOrderComponents/AdminProtectedRoutes';
import BlogAccessForPublic from "./components/pages/BlogAccessForPublicPage";
// import DocumentReport from "./components/DocumentReport"
import AdminBlog from "./components/pages/AdminBlogPage";
import CitationProjPage from "./components/pages/CitationProjPage";
import BlogsonLandingPage from "./components/pages/BlogsonLandingpage";
import CostumHomePage from "./components/pages/CostumHomePage";
import NewHome from "./components/NewHome"
import SocialMediaAuth from "./components/pages/SocialMediaAuthPage";
import ParaTwoPage from "./components/pages/ParaTwoPage";
import ContactUs from "./components/pages/ContactUsPages"
import BlogManagementPage from "./components/pages/BlogManagementPage";

import LoginForm2 from "./components/LoginForm2";
import RegisterForm2 from "./components/RegisterForm2";
import ForgotPasswordForm2 from "./components/ForgotPasswordForm2";

import OTPVerification from "./components/OTPVerification";



import SubAdminDashboardPage from "./components/pages/SubAdminDashboardPage";
import GrammarCheckPage from "./components/pages/GrammarCheckPage";
import SubAdminUserRequestPage from "./components/pages/SubAdminUserRequestPage";
import PromptsGeneratorPage from "./components/pages/PromptsGeneratorPage";
import SubAdminWorkFlowPage from "./components/pages/SubAdminWorkFlowPage";
import SubAdminBlogPage from "./components/pages/SubAdminBlogPage";
import SubAdminDocumentsPage from "./components/pages/SubAdminDocumentsPage";
import ChatBoxPage from "./components/pages/ChatBoxPage";

import GrammarCheckTrialPage from "./components/pages/GrammarCheckTrialPage";
import CitationTrialPage from "./components/pages/CitationTrialPage";
import PlagiarismTrialPage from "./components/pages/PlagiarismTrialPage";
import CoAuthorNewProjectTrialPage from "./components/pages/CoAuthorNewProjectTrialPage";
import ParaphraserTrialPage from "./components/pages/ParaphraserTrialPage";
import UserLogsPage from "./components/pages/UserLogsPage";
import AdminUserLogPage from "./components/pages/AdminUserLogPage";
import SubscriptionPage from "./components/pages/SubscriptionPage";


function App() {
  return (
    <React.Fragment>
      <ToastContainer />
      <Switch>
        {/* <Tableuse/> */}  
        <Route path="/" component={Home} exact />
        <Route path="/Summarizer" component={SummeriserPage} />

        <Route path="/blogeditor" component={BlogManagementPage} />
 
        {/* <Route exact path="/login" component={LoginForm} /> */}
        <Route exact path="/login" component={LoginForm2} /> 
        <Route path="/otpverification" component={OTPVerification} />
        {/* <Route path="/emailVerification/:confirmEmail/:token" component={LoginForm2} /> */}
        <Route path="/login/:loginParam" component={LoginForm2} />
        {/* <Route path="/forgotpassword" component={ForgotPassword} /> */}
        <Route path="/forgotpassword" component={ForgotPasswordForm2} />
        {/* <Route path="/resetpassword" component={ResetPassPage} /> */}
        <Route path="/resetpassword" component={ResetPassPage} />
        {/* <Route path="/signup" component={RegisterForm} /> */}
        <Route path="/signup" component={RegisterForm2} />
        <Route path="/pricing" component={PricingPage} />
        {/* <Route path="/contactus" component={ContactUsPage} /> */}
        <Route path="/faq" component={FAQPage} />
        <SafeRoute path="/collaboration" component={TeamCollaborationPage} />
        <SafeRoute path="/compareDocument" component={CompareDocumentPage} />
        <AdminSafeRoute
          path="/manageinvoices"
          component={ManageInvoicesPage}
        />
        <Route path="/logout" component={Logout} />
        <Route path='/socialMediaAuth' component={SocialMediaAuth} />
        <SafeRoute path="/invoices" component={UserInvoice} />
        <SafeRoute path="/pricedetails" component={PriceDetails} />
        <AdminSafeRoute
          path="/adminaccountsettings"
          component={AccountSettingsPage}
        />
        <SafeRoute
          path="/paraphrase"
          component={ParaphrasePage}
        />
        <SafeRoute path="/paratwo" component={ParaTwoPage} />
        <SafeRoute path="/plagiarism" component={PlagiarismPage} />
        <SafeRoute path="/translator" component={TranslatorPage} />
 
        <AdminSafeRoute
          path="/admindashboard"
          component={AdminDashBoard}
        />
        <AdminSafeRoute
          path="/addsubscription"
          component={NetworkDetector(Addsubcription)}
        />
        <AdminSafeRoute path="/manageuser" component={ManageUserPage} />
        <AdminSafeRoute path="/manageorganizations" component={ManageOrganizationPage} />
        <SafeRoute
          path="/accountsettings"
          component={AccountSettingsPage}
        />
        <AdminSafeRoute path="/Newsletter" component={NewsletterPage} />
        <SafeRoute
          path="/mydocuments"
          component={MyDocumentsPage}
        />

        <AdminSafeRoute path="/Newsletter" component={NewsletterPage} />
        <SafeRoute
          path="/viewmydocuments/:documentId"
          component={ViewMyDocumentsPage}
        />


        <AdminSafeRoute path="/Newsletter" component={NewsletterPage} />
        <SafeRoute
          path="/mynewdocuments"
          component={MyDocumentsNewProjectPage}
        />

        <SafeRoute
          path="/grammarcheck"
          component={GrammarCheckPage}
        />

        <AdminSafeRoute
          path="/managereviews"
          component={ReviewRatingPage}
        />
        <AdminSafeRoute
          path="/adminBlogs"
          component={AdminBlog}
        />
        <AdminSafeRoute
          path="/promptsGenerator"
          component={PromptsGeneratorPage}
        />

        <Route path="/grammarchecktrial" component={GrammarCheckTrialPage} />
        <Route path="/citationtrial" component={CitationTrialPage} />
        <Route path="/plagiarismtrial" component={PlagiarismTrialPage} />
        <Route path="/coauthortrial" component={CoAuthorNewProjectTrialPage} />
        <Route path="/paraphrasetrial" component={ParaphraserTrialPage} />




        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/disclaimer" component={Disclaimer} />
        <Route path="/termsofservice" component={TermsofService} />
        {/* <Route path="/mobilesidenav" component={MobileSidenav} /> */}
        <SafeRoute
          key="documentpreview"
          exact
          path="/documentpreview/:id"
          component={DocumentPreviewPage}
        />
        <SafeRoute
          key="blogpreview"
          exact
          path="/blogpreview/:id"
          component={DocumentPreviewPage}
        />
        {/* <Route path="/room/:roomID" component={Room} /> */}

        <SafeRoute path="/subAdminDashboard" component={SubAdminDashboardPage} />
        <SafeRoute path="/subAdminManageUser" component={SubAdminManagerUserPage} />
        <SafeRoute path="/subAdminDocuments" component={SubAdminDocumentsPage} />
        <SafeRoute path="/subAdminUserRequest" component={SubAdminUserRequestPage} />
        <SafeRoute path="/userLogs" component={UserLogsPage} />
        <SafeRoute path="/adminUserLogs" component={AdminUserLogPage} />
        <SafeRoute path="/manageOrganizationBlogs" component={SubAdminBlogPage} />
        <SafeRoute path="/subAdminWorkFlow" component={SubAdminWorkFlowPage} />
        <SafeRoute path="/subscription" component={SubscriptionPage} />
        <SafeRoute
          exact
          path="/payment"
          component={PaymentPage}
        />
        <Route path="/homepricedetails" component={HomePriceDetails} />
        <SafeRoute path="/dashboard" component={DashBoard} />
        <SafeRoute path="/alerts" component={AlertsPage} />
        {/* <Route
          path="/texteditor"
          component={TextEditorPage}
        /> */}
        <SafeRoute
          path="/paymentreport"
          component={PaymentReport}
        />

        <SafeRoute path="/myteam" component={TeamMembersPage} />
        <SafeRoute path="/chat" component={ChatBoxPage} />
        {/* <SafeRoute path="/videochat" component={VideoChat} /> */}
        <SafeRoute path="/reports" component={Reports} />
        <SafeRoute path="/docTags" component={DocTags} />
        <SafeRoute path="/blogpage" component={BlogSection} />
        <SafeRoute path="/citation" component={CitationPage} />
        <SafeRoute path="/coauthor" component={CoAuthorPage} />
        <SafeRoute path="/coauthor-newproject" component={CoAuthorNewProjectPage} />

        <SafeRoute path="/citationList" component={MyCitationsList} />
        <SafeRoute path="/citationproj" component={CitationProjPage} />
        <SafeRoute path="/plagiarism" component={PlagiarismPage} />
        <Route path="/translator" component={TranslatorPage} />

 
        <SafeRoute path="/contactUs" component={ContactUs} />
        <Route path='/public/blogpreview/:id' component={BlogAccessForPublic} />
        <Route path="/blog" component={BlogsonLandingPage} />

        {/* <Route path="/:id" component={BlogAccessForPublic} /> */}
        <Route path="/afterRegistration" component={AfterRegistration} />
        <AdminSafeRoute path="/costumHome" component={CostumHomePage} />
        <Route path="/newhome" component={NewHome} />
        <Route path="/:name" component={BlogAccessForPublic} />
        <Redirect to="/" />
      </Switch>
    </React.Fragment>
  );
}

export default App;