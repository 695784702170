import React, { useEffect, useState } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import { getSubscriptions } from "../services/subscription";
import CloseIcon from "@material-ui/icons/Close";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';


const SubscriptionRequired = ({ open, setOpen }) => {
  const screenData = useSelector((state) => state.manageSubscriptionPlan.subscriptionPlan);
  const history = useHistory();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const [title, setTitle] = useState("Feature Not Available")
  const [content, setContent] = useState("This feature is not included in your current subscription plan. Upgrade to access this feature.")
  const [showUpgradeContent, setshowUpgradeContent] = useState(false)
  const upgradeClickContent = "Plan upgrades are managed by your admin. Please reach out to them for assistance."
  const userDetails = useSelector((user) => user)
  const isSubAdmin = userDetails?.user?.currentUser?.isSubAdmin
  const userRole = sessionStorage.getItem('Role');
  const prevPath = sessionStorage.getItem("previousPath");

  function isSameOrBefore(date1, date2) {
    // return date1.toISOString().split('T')[0] <= date2.toISOString().split('T')[0];
    if (date1?.toString() !== "Invalid Date" && date2?.toString() !== "Invalid Date") {
      console.log("date1_", date1, date2)
      return date1?.toISOString()?.split('T')[0] <= date2?.toISOString().split('T')[0];
    } else {
      return true
    }
  }


  useEffect(() => {
    if (screenData) {
      if (screenData?.isDefault == false) {
        console.log("screenData", screenData)
        const currentDate = new Date();
        const isPlanValid = isSameOrBefore(currentDate, new Date(screenData?.endDate));

        if (!isPlanValid) {
          setTitle("Subscription Plan Expired");
          setContent("Your current subscription has expired. Upgrade or renew your plan to maintain uninterrupted access.");
        }
      }
    }
  }, [screenData]);


  const handleClose = () => {
    setOpen(false)
    setshowUpgradeContent(false)
    if (isSubAdmin) {
      history.push('/subAdminDashboard');
    } else {
      history.push('/dashboard');
    }
  }

  const handleUpgrade = () => {
    console.log(isSubAdmin, userRole, "zaz");
    if (isSubAdmin || userRole == "Individual") {
      setOpen(false)
      history.push('/pricing');
    } else {
      setshowUpgradeContent(true)
    }
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p style={{ fontSize: "20px" }}>{title}</p>
          <CloseIcon onClick={handleClose} style={{ color: "red", cursor: "pointer", marginTop:'2px' }} />
        </div>

        <Typography id="modal-description" variant="body1" color="textSecondary" gutterBottom style={{ textAlign: "justify", font: 'sans-serif' }}>
          {content}
        </Typography>
        <div >
          {/* <Button
            onClick={handleClose}
            color="primary"
            variant="contained"
            // fullWidth
            sx={{ mt: 2, width: "49%" }}

          >
            Close
          </Button> */}
          <Button
            onClick={handleUpgrade}
            color="primary"
            variant="contained"
            fullWidth
            sx={{ mt: 2 }}
          >
            Upgrade
          </Button>
        </div>
        {showUpgradeContent &&
          <div className="d-flex">
            <ErrorOutlineIcon style={{color:"#ff4d4d",marginTop: "13px"}}/>
            <h5 style={{ textAlign: "justify", marginLeft:'4px',marginTop: "15px", color: '#ff4d4d' }}>
              {upgradeClickContent}
            </h5>
          </div>
        }
      </Box>
    </Modal>
  );
};

export default SubscriptionRequired;
