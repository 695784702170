import React, { useState, useEffect } from "react";
import _ from "lodash";
import { ContentState, EditorState, Modifier, SelectionState } from "draft-js";
import DocumentService from '../../services/document';
import { strCount } from "../Editor/utilize";

const SuggestionComp = ({ mobileView, autoSaveContent, docName, docId, newDocId, folderId, tags, ignoreAllClicked, setIgnoreAllClicked, acceptAllClicked, setAcceptAllClicked, setEditorContentUpload, editorHtmlContent, changedSentence, setSentenceResult, setState, setIsFeatureButton, editorState, setEditorState, setIsSuggestion, copiedText, editor, setIsType, ignoredSuggestions }) => {
    console.log(editorHtmlContent, "editorHtmlContent");
    const [suggestionArray, setSuggestionArray] = useState([])
    const [isColorChange, setIsColorChange] = useState(true)
    const [clickedWord, setClickedWord] = useState('')

    let filterValues = []

    const [wordsCount, characterCount] = strCount(editorHtmlContent);



    useEffect(() => {
        if (acceptAllClicked) {
            handleAcceptAll(suggestionArray)
        }
        setAcceptAllClicked(false)
    }, [acceptAllClicked]);

    useEffect(() => {
        if (ignoreAllClicked) {
            handleIgnoreAll(suggestionArray)
        }
        setIgnoreAllClicked(false)
    }, [ignoreAllClicked]);

    const handleIgnoreAll = async (suggestions) => {
        // let updatedHtmlContent;
        // let editorContent = editorHtmlContent; // Assuming editorHtmlContent is your initial HTML content

        suggestions.forEach(async (sentence) => {
            let filterSentence = suggestionArray.filter((item) => item.value !== sentence.value);

            // Save ignored suggestion (assuming DocumentService.saveIgnoredSuggestions is asynchronous)
            let data = {
                phrase: sentence.corrective_text
            };
            // const response = await DocumentService.saveIgnoredSuggestions(data);
            // console.log(response, "response from save phrase");

            // Remove the sentence from suggestionArray and update the filtered array
            setSuggestionArray(filterSentence);

            // Optionally, update UI based on filterSentence length
            if (filterSentence.length === 0) {
                setSentenceResult([]);
                setIsSuggestion(false);
            }

            // Example functions colorWord and highLightWords
            colorWord(sentence.value, "black");
            highLightWords(sentence.value, "white");
        });

        // Log the updated HTML content
        // console.log("Updated HTML content after ignoring:", updatedHtmlContent);

        // // Update editor content and UI states
        // setEditorContentUpload(updatedHtmlContent);
        setSentenceResult([]);
        setIsColorChange(false);
        setIsSuggestion(false); // Depending on your UI logic
    };

    const handleAcceptAll = (suggestion) => {
        let updatedHtmlContent;
        let editorContent = editorHtmlContent
        suggestion.map((sentence) => {
            let phrase = sentence.phrase
            let correctPhrase = sentence.corrective_text_phrase
            console.log(phrase, "yyyy", correctPhrase)


            let tempElement1 = document.createElement('div');
            tempElement1.innerHTML = editorContent
                .split("&nbsp;")
                .join(" ")
                .replace(/<body[^>]*>(.*?)<\/body>/is, "<div>$1</div>")
                .replace(/<\/?(?!p|li|table|img|tbody|tr|td|ul)([^\s>]+)[^>]*>/g, "")
                .replace(/^<div>|<\/div>$/g, "")


            // Function to recursively replace text nodes
            function replaceTextNodes(node) {
                if (node.nodeType === Node.TEXT_NODE) {
                    let text = node.textContent;
                    // Replace the phrase with the correct phrase
                    let newText = text.replace(new RegExp(phrase, 'gi'), correctPhrase);
                    node.textContent = newText;
                } else if (node.nodeType === Node.ELEMENT_NODE) {
                    // If the node is an element, recursively process its child nodes
                    for (let i = 0; i < node.childNodes.length; i++) {
                        replaceTextNodes(node.childNodes[i]);
                    }
                }
            }

            // Start replacing text nodes from the top-level element
            replaceTextNodes(tempElement1);

            // Get the updated HTML content from the modified DOM element
            updatedHtmlContent = tempElement1.innerHTML;
            editorContent = tempElement1.innerHTML

        })

        // Log the updated HTML content
        console.log("Updated HTML contentyyyy:", updatedHtmlContent);

        setEditorContentUpload(updatedHtmlContent);
        setSentenceResult([])
        setIsColorChange(false)
        setIsSuggestion(true)
    }


    const colorWord = (word, color) => {
        console.log("word_", word, color)
        const content = editor.current.value;
        console.log("contentwords_", content,)

        // Escape special characters in the word for regex
        // Construct the replacement string with the desired HTML content
        const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const replacement = `<span style="color:${color}">${word}</span>`;

        // const replacement = `<span class="dynamic-span" data-word="${word}" data-color="${color}" style="color:${color}">${word}</span>`
        // document.addEventListener('click', (e) => {
        //     if (e.target.classList.contains('dynamic-span')) {
        //         const word = e.target.dataset.word;
        //         handleChange(word);
        //     }
        // });

        // Replace all occurrences of the word with the colored version
        const coloredContent = content.replace(
            new RegExp(`(${escapedWord})(?![^<]*>)`, 'gi'), // Ensure not inside HTML tags
            replacement
        );
        console.log(coloredContent, "xxxcoloredContent___");

        editor.current.setEditorValue(coloredContent);
    };

    const handleChange = (event) => {
        const clickedText = event.target.innerText;
        console.log('wordClick_', clickedText);
        // setClickedWord(word)
        // console.log("wordClick_", word, clickedWord)

        // if (word == clickedWord) {
        //     highLightWords(clickedWord, "#fddfde")
        // } else {
        //     highLightWords(word, 'white')
        // }
    }


    const highLightWords = (word, color) => {
        console.log("word_", word)
        const content = editor.current.value;
        const escapedWord = word.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const replacement = `<span style="background-color:${color};">${word}</span>`;
        const coloredContent = content.replace(
            new RegExp(`(${escapedWord})(?![^<]*>)`, 'gi'), // Ensure not inside HTML tags
            replacement
        );
        // Set the updated content in the editor
        const selection = window.getSelection();
        selection.removeAllRanges();
        editor.current.setEditorValue(coloredContent);
    };


    const handleAccept = (sentence) => {
        console.log("sentence_", sentence, "xxxx", editorState)
        console.log("htmlcontentyyyy", editorHtmlContent
            .split("&nbsp;")
            .join(" ")
            .replace(/<body[^>]*>(.*?)<\/body>/is, "<div>$1</div>")
            .replace(/<\/?(?!p|li|table|img|tbody|tr|td|ul)([^\s>]+)[^>]*>/g, "")
            .replace(/^<div>|<\/div>$/g, ""))
        let phrase = sentence.phrase
        let correctPhrase = sentence.corrective_text_phrase
        console.log(phrase, "yyyy", correctPhrase)


        let tempElement1 = document.createElement('div');
        tempElement1.innerHTML = editorHtmlContent
            .split("&nbsp;")
            .join(" ")
            .replace(/<body[^>]*>(.*?)<\/body>/is, "<div>$1</div>")
            .replace(/<\/?(?!p|li|table|img|tbody|tr|td|ul)([^\s>]+)[^>]*>/g, "")
            .replace(/^<div>|<\/div>$/g, "")


        // Function to recursively replace text nodes
        function replaceTextNodes(node) {
            if (node.nodeType === Node.TEXT_NODE) {
                let text = node.textContent;
                // Replace the phrase with the correct phrase
                let newText = text.replace(new RegExp(phrase, 'gi'), correctPhrase);
                node.textContent = newText;
            } else if (node.nodeType === Node.ELEMENT_NODE) {
                // If the node is an element, recursively process its child nodes
                for (let i = 0; i < node.childNodes.length; i++) {
                    replaceTextNodes(node.childNodes[i]);
                }
            }
        }

        // Start replacing text nodes from the top-level element
        replaceTextNodes(tempElement1);

        // Get the updated HTML content from the modified DOM element
        let updatedHtmlContent = tempElement1.innerHTML;

        // Log the updated HTML content
        console.log("Updated HTML contentyyyy:", updatedHtmlContent);

        // Update the editor content
        setEditorContentUpload(updatedHtmlContent);

        const socketData = {
            documentName: docName,
            documentId: docId ? docId : newDocId,
            onlyDocumentContent: updatedHtmlContent,
            formattedDocumentContent: updatedHtmlContent,
            wordsCount,
            characterCount,
            folder: folderId ? folderId : null,
            tags: tags

        };

        autoSaveContent(socketData)

        let givenInput = editorState
        console.log("phrase_", phrase, "xxxx", sentence.corrective_text_phrase)
        const htmlContent = editor.current.value; // Access the HTML content
        const tempElement = document.createElement('span');
        tempElement.innerHTML = htmlContent;
        // Extract text content
        const textContent = tempElement.textContent || tempElement.innerText;
        console.log("textContent", textContent, "x", sentence.corrective_text_phrase);
        // Replace all occurrences of the original word with the replacement word
        const replacedContent = textContent.replace(
            new RegExp(`\\b${phrase}\\b`, 'gi'),
            sentence.corrective_text_phrase
        );
        const updatedContent = textContent.replace(phrase, sentence.corrective_text_phrase);
        console.log("replacedContent_", replacedContent, "x", updatedContent)
        // editor.current.setEditorValue(updatedContent);

        let content = updatedContent.replace(/\n/g, '');
        console.log("content", content)
        // editor.current.setEditorValue(content);

        // const startIndex = replacedContent.indexOf(sentence.corrective_text);
        // const endIndex = startIndex + sentence.corrective_text.length;

        // Scroll to the selection range
        const selection = window.getSelection();
        selection.removeAllRanges();
        // selection.addRange();
        const currentPosition = selection.focusOffset;
        console.log("currentPosition_", currentPosition)

        // for Suggestion change 
        let filterSentence = _.filter(suggestionArray, (item, i) => item.corrective_text !== sentence.corrective_text)
        console.log("filterValue_", sentence, "x", filterSentence)
        if (filterSentence.length == 0) {
            setSentenceResult([])
            setIsColorChange(false)
            setIsSuggestion(true)
        }

        setSuggestionArray(filterSentence)
    }



    const handleIgnore = async (sentence) => {
        console.log("called---------");
        let filterSentence = _.filter(suggestionArray, (item, i) => item.value !== sentence.value)
        console.log("filterValue_", sentence, filterSentence)
        let data = {
            phrase: sentence.corrective_text

        }
        console.log(data, "data from save ignored suggestion")
        // const response = await DocumentService.saveIgnoredSuggestions(data)
        // console.log(response, "response from save phrase");
        if (filterSentence.length == 0) {
            setSentenceResult([])
            setIsSuggestion(false)
        }
        colorWord(sentence.value, "black")
        highLightWords(sentence.value, "white")
        setSuggestionArray(filterSentence)
    }



    useEffect(() => {
        console.log("changedSentence_", changedSentence)
        if (changedSentence?.length > 0) {
            handleFilterValues(changedSentence)
        }
    }, [changedSentence])

    const handleFilterValues = (sentence) => {
        console.log("changedSentence_", sentence)
        sentence.map((item, i) => {
            console.log("item_", item, i)
            filterValues.push(
                {
                    "corrective_text": item["corrective_text"] || item["corrective_text_phrase"],
                    "corrective_text_phrase": item["corrective_text_phrase"] || "",
                    "justification": item["justification"] || "",
                    "phrase": item["phrase"] || "",
                    "type_of_error": item["type_of_error"] || "",
                    "value": item["value"] || item["phrase"]
                }
            )
        })

        console.log("filterValues_", filterValues)
        let filterSentence = _.filter(filterValues, (item) => item['type_of_error'] !== 'No error' && item['type_of_error'] !== 'No errors' && item['type_of_error'] !== 'None' && item['justification'] !== 'No grammatical errors found.' && item['justification'] !== "" && item['justification'] !== 'The sentence is grammatically correct.' && item['corrective_text'] !== "")
        console.log("filterSentence_", filterSentence)

        let finalFilteredValues = filterSentence.filter(suggestion =>
            !ignoredSuggestions.includes(suggestion.corrective_text));

        console.log("finalFilteredValues_", finalFilteredValues);


        setSuggestionArray(finalFilteredValues)
        if (filterSentence.length == 0) {
            setSentenceResult([])
            setIsSuggestion(true)
        } else {
            setSuggestionArray(finalFilteredValues)
            setIsSuggestion(false)
        }

    }
    useEffect(() => {
        console.log("suggestionArray_", suggestionArray);
        if (suggestionArray.length > 0) {
            suggestionArray.map((item) => {
                // if (item.type_of_error != 'None' || item.type_of_error != 'No error') {
                colorWord(item.value, "red")
                // }
            })
        }
    }, [suggestionArray])


    const saveSelection = () => {
        const sel = window.getSelection();
        if (sel.rangeCount > 0) {
            return sel.getRangeAt(0).cloneRange();
        }
        return null;
    };

    const restoreSelection = (range) => {
        if (range && document.contains(range.startContainer)) {
            const sel = window.getSelection();
            sel.removeAllRanges();
            sel.addRange(range);
        }
    };


    function filterRepeatedSentences(inputString) {
        const sentences = inputString.split(/[.!?]/).map(sentence => sentence.trim()).filter(Boolean);
        const uniqueSentences = [...new Set(sentences)];
        return uniqueSentences.join('.');
    }

    return (
        <div style={{ maxHeight: "70vh", overflowY: "auto", overflowX: "hidden", padding: "2px", marginTop: '45px' }}>
            {suggestionArray.map((suggestion, i) => {
                return (
                    <div
                        className="mt-3"
                        style={{
                            backgroundColor: "#ffffff",
                            borderRadius: "8px",
                            boxShadow: "2px 2px 5px -2px #000000",
                            padding: "9px",
                        }}
                        onMouseOver={() => highLightWords(suggestion.value, "#fddfde")}
                        onMouseLeave={() => highLightWords(suggestion.value, "white")}
                    >

                        <div className="row">
                            <div className="col-md-3"
                                style={{
                                    fontFamily: 'Poppins, sans-serif',
                                    fontWeight: 500,
                                    // color: "#EA1537",
                                    fontSize: "12px",
                                    // textDecoration: "line-through",
                                }}
                            >
                            </div>
                            <div className="col-md-9 d-flex justify-content-end"
                            >
                                <button style={{ borderRadius: '15px', color: "#BA18B5", backgroundColor: '#FEDAF6', padding: '5px' }}><b>{suggestion.type_of_error.charAt(0).toUpperCase() + suggestion.type_of_error.slice(1)}</b></button>

                                {/* {changedSentence?.heading} */}
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md-12"
                                style={{
                                    fontFamily: 'Poppins, sans-serif',
                                    fontWeight: 500,
                                    color: "#676767",
                                    fontSize: "13px",
                                }}
                            >
                                <p style={{ color: "#EA1537", display: "inline", fontSize: mobileView ? '10px' : '' }} >{suggestion.value} </p><br />
                                <p style={{ color: "#676767", fontSize: mobileView ? '11px' : '13px', fontWeight: "600", display: "inline" }}>Replace with:</p><br />
                                <p style={{ color: "green", display: "inline", marginLeft: "2%", fontSize: mobileView ? '10px' : '' }} >"{suggestion.corrective_text}"</p>

                            </div>
                        </div>
                        <div className="mt-2">
                            <p style={{ color: "#676767", fontSize: mobileView ? '11px' : '13px', fontWeight: "600", display: "inline" }}>Justification :</p><br />
                            <p style={{ color: "#007bff", fontSize: "12px", display: "inline", marginLeft: "3px", fontSize: mobileView ? '10px' : '' }} >{suggestion?.justification}</p>
                        </div>
                        <div className="row mt-3" style={{ display: mobileView ? 'flex' : '', flexWrap: mobileView ? 'nowrap' : '', justifyContent: mobileView ? 'flex-end' : '' }}>
                            <div className="col-5"></div>

                            <div className="col-md-3">
                                <button className="btn btn-outline primary" style={{ borderRadius: '15px', border: '1px solid grey', fontSize: mobileView ? '10px' : '' }}
                                    onClick={() => handleIgnore(suggestion)}
                                >Ignore</button>
                            </div>
                            <div className="col-md-3">
                                <button className="btn btn-primary" style={{ borderRadius: '15px', fontSize: mobileView ? '10px' : '' }}
                                    onClick={() => handleAccept(suggestion)}
                                >Accept</button>
                            </div>
                        </div>
                    </div>
                )
            })}

        </div>
    )
}
export default SuggestionComp