import React, { useState, useEffect, useMemo } from 'react';
import { Container, Row, Col, Dropdown, Button as B_Button } from 'react-bootstrap';
import { Modal as B_Modal, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Tabs, Tab, Box, AppBar, InputAdornment, FormControlLabel, Select, Modal, FormControl, InputLabel, NativeSelect, TextField, Typography, Checkbox, Accordion, AccordionSummary, AccordionDetails, } from '@material-ui/core';
import { Link as MuiLink, MenuItem, Menu, Button, IconButton, Card, CardContent } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { FileCopy as CopyIcon, GetApp as DownloadIcon } from '@material-ui/icons';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import CloseIcon from '@material-ui/icons/Close';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import citationService from '../../services/citationService';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewListIcon from '@mui/icons-material/ViewList';
import ListItemText from '@mui/material/ListItemText';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './FormComponents/Citation-1.css'
import { CitationCard } from './CitationCard';
import HeadingB from '../Cards/HeadingB';
import { checkFeatureAccess } from '../featureAccess';

import 'react-toastify/dist/ReactToastify.css';

import AddIcon from '@mui/icons-material/Add';
import SelectDropdownMui from '../ReusableComponents/SelectDropdownMui';
import html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";
import CitationModal from './CitationModal';
import UpdateCitationModal from './UpdateCitationModal';
import Loader from '../../utils/loader';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { addUserLogs } from '../../services/userLogServices';
import { useCheckFeatureAccess } from '../CheckFeature';
import { useCheckSubscriptionPlanAccess } from '../SubscriptionAccess';
import SubscriptionRequired from '../SubscriptionRequired';
import { checkAIRequestLimit } from '../aiRequestLimit';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);





const Citation1 = ({ userId }) => {
  const [initialCitations, setInitialCitations] = useState([])
  const [citations, setCitations] = useState([]);
  const [loading, setLoading] = useState(false);
  const userDetails = useSelector((user) => user)
  const userID = userId ? userId : userDetails.user.currentUser._id
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [checkedCards, setCheckedCards] = useState([]);
  const [selectedValue, setSelectedValue] = useState(0);



  const [anchorEl, setAnchorEl] = useState(null);
  const [citationStyle, setCitationStyle] = useState("");
  const [searchCitationStyle, setSearchCitationStyle] = useState("");


  const [searchInput, setSearchInput] = useState("");
  const [websiteData, setWebsiteData] = useState(null);
  const [show, setShow] = useState(false);
  const [sourceChecked, setSourceChecked] = useState(false);
  const [filteredChecked, setFilteredChecked] = useState(false);
  const [totalSource, setTotalSource] = useState(0)
  const [totalSearchedSource, setTotalSearchedSource] = useState(0)
  const [editClicked, setEditClicked] = useState(false)

  const [isCopied, setIsCopied] = useState(false);
  const [citationId, setCitationId] = useState('');
  const [editShow, setEditShow] = useState(false);
  const [editModalTitle, setEditModalTitle] = useState('')


  const [publicationYearError, setPublicationYearError] = useState('');
  const [accessYearError, setAccessYearError] = useState('');
  const [publicationDayError, setPublicationDayError] = useState('');
  const [accessDayError, setAccessDayError] = useState('');

  const [publicationYear, setPublicationYear] = useState('');
  const [publicationMonth, setPublicationMonth] = useState('');
  const [publicationDay, setPublicationDay] = useState('');

  const [accessYear, setAccessYear] = useState('');
  const [accessMonth, setAccessMonth] = useState('');
  const [accessDay, setAccessDay] = useState('');


  const [contributors, setContributors] = useState([
    { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
  ]);

  const [anchorElDropdown, setAnchorElDropdown] = useState(null);
  const [downloadAnchorElDropdown, setDownloadAnchorElDropdown] = useState(null);
  const [professionList, setProfessionList] = useState([

    {
      "value": "AMA 10th edition",
      "name": "AMA 10th edition"
    },
    {
      "value": "American Chemical Society",
      "name": "American Chemical Society"
    },
    {
      "value": "APA 11th edition",
      "name": "APA 11th edition"
    },
    {
      "value": "APA 6th edition",
      "name": "APA 6th edition"
    },
    {
      "value": "APA 7th edition",
      "name": "APA 7th edition"
    },
    {
      "value": "Chicago author",
      "name": "Chicago author"
    }
  ]);

  const [publishSort, setPublishSort] = useState([])
  const [displayByDate, setDisplayByDate] = useState(false)
  const [totalSort, setTotalSort] = useState();
  const [sourceTypeSort, setSourceTypeSort] = useState([])

  const [formData, setFormData] = useState({
    heading: "",
    title: "",
    description: "",
    websiteName: "",
    contributors: [],
    url: "",
    doi: "",
    journalName: "",
    edition: "",
    publisher: "",
    articleNumber: "",
    volumeNumber: "",
    issueNumber: "",
    page: "",
    libraryDatabase: "",
    ISBN: "",
    ISSN: "",
    publicationDate: {
      year: "",
      month: "",
      day: "",
    },
    accessDate: {
      year: "",
      month: "",
      day: "",
    },
    placeOfPublication: {
      country: "",
      state: "",
      city: ""
    }
    // sourceType: ''
  });

  const [editFormData, setEditFormData] = useState({
    heading: "",
    title: "",
    description: "",
    websiteName: "",
    contributors: [],
    url: "",
    doi: "",
    journalName: "",
    edition: "",
    publisher: "",
    articleNumber: "",
    volumeNumber: "",
    issueNumber: "",
    page: "",
    libraryDatabase: "",
    ISBN: "",
    ISSN: "",
    publicationDate: {
      year: "",
      month: "",
      day: "",
    },
    accessDate: {
      year: "",
      month: "",
      day: "",
    },
    // sourceType: ''
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const accessToCite = useCheckFeatureAccess('citationGenerator', 'cite')
  const accessToCiteManually = useCheckFeatureAccess('citationGenerator', 'citeManually')
  const accessToDelete = useCheckFeatureAccess('citationGenerator', 'Delete')
  const accessToCitationScreen = useCheckSubscriptionPlanAccess("Citation")
  const [subReqOpen, setSubReqOpen] = useState(false)



  // Update windowWidth on resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    // if(window.innerWidth <768){
    //   setSidebarOpen(!isSidebarOpen)
    // }

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (accessToCitationScreen == false) {
      setSubReqOpen(true)
    }
  }, [accessToCitationScreen])

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseAccordion = () => {
    setWebsiteData(null);
  };

  const handleModalClose = () => {
    setShow(false);
  }
  const handleModalShow = () => {
    setShow(true);
    setWebsiteData(null)

  }

  const handleCopyClick = () => {
    setIsCopied(true);
    toast.success("Text copied")
    setWebsiteData(null)
  };


  const handleEditModalClose = () => {
    setEditShow(false);
  }


  const handleEditModalShow = () => setEditShow(true);



  const showConfirmationToast = (message, onConfirm, onCancel) => {
    const toastId = toast.info(
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <div style={{ marginBottom: '10px' }}><h4>Delete Citation</h4></div>
        <p style={{ marginBottom: '10px' }}>{message}</p>
        <div style={{ display: 'flex', gap: '10px' }}>
          <button
            onClick={() => {
              onConfirm();
              toast.dismiss(toastId);
            }}
            style={{
              padding: '8px 12px',
              backgroundColor: 'white',
              color: '#1976D2',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Delete Citation
          </button>
          <button
            onClick={() => {
              onCancel();
              toast.dismiss(toastId);
            }}
            style={{
              padding: '8px 12px',
              backgroundColor: 'white',
              color: '#1976D2',
              border: 'none',
              borderRadius: '4px',
              cursor: 'pointer',
            }}
          >
            Cancel
          </button>
        </div>
      </div>,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false, // Do not auto-close the toast
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      }
    );

    return toastId;
  };


  const handleSourceCheckboxChange = (event) => {
    const isChecked = event.target.checked;
    setSourceChecked(isChecked);
    setFilteredChecked(isChecked);

    // Update the state of all checkboxes in the cards based on the "Source" checkbox
    const updatedCitations = citations.map((citation) => ({
      ...citation,
      isChecked: isChecked,
    }));

    setCitations(updatedCitations);
  };

  const handleCardCheckboxChange = (citationId) => {
    // Update the state of the clicked checkbox only
    const updatedCitations = citations.map((citation) => {
      if (citation._id === citationId) {
        return {
          ...citation,
          isChecked: !citation.isChecked,
        };
      }
      return citation;
    });

    // Check if all individual card checkboxes are checked
    const allChecked = updatedCitations.every(citation => citation.isChecked);
    setSourceChecked(allChecked);

    setCitations(updatedCitations);
  };

  const handleCopySelectedClick = () => {
    // Filter out the checked cards
    const selectedCitations = citations.filter((citation) =>
      citation.isChecked
    );

    if (selectedCitations == "") {
      toast.warn(`Select citations to copy`);
      return;
    }


    // Concatenate the data of checked cards for copying
    const copiedText = selectedCitations
      .map((citation) => `Title: ${citation.title}\nFull Citation: ${citation.fullCitation}\nIn-Text Citation: ${citation.publicationDate.year}\nCitation Style: ${citation.textStyle}\n\n`)
      .join("");

    // Use the copiedText as needed (e.g., copy to clipboard)
    navigator.clipboard.writeText(copiedText)

    toast.success("Citations copied to clipboard!");
  };


  const createBlobAndDownload = (content, fileType, fileName) => {
    const blob = new Blob([content], { type: fileType });

    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = fileName;
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
    URL.revokeObjectURL(anchor.href);
  };

  const handleBibtexDownload = () => {
    const selectedCitations = citations.filter((citation) =>
      citation.isChecked
    );

    if (selectedCitations.length === 0) {
      toast.warn(`Select citations to download`);
      return;
    }

    const bibtexContent = selectedCitations
      .map(
        (citation) =>
          `Title: ${citation.title}\nFull Citation: ${citation.fullCitation}\nIn-Text Citation: ${citation.publicationDate.year}\nCitation Style: ${citation.textStyle}\n\n\n`
      )
      .join('');

    createBlobAndDownload(
      bibtexContent,
      'application/x-bibtex',
      'citations.bib'
    );

  };

  const createPdfDocument = async (citations) => {
    // Assuming citations is an array of objects with properties: title, fullCitation, and publicationDate
    const pdfContent = citations
      .map((citation) => {
        return `<div>
                        <h5>Title: ${citation.title}</h5>
                        <p>Full Citation: ${citation.fullCitation}</p>
                        <p>In-Text Citation: ${citation.publicationDate.year}</p>
                        <p>Citation Style: ${citation.textStyle}</p><br/>
                        <hr>
                    </div>`;
      })
      .join('');

    const element = document.createElement('div');
    element.innerHTML = pdfContent;

    return html2pdf(element, {
      margin: 10,
      filename: 'citations.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    });

    const data = {
      userId: sessionStorage.getItem("userId"),
      organizationId: sessionStorage.getItem("organizationId"),
      actionType: "downloadCitation",
    };

    const addLogs = await addUserLogs(data);
    if (addLogs.status !== 200) {
      toast.error(addLogs.message);
    }
  };

  const handlePDFDownload = async () => {
    const selectedCitations = citations.filter((citation) => citation.isChecked);

    if (selectedCitations.length === 0) {
      toast.warn(`Select citations to download`);
      return;
    }

    try {
      const pdf = await createPdfDocument(selectedCitations);
      saveAs(pdf, 'citations.pdf');

      const data = {
        userId: sessionStorage.getItem("userId"),
        organizationId: sessionStorage.getItem("organizationId"),
        actionType: "downloadCitation",
      };

      const addLogs = await addUserLogs(data);
      if (addLogs.status !== 200) {
        toast.error(addLogs.message);
      }

    } catch (error) {
      console.error("Error generating PDF:", error);
    }
  };

  const handleWordDownload = async () => {
    const selectedCitations = citations.filter((citation) =>
      citation.isChecked
    );

    if (selectedCitations.length === 0) {
      toast.warn(`Select citations to download`);
      return;
    }

    const wordDocContent = selectedCitations
      .map(
        (citation) =>
          `<p>Title: ${citation.title}</p><p>Full Citation: ${citation.fullCitation}</p><p>In-Text Citation: ${citation.publicationDate.year}</p><p>Citation Style: ${citation.textStyle}</p><hr>`
      )
      .join('');

    const htmlContent = `
    <html>
      <head>
        <meta charset="UTF-8">
        <style>
          hr {
            border: 1px solid black;
          }
        </style>
      </head>
      <body>
        ${wordDocContent}
      </body>
    </html>`;

    const blob = new Blob([htmlContent], { type: 'application/msword' });

    const anchor = document.createElement('a');
    anchor.href = URL.createObjectURL(blob);
    anchor.download = 'citations.doc';
    anchor.click();
    URL.revokeObjectURL(anchor.href);

    const data = {
      userId: sessionStorage.getItem("userId"),
      organizationId: sessionStorage.getItem("organizationId"),
      actionType: "downloadCitation",
    };

    const addLogs = await addUserLogs(data);
    if (addLogs.status !== 200) {
      toast.error(addLogs.message);
    }
  };




  const handleDeleteSelectedClick = async () => {
    // Filter out the checked cards
    const selectedCitations = citations.filter((citation) => citation.isChecked);

    if (selectedCitations.length === 0) {
      toast.warn(`Select citations to delete`);
      return;
    }

    // Extract the IDs of selectedCitations
    const selectedIds = selectedCitations.map((citation) => citation._id);

    // Show a confirmation toast
    const toastId = showConfirmationToast("You won't be able to restore this citation(s).\nAre you sure you want to delete this?",
      () => handleConfirmDeleteSelected(selectedIds, toastId), // onConfirm
      () => toast.dismiss(toastId) // onCancel
    );

    // ... (existing code)
  };

  const handleConfirmDeleteSelected = async (selectedIds, toastId) => {
    try {
      // Call the API to delete the selected citations
      const response = await citationService.deleteSelectedCitations(selectedIds);

      console.log("Backend Response:", response);
      if (response.status === 200) {
        toast.success("Deleted successfully");
        fetchCitationDetails();
        const data = {
          userId: sessionStorage.getItem("userId"),
          organizationId: sessionStorage.getItem("organizationId"),
          actionType: "deleteCitation",
        };

        const addLogs = await addUserLogs(data);
        if (addLogs.status !== 200) {
          toast.error(addLogs.message);
        }
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }

    // Dismiss the confirmation toast
    toast.dismiss(toastId);
  };

  const fetchCitationDetails = async () => {
    try {
      const email = sessionStorage.getItem("currentUserEmail");
      let data = { email }
      const response = await citationService.getCitationDetails(data);
      if (response.status === 200) {
        const reversedCitations = response.citationDetails.reverse();

        setInitialCitations(reversedCitations);
        setTotalSource(response.citationDetails.length);
        setTotalSearchedSource(response.citationDetails.length)
        setCitations(reversedCitations);
        setTotalSort('');
        setDisplayByDate(false)
      } else {
        console.error("Error fetching citation details:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  useEffect(() => {
    fetchCitationDetails(); // Fetch data when the component mounts
  }, []);

  const yearsArray = [...new Set(initialCitations.map(citation => citation.publicationDate.year))];



  //////////////////////////////////////////////     SORTING       &&&&     SEARCHING                   ///////////////////////////////////////////////////////


  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    const newSearchTerm = e.target.value.toLowerCase();
    // setSearchTerm(newSearchTerm);

    const filteredCitations = initialCitations.filter((citation) =>
      citation.title.toLowerCase().includes(newSearchTerm)
    );

    setCitations((prevCitations) => (newSearchTerm ? filteredCitations : initialCitations));
    setTotalSearchedSource(() => (newSearchTerm ? filteredCitations.length : initialCitations.length));
  };



  const handleSortClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    const sortedCitations = [...initialCitations].sort((a, b) => {
      const titleA = a.title.toUpperCase();
      const titleB = b.title.toUpperCase();

      return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
    });

    setInitialCitations(sortedCitations);
    setCitations(sortedCitations);
  };

  //////////////////////////////////////////////     SORTING       &&&&     SEARCHING                   ///////////////////////////////////////////////////////



  let suggestionArray = [];


  // const isValidURL = (url) => {
  //   try {
  //     // Create a new URL object
  //     const parsedUrl = new URL(url);

  //     // Extract the hostname
  //     const hostname = parsedUrl.hostname;

  //     // Check if the hostname ends with a valid top-level domain
  //     const validUrlPattern = new RegExp('\\.(com|io|org|net|gov|edu|co)$', 'i');
  //     return validUrlPattern.test(hostname);
  //   } catch (e) {
  //     // If URL constructor throws an error, it's an invalid URL
  //     return false;
  //   }
  // };

  const isValidURL = (url) => {
    try {
      // If the URL doesn't start with a protocol, add 'https://'
      if (!/^https?:\/\//i.test(url)) {
        url = 'https://' + url;
      }

      // Create a new URL object
      const parsedUrl = new URL(url);

      // Extract the hostname
      const hostname = parsedUrl.hostname;

      // Check if the hostname ends with a valid top-level domain
      const validUrlPattern = new RegExp('\\.(com|io|org|net|gov|edu|co)$', 'i');
      return validUrlPattern.test(hostname);
    } catch (e) {
      // If URL constructor throws an error, it's an invalid URL
      return false;
    }
  };



  const validateInput = (selectedValue, searchInput) => {
    if (selectedValue === 0 && !isValidURL(searchInput)) {
      toast.warning("Please enter a valid URL");
      return false;
    }

    // Add more validation checks for other tabs if needed

    return true;
  };

  const handleCiteButtonClick = async () => {
    const checkailimit = await checkAIRequestLimit(userID);
    console.log(checkailimit, "_checkailimit");
    if (checkailimit == false) {
      toast.warn("AI request limit reached.");
      return;
    }
    if (!searchInput.trim()) {
      toast.warn("Search bar is empty");
      return;
    }

    let sourceitem = "";
    if (selectedValue === 0) {
      sourceitem = "Webpage";
    } else if (selectedValue === 1) {
      sourceitem = "Journal Article";
    } else {
      sourceitem = "Book";
    }

    if (validateInput(selectedValue, searchInput)) {
      setLoading(true)
      try {

        let response;
        let userId = userID
        let isCoAuthor = false

        if (sourceitem === 'Webpage') {
          response = await citationService.getCheckCitationWebpage(searchInput, citationStyle, sourceitem, userId, isCoAuthor);

          if (response.status === 200) {
            setLoading(false)
            console.log("Backend Response:", response);
            let responseOutput = response?.data?.output;
            const parsedData = JSON.parse(responseOutput[0]);
            suggestionArray = parsedData.replacements?.corrections;
            console.log("changedJSon_", suggestionArray, parsedData);
            setWebsiteData(suggestionArray)
          }
        }
        else if (sourceitem === 'Journal Article') {
          response = await citationService.getCheckCitationJournalArticle(searchInput, citationStyle, sourceitem, userId, isCoAuthor);

          if (response.status === 200) {
            setLoading(false)
            console.log("Backend Response:", response);
            let responseOutput = response?.data?.output;
            const parsedData = JSON.parse(responseOutput[0]);
            suggestionArray = parsedData.replacements?.corrections;
            console.log("changedJSon_", suggestionArray, parsedData);
            setWebsiteData(suggestionArray)
          }
        }
        else {
          response = await citationService.getCheckCitationBook(searchInput, citationStyle, sourceitem, userId, isCoAuthor);

          if (response.status === 200) {
            setLoading(false)
            console.log("Backend Response:", response);
            let responseOutput = response?.data?.output;
            const parsedData = JSON.parse(responseOutput[0]);
            suggestionArray = parsedData.replacements?.corrections;
            console.log("changedJSon_", suggestionArray, parsedData);
            setWebsiteData(suggestionArray)
          }
        }

      } catch (error) {
        setLoading(false)
        toast.warn("Error fetching citation details");
        console.error("Error calling backend API:", error.message);
      }


    }
  };



  const handleSaveCitations = async () => {

    if (publicationDayError === "Invalid day" || accessDayError === "Invalid day") {
      toast.error("Invalid date. Please provide correct date")
      return
    }
    const email = sessionStorage.getItem("currentUserEmail")
    // Include contributors data in the formData state
    const updatedFormData = {
      ...formData,
      email: email,
      contributors: contributors,
    };

    if (updatedFormData.title === "") {
      toast.error("Please provide citation title.")
      return
    }


    // Handle save logic here using updatedFormData
    try {
      const response = await citationService.getAddCitation(updatedFormData, citationStyle, selectedValue);
      console.log("Backend Response:", response);

      if (response.status == 200) {
        toast.success("Citation saved successfully")
        fetchCitationDetails()

        const data = {
          userId: sessionStorage.getItem("userId"),
          organizationId: sessionStorage.getItem("organizationId"),
          actionType: "addCitation",
        };

        const addLogs = await addUserLogs(data);
        if (addLogs.status !== 200) {
          toast.error(addLogs.message);
        }

        setFormData({
          heading: "",
          title: "",
          description: "",
          websiteName: "",
          contributors: [],
          url: "",
          doi: "",
          journalName: "",
          edition: "",
          publisher: "",
          articleNumber: "",
          volumeNumber: "",
          issueNumber: "",
          page: "",
          libraryDatabase: "",
          ISBN: "",
          ISSN: "",
          publicationDate: {
            year: "",
            month: "",
            day: "",
          },
          accessDate: {
            year: "",
            month: "",
            day: "",
          },
        })
        setContributors([
          { id: uuidv4(), position: '', firstName: '', middleName: '', lastName: '', suffix: '' },
        ])

        setSelectedValue(0)
        setPublicationYearError('')
        setAccessYearError('')
        setPublicationDayError('')
        setAccessDayError('')
        setPublicationDay('')
        setPublicationMonth('')
        setPublicationYear('')
        setAccessDay('')
        setAccessMonth('')
        setAccessYear('')

      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }

    //setSelectedValue("")
    handleModalClose();
  };


  const handleSaveCitations2 = async () => {
    // Handle save logic here
    const email = sessionStorage.getItem("currentUserEmail")
    try {
      const response = await citationService.getAddCitation2(websiteData[0], email);
      console.log("Backend Response:", response);
      if (response.status == 200) {
        toast.success("Citation saved successfully")
        fetchCitationDetails()
        setWebsiteData(null)
        const data = {
          userId: sessionStorage.getItem("userId"),
          organizationId: sessionStorage.getItem("organizationId"),
          actionType: "addCitation",
        };

        const addLogs = await addUserLogs(data);
        if (addLogs.status !== 200) {
          toast.error(addLogs.message);
        }
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }

    handleClose();

  };

  const handleDeleteCitation = async (id) => {


    // Show a confirmation toast
    const toastId = showConfirmationToast("You won't be able to restore this citation(s).\nAre you sure you want to delete this?",
      () => handleConfirmDeleteCitation(id, toastId), // onConfirm
      () => toast.dismiss(toastId) // onCancel
    );

  };

  const handleConfirmDeleteCitation = async (id, toastId) => {
    try {
      // Call the API to delete the citation
      const response = await citationService.deleteCitation(id);

      console.log('Backend Response:', response);
      if (response.status === 200) {
        toast.success('Deleted successfully');
        fetchCitationDetails();
        const data = {
          userId: sessionStorage.getItem("userId"),
          organizationId: sessionStorage.getItem("organizationId"),
          actionType: "deleteCitation",
        };

        const addLogs = await addUserLogs(data);
        if (addLogs.status !== 200) {
          toast.error(addLogs.message);
        }
      }
    } catch (error) {
      console.error('Error calling backend API:', error.message);
    }
  };

  const handleCitationStyleChange = async (event) => {
    const selectedCitationStyle = event.target.value;
    setSearchCitationStyle(selectedCitationStyle);

    // Use map to create a new array with updated fullCitation values
    const updatedCitations = await Promise.all(
      citations.map(async (e) => {

        try {
          console.log(e.contributors[0].lastName)
          const response = await citationService.getCitationStyleChange(
            e.title,
            e.publicationDate.year,
            e.contributors[0].lastName,
            e.contributors[0].firstName,
            e.sourceType,
            selectedCitationStyle
          );

          if (response.status === 200) {
            // Return a new object with updated fullCitation value
            return { ...e, fullCitation: response.data.output, textStyle: selectedCitationStyle };
          } else {
            console.error("Error fetching citation details:", response.message);
            // If there's an error, return the original object
            return e;
          }
        } catch (error) {
          console.error("Error calling backend API:", error.message);
          // If there's an error, return the original object
          return e;
        }
      })
    );

    // Update the state with the new array
    setCitations(updatedCitations);
  };



  //  for grid functionality

  const handleDropdownClick = (event) => {
    setAnchorElDropdown(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorElDropdown(null);
  };

  const handleDownloadDropdownClick = (event) => {
    setDownloadAnchorElDropdown(event.currentTarget);
  };

  const handleDownloadDropdownClose = () => {
    setDownloadAnchorElDropdown(null);
  };

  const handleNoGroup = () => {
    // Reset to the initial order
    setCitations(initialCitations);
    setTotalSort('')
  };

  const handleSortBySourceType = () => {
    const groupedCitations = {};
    console.log(initialCitations, "init cia");
    // Group citations by source type
    initialCitations.forEach(citation => {
      const sourceType = citation.sourceType || "Unknown";

      if (!groupedCitations[sourceType]) {
        groupedCitations[sourceType] = [];
      }

      groupedCitations[sourceType].push(citation);
    });

    // Create a flat array by concatenating groups
    const sortedCitations = Object.values(groupedCitations).flatMap(group => group);
    console.log(sortedCitations, "srted ci");
    setSourceTypeSort(sortedCitations);
    setTotalSort(sortedCitations.length)
    setCitations(sortedCitations);
    setDisplayByDate(false)
  };


  const filteredCitationsByYear = useMemo(() => {
    const sortedYearsArray = [...yearsArray].sort((a, b) => a - b);

    return sortedYearsArray.map((year) => ({
      year,
      citations: citations.filter(
        (cit) => cit.publicationDate.year === year
      ),
    }));
  }, [citations, yearsArray]);

  const handleSortByPublishedYear = () => {
    console.log("Before sorting:", initialCitations);

    setTotalSort(citations.length)
    setDisplayByDate(true)
  };


  const handleDropdownSelectforGrid = (selectedOption) => {
    console.log(selectedOption, "selected option");
    switch (selectedOption) {
      case "No Group":
        console.log("no group selected");
        fetchCitationDetails()
        console.log("Citations after resetting:", citations);
        break;
      case "Source Type":
        handleSortBySourceType();
        break;
      case "Published Year":
        console.log("published year selected");
        handleSortByPublishedYear();
        break;
      default:
        handleNoGroup();
        break;
    }
    handleDropdownClose();
  };

  const handleDropdownSelectforDownload = (selectedOption) => {
    console.log(selectedOption, "selected option");
    switch (selectedOption) {
      case "MS-Word":
        handleWordDownload()
        break;
      case "BibTeX":
        handleBibtexDownload()
        break;
      case "PDF":
        handlePDFDownload();
        break;
    }
    handleDropdownClose();
  };


  const Navtabs = () => {

    const handleTabChange = (event, newValue) => {
      setSelectedValue(newValue);

    };

    const handleSelectChange = (event) => {
      //setSelectedValue(-1); // Set an invalid index for the Tabs to avoid highlighting any tab
      setSelectedValue(event.target.value);
    };

    const list = [
      {
        "value": "Book Chapter",
        "name": "BOOK CHAPTER"
      },
      {
        "value": "Video",
        "name": "VIDEO"
      },
      {
        "value": "Online Dictionary Entry",
        "name": "ONLINE DICTIONARY ENTRY"
      },
      {
        "value": "Online News Article",
        "name": "ONLINE NEWS ARTICLE"
      },
      {
        "value": "Image",
        "name": "IMAGE"
      },
      {
        "value": "Report",
        "name": "REPORT"
      },
      {
        "value": "Website",
        "name": "WEBSITE"
      },
    ]

    return (
      <>

        <Loader loading={loading} />
        <SubscriptionRequired open={subReqOpen} setOpen={setSubReqOpen} />
        <div style={{ width: windowWidth <= 768 ? '100%' : "55%" }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', alignItems: 'center', width: "100%" }}>
            <Tabs value={selectedValue} onChange={handleTabChange} aria-label="basic tabs example" textColor="primary" indicatorColor="primary" style={{ width: "100%" }}>
              <Tab label="Webpage" />
              <Tab label="Journal Article" />
              <Tab label="Book" />
            </Tabs>
            {/* <Select
              value={selectedValue || ''}
              onChange={handleSelectChange}
              style={{ height: '48px', color: 'grey' }}
              className='nav-tabs'
            //displayEmpty
            >
              <MenuItem disabled={!accessToCiteManually} value="Book Chapter">BOOK CHAPTER</MenuItem>
              <MenuItem disabled={!accessToCiteManually} value="Video">VIDEO</MenuItem>
              <MenuItem disabled={!accessToCiteManually} value="Online Dictionary Entry">ONLINE DICTIONARY ENTRY</MenuItem>
              <MenuItem disabled={!accessToCiteManually} value="Online News Article">ONLINE NEWS ARTICLE</MenuItem>
              <MenuItem disabled={!accessToCiteManually} value="Image">IMAGE</MenuItem>
              <MenuItem disabled={!accessToCiteManually} value="Report">REPORT</MenuItem>
              <MenuItem disabled={!accessToCiteManually} value="Website">WEBSITE</MenuItem>
            </Select> */}
            <div className='nav-tabs' style={{ height: '48px', color: 'grey', width:"50%" }}>
              <SelectDropdownMui
                optionsList={list}
                selectLabel={"Select"}
                onChange={handleSelectChange}
                value={selectedValue || ''}
                disabled={!accessToCiteManually}
                boxheight={true}
              />
            </div>
          </Box>
        </div>

      </>

    );
  };


  ///////////////////////////////////////////////////////////////////////////////////////////////
  console.log(websiteData, "websiteData");


  return (
    <>
      <div style={{ width: '100%', margin: 'auto' }}>

        {userId &&
          <div className="col-10 row" style={{ marginTop: '60px' }}>
            <HeadingB text="Citation Generator"></HeadingB>
          </div>
        }
        <div fluid style={{ width: "100%" }}>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%" }}>
              <Navtabs />
            </div>
          </div>

          <div
            style={{
              width: '100%',
              zIndex: 1000,
              // height: '72.4px',
              marginTop: "2%",
              // top: '173px',
              // left: '306px',
              // borderRadius: '15px',
              // backgroundColor: '#FFFFFF',
              // paddingTop: '1%',
              // paddingLeft: '2%',
              // paddingRight: '6px',
              // boxShadow: '3px 1px 8px 0px rgba(0,0,0,0.25)',
              // marginLeft: 'auto',
              // marginRight: 'auto',
              display: "flex",
              flexWrap: "wrap"
            }}
          >

            <div className={windowWidth < 768 ? 'cite-sx' : "cite"} style={{ width: windowWidth < 768 ? "100%" : "80%" }}>
              <FormControl fullWidth>
                <TextField
                  id="standard-search"
                  label={selectedValue === 0 ? "Enter or Paste the URL" : selectedValue === 1 ? "Search or Paste Article Title" : "Search or Paste Book Title"}

                  type="search"
                  variant="standard"
                  value={searchInput}
                  onChange={(e) => {
                    setSearchInput(e.target.value)
                    if (!e.target.value.trim()) {
                      handleCloseAccordion();
                    }
                  }}
                />
              </FormControl>
            </div>

            <div className='cite-right' style={{ marginLeft: windowWidth > 768 ? "-1.2%" : "", width: windowWidth < 768 ? "48%" : "20%", marginTop: windowWidth < 768 ? "3%" : "", display: "flex", alignItems: "center", justifyContent: "center" }}>
              <B_Button className='diabledInDarkMode' disabled={!accessToCite} style={{ width: windowWidth < 768 ? "100%" : "60%", }} variant="primary" onClick={handleCiteButtonClick}>
                Cite
              </B_Button>

            </div>

            {websiteData && (
              <Accordion style={{ width: '97%', zIndex: 1001 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon className='zzz' />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className='zzz'><b>{websiteData[0].title}</b></Typography>

                </AccordionSummary>
                <AccordionDetails style={{ position: 'relative' }}>

                  <Typography className='zzz'><p>{websiteData[0].sourceType} | {websiteData[0].publicationDate.year}</p></Typography>


                  <div style={{ display: 'flex' }}>
                    <IconButton
                      style={{ position: 'absolute', bottom: '10px', right: '90px', color: "green" }}
                      onClick={handleSaveCitations2}

                    >
                      <LightTooltip title="Save Citation">
                        <AssignmentTurnedInIcon data-toggle="tooltip" />
                      </LightTooltip>
                    </IconButton>

                    <CopyToClipboard
                      text={`${websiteData[0].title} - ${websiteData[0].sourceType === "Journal Article" ? websiteData[0].journalName : websiteData[0].sourceType === "Webpage" ? websiteData[0].description : ""} \n ${websiteData[0].sourceType === "Book" ? `Edition - ${websiteData[0].edition}\n` : ""} ${websiteData[0].sourceType === "Book" ? `Publisher: ${websiteData[0].publisher} \n` : ""} ${websiteData[0].sourceType} | ${websiteData[0].publicationDate.year}\n ${(websiteData[0].sourceType === "Book" || (websiteData[0].sourceType === "Journal Article" && websiteData[0].doi !== "")) ? `DOI - ${websiteData[0].doi} \n` : ""}${websiteData[0].url}`}

                      onCopy={handleCopyClick}
                    >

                      <IconButton
                        style={{ position: 'absolute', bottom: '10px', right: '50px', color: "blue" }}

                      >
                        <LightTooltip title="Copy">
                          <ContentCopyIcon style={{ color: "blue" }} />
                        </LightTooltip>
                      </IconButton>
                    </CopyToClipboard>

                    <IconButton
                      style={{ position: 'absolute', bottom: '10px', right: '10px', color: "red" }}
                      onClick={handleCloseAccordion}

                    >
                      <LightTooltip title="Close">
                        <CloseIcon data-toggle="tooltip" />
                      </LightTooltip>
                    </IconButton>
                  </div>

                </AccordionDetails>
              </Accordion>
            )}

            <div className={windowWidth < 768 ? "plus-icon-sx" : "plus-icon"} style={{ width: windowWidth < 768 ? "48%" : "100%", marginTop: windowWidth < 768 ? "3%" : "", display: "flex" }}>
              <a href="#" className={windowWidth > 768 ? "cite-button" : "cite-button-sx"}
                onClick={(e) => {
                  if (!accessToCiteManually) {
                    e.preventDefault();
                  } else {
                    handleModalShow();
                  }
                }}
                style={{
                  whiteSpace: "nowrap",
                  pointerEvents: !accessToCiteManually ? "none" : "auto",
                  color: !accessToCiteManually ? "gray" : "",
                }}
              >
                <AddIcon /> Cite Manually
              </a>
              <CitationModal

                selectedValue={selectedValue}
                setSelectedValue={setSelectedValue}
                handleSaveCitations={handleSaveCitations}
                formData={formData}
                setFormData={setFormData}
                show={show}
                setShow={setShow}

                contributors={contributors}
                setContributors={setContributors}
                publicationYearError={publicationYearError}
                setPublicationYearError={setPublicationYearError}
                accessYearError={accessYearError}
                setAccessYearError={setAccessYearError}
                publicationDayError={publicationDayError}
                setPublicationDayError={setPublicationDayError}
                accessDayError={accessDayError}
                setAccessDayError={setAccessDayError}
                setSearchInput={setSearchInput}
                setWebsiteData={setWebsiteData}
                publicationDay={publicationDay}
                publicationMonth={publicationMonth}
                publicationYear={publicationYear}
                setPublicationDay={setPublicationDay}
                setPublicationMonth={setPublicationMonth}
                setPublicationYear={setPublicationYear}
                accessDay={accessDay}
                accessMonth={accessMonth}
                accessYear={accessYear}
                setAccessDay={setAccessDay}
                setAccessMonth={setAccessMonth}
                setAccessYear={setAccessYear}


              />
            </div>
          </div>
        </div>

        {/* <Container fluid style={{ marginTop: '10px', width: '97%' }}>
          <Row xs={12} className="justify-content-between">
            <Col>
 
            </Col>
 
 
          
          </Row>
        </Container>  */}

        <div style={{ marginTop: "10px", width: '100%', display: windowWidth <= 768 ? '' : "flex", justifyContent: "space-between" }}>
          <h3 className='zzz' style={{ width: "20%" }}>Citations</h3>
          {windowWidth < 768 && <div style={{ width: "100%" }}>
            <FormControl sx={{ width: "100%" }}>
              {totalSort ? <FormControlLabel
                control={<Checkbox className='zzz' checked={sourceChecked} onChange={handleSourceCheckboxChange} />}
                label={<Typography className='zzz' variant="body1" style={{ fontSize: '13px' }}>Source ({totalSearchedSource} out of {totalSource})</Typography>}
              /> : <FormControlLabel
                control={<Checkbox className='zzz' checked={sourceChecked} onChange={handleSourceCheckboxChange} />}
                label={<Typography className='zzz' variant="body1" style={{ fontSize: '13px' }}>Source ({totalSearchedSource})</Typography>}
              />
              }
            </FormControl>
          </div>}
        </div>

        <div style={{ marginTop: "10px", width: "100%" }}>
          <div style={{ width: "100%", display: "flex" }}>
            {windowWidth >= 768 && <div style={{ width: "20%" }}>
              <FormControl sx={{ width: "100%" }}>
                {totalSort ? <FormControlLabel
                  control={<Checkbox className='zzz' checked={sourceChecked} onChange={handleSourceCheckboxChange} />}
                  label={<Typography className='zzz' variant="body1" style={{ fontSize: '15px' }}>Source ({totalSearchedSource} out of {totalSource})</Typography>}
                /> : <FormControlLabel
                  control={<Checkbox className='zzz' checked={sourceChecked} onChange={handleSourceCheckboxChange} />}
                  label={<Typography className='zzz' variant="body1" style={{ fontSize: '15px' }}>Source ({totalSearchedSource})</Typography>}
                />
                }
              </FormControl>
            </div>}

            <div style={{ width: windowWidth > 768 ? "80%" : "100%" }} className="d-flex col-md-8 justify-content-end align-items-center ml-auto">
              {" "}
              {windowWidth > 768 && <TextField
                placeholder="Search"
                variant="outlined"
                size="small"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon className='search-icon' />
                    </InputAdornment>
                  ),
                }}
                style={{
                  width: "20%",
                }}
                value={searchTerm}
                onChange={handleSearchChange}
                className='search-bar'
              />}

              <div style={{ width: windowWidth > 768 ? "40%" : "100%", marginLeft: windowWidth < 768 ? "-2.3%" : "2%" }} >
                <SelectDropdownMui
                  optionsList={professionList}
                  selectLabel={"Select"}
                  onChange={handleCitationStyleChange}
                  value={searchCitationStyle}
                />
              </div>
              <div id="hoverChange" >
                <LightTooltip title="Delete">
                  <DeleteIcon
                    onClick={() => accessToDelete && handleDeleteSelectedClick()}

                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: 'pointer',
                      color: accessToDelete ? 'red' : "gray",
                      margin: '6px'
                    }}
                  />
                </LightTooltip>
              </div>
              <div id="hoverChange">
                <LightTooltip title="Copy">
                  <ContentCopyIcon
                    onClick={handleCopySelectedClick}
                    style={{
                      width: "22px",
                      height: "22px",
                      margin: '8px',
                      cursor: 'pointer',
                      color: 'blue'
                    }}
                  />
                </LightTooltip>
              </div>
              <div id="hoverChange">
                <LightTooltip title="Download">
                  <DownloadIcon
                    onClick={handleDownloadDropdownClick}
                    style={{
                      width: "24px",
                      height: "24px",
                      cursor: 'pointer',
                      color: 'green',
                      margin: '7px',
                    }}
                  />
                </LightTooltip>
              </div>
              <div id="hoverChange">
                <LightTooltip title="Sort">
                  <SortByAlphaIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      color: 'coral',
                      margin: '7px',
                      cursor: 'pointer',
                    }}
                    onClick={handleSortClick}
                  />
                </LightTooltip>
              </div>
              <div id="hoverChange">
                <LightTooltip title="Group By">
                  <ViewListIcon
                    style={{
                      width: "24px",
                      height: "24px",
                      color: '#C71585',
                      margin: '8px',
                      cursor: 'pointer',
                    }}
                    onClick={handleDropdownClick}
                  />
                </LightTooltip>
              </div>
            </div>

          </div>
        </div>
        {windowWidth <= 768 && <>
          <div style={{ width: "100%" }}>
            <TextField
              placeholder="Search"
              variant="outlined"
              size="small"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              style={{
                backgroundColor: 'white',
                width: "100%",
              }}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
        </>}

        {/* Dropdown Menu */}
        <Menu
          anchorEl={anchorElDropdown}
          open={Boolean(anchorElDropdown)}
          onClose={handleDropdownClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >

          <MenuItem onClick={() => handleDropdownSelectforGrid("No Group")}>
            <ListItemText primary="No Group" />
          </MenuItem>
          <MenuItem onClick={() => handleDropdownSelectforGrid("Source Type")}>
            <ListItemText primary="Source Type" />
          </MenuItem>
          <MenuItem onClick={() => handleDropdownSelectforGrid("Published Year")}>
            <ListItemText primary="Published Year" />
          </MenuItem>


        </Menu>

        <Menu
          anchorEl={downloadAnchorElDropdown}
          open={Boolean(downloadAnchorElDropdown)}
          onClose={handleDownloadDropdownClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
        >

          <MenuItem onClick={() => handleDropdownSelectforDownload("MS-Word")}>
            <ListItemText primary="MS-Word" />
          </MenuItem>
          <MenuItem onClick={() => handleDropdownSelectforDownload("BibTeX")}>
            <ListItemText primary="BibTeX" />
          </MenuItem>
          <MenuItem onClick={() => handleDropdownSelectforDownload("PDF")}>
            <ListItemText primary="PDF" />
          </MenuItem>


        </Menu>

        <div style={{ width: "100%", marginTop: "15px" }}>
          {displayByDate ?
            filteredCitationsByYear?.map(({ year, citations }) => (
              <div style={{ width: "100%" }} key={year}>
                {citations.length != 0 && <ul><li>{year}</li></ul>}
                {citations.map((citation) => (
                  <CitationCard
                    citation={citation}
                    handleCardCheckboxChange={handleCardCheckboxChange}
                    handleDeleteCitation={handleDeleteCitation}
                    fetchCitationDetails={fetchCitationDetails}
                    handleEditModalShow={handleEditModalShow}
                    setCitationId={setCitationId}
                    setWebsiteData={setWebsiteData}
                    setEditModalTitle={setEditModalTitle}
                  />
                ))}
              </div>
            ))
            :
            (citations.map((citation) => (
              <CitationCard
                citation={citation}
                handleCardCheckboxChange={handleCardCheckboxChange}
                handleDeleteCitation={handleDeleteCitation}
                fetchCitationDetails={fetchCitationDetails}
                handleEditModalShow={handleEditModalShow}
                setCitationId={setCitationId}
                setWebsiteData={setWebsiteData}
                setEditModalTitle={setEditModalTitle}
              />
            )))

          }
        </div>


      </div>
      <UpdateCitationModal
        citationId={citationId}
        citations={citations}
        editFormData={editFormData}
        editShow={editShow}
        setEditShow={setEditShow}
        handleEditModalClose={handleEditModalClose}
        contributors={contributors}
        fetchCitationDetails={fetchCitationDetails}
        editModalTitle={editModalTitle}
        publicationYearError={publicationYearError}
        setPublicationYearError={setPublicationYearError}
        accessYearError={accessYearError}
        setAccessYearError={setAccessYearError}
        publicationDayError={publicationDayError}
        setPublicationDayError={setPublicationDayError}
        accessDayError={accessDayError}
        setAccessDayError={setAccessDayError}
        publicationDay={publicationDay}
        publicationMonth={publicationMonth}
        publicationYear={publicationYear}
        setPublicationDay={setPublicationDay}
        setPublicationMonth={setPublicationMonth}
        setPublicationYear={setPublicationYear}
        accessDay={accessDay}
        accessMonth={accessMonth}
        accessYear={accessYear}
        setAccessDay={setAccessDay}
        setAccessMonth={setAccessMonth}
        setAccessYear={setAccessYear}

      />
    </>
  );
};


export default Citation1;
