import React, { useState, useEffect, useRef } from 'react';
import { Container, TextField, Button, IconButton, Modal, Card, CardContent, CardMedia, Typography, Grid, Menu, MenuItem, FormControl, FormControlLabel, Checkbox, Paper } from '@material-ui/core';
import { Modal as M_Modal, Button as B_Button, Form } from 'react-bootstrap';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import WindowIcon from '@mui/icons-material/Window';
import DownloadIcon from '@mui/icons-material/Download';
import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import AddIcon from '@mui/icons-material/Add';
import UploadIcon from '@mui/icons-material/CloudUploadOutlined';
import TableRowsIcon from '@mui/icons-material/TableRows';
import { Link } from 'react-router-dom';
import documentService from '../../services/document';
import { ListItemText } from "@material-ui/core";
import { toast } from 'react-toastify';
import { asBlob } from 'html-docx-js-typescript'
import { saveAs } from "file-saver";
import html2pdf from "html2pdf.js";
import { useHistory } from 'react-router-dom';
import { DocumentGridCard, DocumentListCard } from '../MyDocumentsCards';
import TemplatesIcon from '@mui/icons-material/CropOriginalOutlined';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import CloseIcon from '@material-ui/icons/Close';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import ComputerIcon from '@mui/icons-material/Computer';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateName } from '../../redux/coauthor/coauthor.action';
import Box from '@mui/material/Box';
import { Editor, EditorState } from 'draft-js';
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import { uploadDocument } from '../../redux/coauthor/coauthor.action';
import Pagination from '@mui/material/Pagination';
import DeleteIcon from '@mui/icons-material/Delete';
import { PermanentDeleteAllModal, DeleteAllMyDocsModal } from '../MyDocumentsCardsModal';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { shareFolderId } from '../../redux/coauthor/coauthor.action';
import LoopIcon from '@mui/icons-material/Loop';
import appendixPic from '../../static/Appendix2.png'
import articlePic from '../../static/Article2.png'
import blogPic from '../../static/Blog2.png'
import businessPic from '../../static/Business Letter2.png'
import coldemailPic from '../../static/Cold-Email2.png'
import essayPic from '../../static/Essay2.png'
import meetingPic from '../../static/meeting Notes2.png'
import resumePic from '../../static/Resume2.png'
import Loader from "../../utils/loader";
import "./CoAuthor.css"
import mammoth from "mammoth";
import axios from 'axios';
import * as pdfjs from 'pdfjs-dist/legacy/build/pdf';
import { checkFeatureAccess } from '../featureAccess';
import { addUserLogs } from '../../services/userLogServices';
import { useCheckFeatureAccess } from '../CheckFeature';
import SubscriptionRequired from '../SubscriptionRequired';
import { useCheckSubscriptionPlanAccess } from '../SubscriptionAccess';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'blue',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);


const templateData = [
  {
    "name": "Appendix",
    "imageUrl": appendixPic,
    "type": "Academic"
  },
  {
    "name": "Article",
    "imageUrl": articlePic,
    "type": "Academic"
  },
  {
    "name": "Blog",
    "imageUrl": blogPic,
    "type": "Professional"
  },
  {
    "name": "Essay",
    "imageUrl": essayPic,
    "type": "Academic"
  },
  {
    "name": "Business Letter",
    "imageUrl": businessPic,
    "type": "Email & Letters"
  },

  {
    "name": "Resume",
    "imageUrl": resumePic,
    "type": "Professional"
  },
  {
    "name": "Meeting-Notes",
    "imageUrl": meetingPic,
    "type": "Personal"
  },
  {
    "name": "Cold-Email",
    "imageUrl": coldemailPic,
    "type": "Email & Letters"
  },

]




const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "80%",
  height: '75vh', // 75% of the viewport height
  bgcolor: 'white',
  border: '2px solid #000',
  boxShadow: 24,

};















const CoAuthor = () => {

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [listView, setListView] = useState(false)
  const [documents, setDocuments] = useState([])
  const [sortOrder, setSortOrder] = useState('asc');
  const [downloadAnchorElDropdown, setDownloadAnchorElDropdown] = useState(null);
  const [isCreateNewFolderModalOpen, setIsCreateNewFolderModalOpen] = useState(false)
  const [folderName, setFolderName] = useState('');
  const [folderId, setFolderId] = useState('')
  const [folderOpen, setFolderOpen] = useState(false)
  const [combinedMyDocs, setCombinedMyDocs] = useState([])

  const [combinedDeletedDocs, setCombinedDeletedDocs] = useState([])
  const [myFolders, setMyFolders] = useState([])
  const [deletedFolders, setDeletedFolders] = useState([])
  const [myDocs, setMyDocs] = useState([]);
  const [deletedDocs, setDeletedDocs] = useState([]);
  const [sharedDocs, setSharedDocs] = useState([]);

  const [initialMyDocs, setInitialMyDocs] = useState([])
  const [initialDeletedDocs, setInitialDeletedDocs] = useState([])
  const [initialSharedDocs, setInitialSharedDocs] = useState([])
  const [initialMyFolders, setInitialMyFolders] = useState([])
  const [initialDeletedFolders, setInitialDeletedFolders] = useState([])

  const [isTemplatesModalOpen, setTemplatesModalOpen] = useState(false);

  const [myDocsCurrentPage, setMyDocsCurrentPage] = useState(1);
  const [sharedDocsCurrentPage, setSharedDocsCurrentPage] = useState(1);
  const [deletedDocsCurrentPage, setDeletedDocsCurrentPage] = useState(1);
  

  const [selectAllDeleteChecked, setSelectAllDeleteChecked] = useState(false);
  const [isPermanentDeleteAllModalOpen, setIsPermanentDeleteAllModalOpen] = useState(false);
  const [selectedIdsDeletePermanently, setSelectedIdsDeletePermanently] = useState()

  const [selectAllSharedChecked, setSelectAllSharedChecked] = useState(false);
  const [selectedIdsDeleteMyDocs, setSelectedIdsDeleteMyDocs] = useState()
  const [isDeleteAllMyDocsModalOpen, setIsDeleteAllMyDocsModalOpen] = useState(false);
  const [isDeleteAllSharedDocsModalOpen, setIsDeleteAllSharedDocsModalOpen] = useState(false);
  const [selectedIdsDeleteSharedDocs, setSelectedIdsDeleteSharedDocs] = useState()
  const currentNavItem = useSelector((state) => state.manageCoauthorNavReducer.currentCoauthorNavItem);
  const [myDocsCount, setMyDocsCount] = useState();
  const [deletedDocsCount, setDeletedDocsCount] = useState();
  const [sharedDocsCount, setSharedDocsCount] = useState();
  const [loading, setLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const { leftNavbarOpen } = useSelector(state => state.handleModelAndSideBavBar);
  const [itemsPerPage, setItemsPerPage] = useState( windowWidth > 991 ? 9 : windowWidth > 767 ? 8 : 3);
  const accessToPermanantDelete = useCheckFeatureAccess(currentNavItem,'deletePermanantly')
  const accessToNewProject = useCheckFeatureAccess(currentNavItem,'newProject')
  const accessToUpload =  useCheckFeatureAccess(currentNavItem,'uploadDocumnet')
  const accesstoUsetemplates = useCheckFeatureAccess(currentNavItem,'useTemplates')
  const accessToCreateFolder = useCheckFeatureAccess(currentNavItem,'createFolder')
  const accessToDeleteAllMyDocs = useCheckFeatureAccess(currentNavItem,'deletAll')
  const [subReqOpen, setSubReqOpen] = useState(false)
  const accessToDocScreen = useCheckSubscriptionPlanAccess("CoAuthor")


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  
  useEffect(()=>{
    console.log("accessTODocScreen_",accessToDocScreen)
    if(accessToDocScreen == false){
     setSubReqOpen(true)
    }
 },[accessToDocScreen])
  
  useEffect(() => {
        const handleResize = () => {
          const newWindowWidth = window.innerWidth;
          setWindowWidth(newWindowWidth);
          
          // Update itemsPerPage based on the new window width
          if (newWindowWidth > 991) {
            setItemsPerPage(9);
          } else if (newWindowWidth > 767) {
            setItemsPerPage(8);
          } else {
            setItemsPerPage(3);
          }
        };
    
        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
      }, []);

  const history = useHistory();
  const dispatch = useDispatch();



  const fileInputRef = useRef(null);

  const handleUploadButtonClick = () => {
    // Programmatically click the hidden file input
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleSelectAllDeleteChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllDeleteChecked(isChecked);
    setCombinedDeletedDocs((prevDocuments) => {
      return prevDocuments.map((doc) => ({
        ...doc,
        isChecked: isChecked
      }));
    });

  }
  const handleRefresh = () => {
    getAllDocuments();
  }
  const closePermanentDeleteAllModal = () => {
    setIsPermanentDeleteAllModalOpen(false);
  };



  const convertTextToHtml = (text) => {
    // Split the text into paragraphs based on line breaks
    const paragraphs = text.split('\n');

    // Map each paragraph to wrap it with <p> tags and handle line breaks
    const htmlContent = paragraphs.map((paragraph) => {
      // Replace consecutive line breaks with <br> tags within the paragraph
      const paragraphWithBreaks = paragraph.replace(/\n{2,}/g, '<br><br>');
      // Wrap the paragraph with <p> tags
      return `<p>${paragraphWithBreaks}</p>`;
    });

    // Join the paragraphs into a single HTML string
    return htmlContent.join('');
  };

  // const handleUploadFile = (event) => {
  //   const file = event.target.files[0];
  //   const fileName = file.name;
  //   console.log('Selected file:', file);
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const fileContent = e.target.result;
  //       const fileName = file.name;
  //       let contentToSend;

  //       if (file.name.endsWith('.txt')) {
  //         // Convert text content to HTML format
  //         const htmlContent = convertTextToHtml(fileContent);
  //         contentToSend = htmlContent;
  //       } else if (file.name.endsWith('.docx')) {
  //         const bodyContentRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
  //         const match = bodyContentRegex.exec(fileContent);
  //         contentToSend = match ? match[1] : '';
  //       } else {
  //         toast.warning("Please upload .txt or .docx file")
  //         return
  //       }

  //       // Redirect to CoAuthorNewProject with file content and name as parameters
  //       history.push({
  //         pathname: '/mynewdocuments',
  //         state: { fileContent: contentToSend, fileName }
  //       });
  //       dispatch(uploadDocument(true));
  //     };
  //     reader.readAsText(file);
  //   }
  // };

  const handleUploadFile = (event) => {
    const file = event.target.files[0];
    console.log('Selected file:', file);

    if (file) {
        const reader = new FileReader();

        reader.onload = async (e) => {
            const arrayBuffer = e.target.result;
            const fileName = file.name;
            let contentToSend;

            if (fileName.endsWith('.txt')) {
                const textContent = new TextDecoder().decode(arrayBuffer);
                contentToSend = convertTextToHtml(textContent); // Your function to convert plain text to HTML
            } else if (fileName.endsWith('.docx')) {
                try {
                    // Use mammoth to convert DOCX to HTML
                    let { value: htmlContent, messages } = await mammoth.convertToHtml({ arrayBuffer });


                    // Optionally log any warnings from mammoth (e.g., unsupported elements)
                    if (messages && messages.length) {
                        console.warn('Mammoth warnings:', messages);
                    }
                    if(!htmlContent){

                      // Convert arrayBuffer to string to apply regex
                      const decoder = new TextDecoder("utf-8");
                      const textContent = decoder.decode(arrayBuffer);
                  
                      // Use regex to extract content from body tag
                      const bodyContentRegex = /<body[^>]*>([\s\S]*?)<\/body>/i;
                      const match = bodyContentRegex.exec(textContent);
                      console.log(match,"match")
                      htmlContent = match ? match[1] : '';
                  }

                    // Set the HTML content to be sent
                    contentToSend = htmlContent;
                    console.log(contentToSend, "__contentToSend");

                } catch (error) {
                    console.error("Error reading DOCX file", error);
                    toast.error("Error reading DOCX file");
                    return;
                }
            }  else if (fileName.endsWith('.pdf')) {
              try {

                const pdf = await pdfjs.getDocument({ data: arrayBuffer }).promise;
                let htmlContent = '';
   
                for (let i = 1; i <= pdf.numPages; i++) {
                    const page = await pdf.getPage(i);
                    const content = await page.getTextContent();
   
                    // Create a div to wrap the content of each page
                    let pageHTML = `<div style="margin: 20px 0;">`;
   
                    // Group text items into paragraphs based on their y-coordinate proximity
                    let paragraphs = [];
                    let currentParagraph = [];
                    let lastY = null;
   
                    content.items.forEach((item) => {
                        const text = item.str;
                        const y = item.transform[5];
   
                        // If there's a significant difference in y-coordinates, consider it a new paragraph
                        if (lastY !== null && Math.abs(lastY - y) > 10) {
                            paragraphs.push(currentParagraph);
                            currentParagraph = [];
                        }
   
                        currentParagraph.push(text);
                        lastY = y;
                    });
   
                    // Add the last paragraph
                    if (currentParagraph.length > 0) {
                        paragraphs.push(currentParagraph);
                    }
   
                    // Convert paragraphs into HTML
                    paragraphs.forEach((paragraph) => {
                        pageHTML += `<p>${paragraph.join(' ')}</p>`;
                    });
   
                    pageHTML += `</div>`;
                    htmlContent += pageHTML;
                }
   
                console.log(htmlContent, "pdffiletext");
                contentToSend = htmlContent

                
            } catch (error) {
                console.error("Error reading  file", error);
                return;
            }
            } else if (fileName.endsWith('.doc')) {
                try {
                    // For .doc files, send the file to the backend API for processing
                    const formData = new FormData();
                    formData.append('file', file);

                    const response = await axios.post('https://api.mypoet360.com/api/texteditor/readDocFile', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        }
                    });

                    // Check if the response contains the processed content
                    if (response) {
                        contentToSend = response.data.content._body;
                        console.log(contentToSend, "__contentToSend");
                    } else {
                        console.error('No content received from API');
                        return;
                    }
                } catch (error) {
                    console.error("Error reading DOC file", error);
                    return;
                }
            } else {
                toast.warning("Please upload .txt, .doc, or .docx file");
                return;
            }

             // Redirect to CoAuthorNewProject with file content and name as parameters
        history.push({
          pathname: '/mynewdocuments',
          state: { fileContent: contentToSend, fileName }
        });
        dispatch(uploadDocument(true));
      };
      reader.readAsArrayBuffer(file);
    }
};

  // Select all cards
  const handleSelectAllChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllChecked(isChecked);
    setCombinedMyDocs((prevDocuments) => {
      return prevDocuments.map((doc) => ({
        ...doc,
        isChecked: isChecked
      }));
    });


    // setDeletedDocs((prevDocuments) => {
    //     return prevDocuments.map((doc) => ({
    //         ...doc,
    //         isChecked: isChecked
    //     }));
    // });

    // setSharedDocs((prevDocuments) => {
    //     return prevDocuments.map((doc) => ({
    //         ...doc,
    //         isChecked: isChecked
    //     }));
    // });
  };


  const handleSelectAllSharedChange = (event) => {
    const isChecked = event.target.checked;
    setSelectAllSharedChecked(isChecked);
    setSharedDocs((prevDocuments) => {
      return prevDocuments.map((doc) => ({
        ...doc,
        isChecked: isChecked
      }));
    });

  }


  const closeDeleteAllMyDocsModal = () => {
    setIsDeleteAllMyDocsModalOpen(false);
  };

  const closeDeleteAllSharedDocsModal = () => {
    setIsDeleteAllSharedDocsModalOpen(false);
  };
  // Download pdf and word doc
  const handleDownloadDropdownClick = (event) => {
    console.log("___IIIIII")
    setDownloadAnchorElDropdown(event.currentTarget);
  };

  const handleDownloadDropdownClose = () => {
    setDownloadAnchorElDropdown(null);
  };


  const handleMyDocsPageChange = (event, page) => {
    setMyDocsCurrentPage(page);
  };

  const handleSharedDocsPageChange = (event, page) => {
    setSharedDocsCurrentPage(page);
  };

  const handleDeletedDocsPageChange = (event, page) => {
    setDeletedDocsCurrentPage(page);
  };


  // Get documents for the current page
  const getMyDocsCurrentPage = () => {
    const startIndex = (myDocsCurrentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return combinedMyDocs.slice(startIndex, endIndex);
  };

  const getSharedDocsCurrentPage = () => {
    const startIndex = (sharedDocsCurrentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return sharedDocs.slice(startIndex, endIndex);
  };

  const getDeletedDocsCurrentPage = () => {
    const startIndex = (deletedDocsCurrentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return combinedDeletedDocs.slice(startIndex, endIndex);
  };





  const handleWordDownload = async() => {
    const selectedDocuments = combinedMyDocs.filter((docs) =>
      docs.isChecked
    );

    if (selectedDocuments.length === 0) {
      toast.warn(`Select documents to download`);
      return;
    }

    const wordDocContent = selectedDocuments
      .map(
        (doc) => {
          // const parsedJson = doc.onlyDocumentContent ? JSON.parse(doc.onlyDocumentContent) : { blocks: [{ text: '' }] };
          let description = doc.onlyDocumentContent
          return `<head><style>
          table, th, td {
              border: 1px solid black;
              border-collapse: collapse;
          }
          th, td {
              padding: 5px;
              text-align: left;
          }
      </style></head><body>${doc.documentName}<br/>${description}<br/>---------------------------------------------------<br/><br/></body>`
        }
      )
      .join('');


    asBlob(wordDocContent).then(docx => {
      saveAs(docx, `Documents.docx`);
    });

    const data = {
      userId: sessionStorage.getItem("userId"),
      organizationId: sessionStorage.getItem("organizationId"),
      actionType: "downloadDoc",
    };

    const addLogs = await addUserLogs(data);
    if (addLogs.status !== 200) {
      toast.error(addLogs.message);
    }
  }


  const handlePDFDownload = async() => {
    const selectedDocuments = combinedMyDocs.filter((docs) => docs.isChecked);

    if (selectedDocuments.length === 0) {
      toast.warn(`Select documents to download`);
      return;
    }

    const pdfContent = selectedDocuments
      .map((doc) => {
        // const parsedJson = doc.onlyDocumentContent ? JSON.parse(doc.onlyDocumentContent) : { blocks: [{ text: '' }] };
        let description = doc.onlyDocumentContent

        // Customize the HTML structure as needed
        return `<head><style>
        table, th, td {
            border: 1px solid black;
            border-collapse: collapse;
        }
        th, td {
            padding: 5px;
            text-align: left;
        }
    </style></head><body><div><h5>${doc.documentName}</h5><p>${description}</p>---------------------------------------------------------</div></body>`;
      })
      .join('');

    const element = document.createElement('div');
    element.innerHTML = pdfContent;

    html2pdf(element, {
      margin: 10,
      filename: 'Documents.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
    }).then((pdf) => {
      saveAs(pdf, 'Documents.pdf');
    });

    const data = {
      userId: sessionStorage.getItem("userId"),
      organizationId: sessionStorage.getItem("organizationId"),
      actionType: "downloadDoc",
    };

    const addLogs = await addUserLogs(data);
    if (addLogs.status !== 200) {
      toast.error(addLogs.message);
    }
  };

  const handleDeletedDocsCardChange = (index) => {
    const pageIndex = (deletedDocsCurrentPage - 1) * itemsPerPage;
    const docIndex = pageIndex + index;
    setCombinedDeletedDocs((prevDocuments) => {
      const newDocuments = [...prevDocuments];
      newDocuments[docIndex].isChecked = !newDocuments[docIndex].isChecked;

      // Check if all individual cards are checked or not
      const allChecked = newDocuments.every((doc) => doc.isChecked);
      setSelectAllDeleteChecked(allChecked);

      return newDocuments;
    });
  };


  const handleSharedDocsCardChange = (index) => {
    const pageIndex = (sharedDocsCurrentPage - 1) * itemsPerPage;
    const docIndex = pageIndex + index;
    setSharedDocs((prevDocuments) => {
      const newDocuments = [...prevDocuments];
      newDocuments[docIndex].isChecked = !newDocuments[docIndex].isChecked;

      // Check if all individual cards are checked or not
      const allChecked = newDocuments.every((doc) => doc.isChecked);
      setSelectAllSharedChecked(allChecked);

      return newDocuments;
    });
  };

  const handleDeleteAllPermanentlyClick = () => {


    const selectedDocuments = combinedDeletedDocs.filter((docs) => docs.isChecked);

    if (selectedDocuments.length === 0) {
      toast.warn(`Select documents to delete`);
      return;
    }

    const selectedIds = selectedDocuments.map((doc) => doc._id);

    setSelectedIdsDeletePermanently(selectedIds)

    setIsPermanentDeleteAllModalOpen(true);

  }

  const handleDeleteAllMyDocs = () => {

    const selectedDocuments = combinedMyDocs.filter((docs) => docs.isChecked);

    if (selectedDocuments.length === 0) {
      toast.warn(`Select documents to delete`);
      return;
    }

    const selectedIds = selectedDocuments.map((doc) => doc._id);

    setSelectedIdsDeleteMyDocs(selectedIds)

    setIsDeleteAllMyDocsModalOpen(true);

  }

  const handleDeleteAllSharedDocs = () => {


    const selectedDocuments = sharedDocs.filter((docs) => docs.isChecked);

    if (selectedDocuments.length === 0) {
      toast.warn(`Select documents to delete`);
      return;
    }

    const selectedIds = selectedDocuments.map((doc) => doc._id);

    setSelectedIdsDeleteSharedDocs(selectedIds)

    setIsDeleteAllSharedDocsModalOpen(true);

  }




  const handleDropdownSelectforDownload = (selectedOption) => {
    console.log(selectedOption, "selected option");
    switch (selectedOption) {
      case "MS-Word":
        handleWordDownload()
        break;
      case "PDF":
        console.log("PDF")
        handlePDFDownload();
        break;
    }
    handleDownloadDropdownClose();
  };



  // Sorting documents
  const handleSortClick = () => {
    const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortOrder(newSortOrder);
    const sortMyDocs = [...myDocs].sort((a, b) => {
      const titleA = a.documentName.toUpperCase();
      const titleB = b.documentName.toUpperCase();

      return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
    });
    const sortDeletedDocs = [...deletedDocs].sort((a, b) => {
      const titleA = a.documentName.toUpperCase();
      const titleB = b.documentName.toUpperCase();

      return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
    });
    const sortSharedDocs = [...sharedDocs].sort((a, b) => {
      const titleA = a.documentName.toUpperCase();
      const titleB = b.documentName.toUpperCase();

      return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
    });
    const sortMyFolders = [...myFolders].sort((a, b) => {
      const titleA = a.folderName.toUpperCase();
      const titleB = b.folderName.toUpperCase();

      return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
    });
    const sortDeletedFolders = [...deletedFolders].sort((a, b) => {
      const titleA = a.folderName.toUpperCase();
      const titleB = b.folderName.toUpperCase();

      return newSortOrder === "asc" ? titleA.localeCompare(titleB) : titleB.localeCompare(titleA);
    });

    setMyDocs(sortMyDocs);
    setDeletedDocs(sortDeletedDocs);
    setSharedDocs(sortSharedDocs);
    setMyFolders(sortMyFolders);
    setDeletedFolders(sortDeletedFolders);

  };


  // Search Documents
  const handleSearchChange = (e) => {
    const newSearchTerm = e.target.value.toLowerCase();

    const filteredMyDocs = initialMyDocs.filter((doc) =>
      doc.documentName.toLowerCase().includes(newSearchTerm)
    );
    const filteredDeletedDocs = initialDeletedDocs.filter((doc) =>
      doc.documentName.toLowerCase().includes(newSearchTerm)
    );
    const filteredSharedDocs = initialSharedDocs.filter((doc) =>
      doc.documentName.toLowerCase().includes(newSearchTerm)
    );
    const filteredMyFolders = initialMyFolders.filter((doc) =>
      doc.folderName.toLowerCase().includes(newSearchTerm)
    );
    const filteredDeletedFolders = initialDeletedFolders.filter((doc) =>
      doc.folderName.toLowerCase().includes(newSearchTerm)
    );

    setMyDocs(() => (newSearchTerm ? filteredMyDocs : initialMyDocs));
    setDeletedDocs(() => (newSearchTerm ? filteredDeletedDocs : initialDeletedDocs));
    setSharedDocs(() => (newSearchTerm ? filteredSharedDocs : initialSharedDocs));
    setMyFolders(() => (folderOpen) ? [] : (newSearchTerm ? filteredMyFolders : initialMyFolders));
    setDeletedFolders(() => (newSearchTerm ? filteredDeletedFolders : initialDeletedFolders))
  };


  // Individual Card Selection
  const handleMyDocsCardChange = (index) => {
    const pageIndex = (myDocsCurrentPage - 1) * itemsPerPage;
    const docIndex = pageIndex + index; // Calculate the index of the document in the entire list
    setCombinedMyDocs((prevDocuments) => {
      const newDocuments = [...prevDocuments];
      newDocuments[docIndex].isChecked = !newDocuments[docIndex].isChecked;

      // Check if all individual cards are checked or not
      const allChecked = newDocuments.every((doc) => doc.isChecked);
      setSelectAllChecked(allChecked);

      return newDocuments;
    });
  };



  // const handleDeletedDocsCardChange = (index) => {
  //     setDeletedDocs((prevDocuments) => {
  //       const newDocuments = [...prevDocuments];
  //       newDocuments[index].isChecked = !newDocuments[index].isChecked;

  //       // Check if all individual checkboxes are checked
  //       const areAllChecked = newDocuments.every((doc) => doc.isChecked);
  //       return newDocuments;
  //     });
  //   };

  // const handleSharedDocsCardChange = (index) => {
  //     setSharedDocs((prevDocuments) => {
  //       const newDocuments = [...prevDocuments];
  //       newDocuments[index].isChecked = !newDocuments[index].isChecked;

  //       // Check if all individual checkboxes are checked
  //       const areAllChecked = newDocuments.every((doc) => doc.isChecked);
  //       return newDocuments;
  //     });
  //   };



  // API Calls for getting docs, deleted docs and shared docs
  const getDocs = async (id) => {
    try {
      setLoading(true)
      const response = await documentService.getAllDocuments(id);
      if (response.status === 200) {
        setLoading(false)
        const modifiedData = response.data.map(doc => ({
          ...doc,
          ownerName: ""
        }));
        console.log("mystatus", modifiedData);
        setMyDocs(modifiedData);
        setInitialMyDocs(modifiedData);
        //setMyDocsCount(response.data.length)
      } else {
        console.error("Error fetching data:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  const getDeletedDocs = async () => {
    try {
      const response = await documentService.getDeletedDocuments();
      if (response.status === 200) {
        const modifiedData = response.data.map(doc => ({
          ...doc,
          ownerName: ""
        }));

        setDeletedDocs(modifiedData);
        setInitialDeletedDocs(modifiedData);
        //setDeletedDocsCount(response.data.length)
      } else {
        console.error("Error fetching data:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  const getSharedDocs = async () => {
    try {
      const response = await documentService.getSharedDocuments();
      if (response.status === 200) {
        setSharedDocs(response.data);
        setInitialSharedDocs(response.data)
        setSharedDocsCount(response.data.length)
      } else {
        console.error("Error fetching data:", response.message);
        setSharedDocs([])
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };


  const getAllDocuments = () => {
    getDocs();
    getDeletedDocs();
    getSharedDocs();
    getFolders();
    getDeletedFolders();
    handleDocsBackClick();
  }

  useEffect(() => {
    getAllDocuments()
  }, []);


  const handleTemplatesButtonClick = () => {
    setTemplatesModalOpen(true);
  };

  const handleCloseModal = () => {
    setTemplatesModalOpen(false);
  };


  useEffect(() => {
    setDocuments([...myDocs, ...deletedDocs, ...sharedDocs]);
    setCombinedMyDocs([...myFolders, ...myDocs])
    setCombinedDeletedDocs([...deletedFolders, ...deletedDocs])
  }, [myDocs, deletedDocs, sharedDocs, myFolders, deletedFolders]);


  useEffect(() => {
    if (folderOpen) {
      getDocs(folderId)
      setMyFolders("")
    } else {
      getAllDocuments()
    }
  }, [folderOpen])

  useEffect(() => {
    setMyDocsCount(combinedMyDocs.length)
    setDeletedDocsCount(combinedDeletedDocs.length)
  }, [combinedMyDocs, combinedDeletedDocs])



  const handleCreateNewFolder = () => {
    console.log("Open create folder")
    setIsCreateNewFolderModalOpen(true)
  }

  const closeCreateNewFolderModal = () => {
    setIsCreateNewFolderModalOpen(false)
    setFolderName('')
  }


  const createNewFolderClick = async (folderName) => {
    if (folderName.length === 0) {
      toast.warning("Please enter folder name")
      return
    }

    try {
      const response = await documentService.createFolder(folderName);
      console.log(response.data, "folder data");
      if (response.status === 200) {
        toast.success("Folder created successfully")
        getFolders()
      }

    } catch (error) {
      toast.error("Error creating folder")
      console.error('Error creating folder:', error);
    }


    closeCreateNewFolderModal()
    setFolderName("")
  }

  const getFolders = async () => {
    try {
      const response = await documentService.getAllFolders();
      if (response.status === 200) {
        const foldersReverse = response.data.reverse()
        setMyFolders(foldersReverse);
        setInitialMyFolders(foldersReverse)



      } else {
        console.error("Error fetching folders:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };

  const getDeletedFolders = async () => {
    try {
      const response = await documentService.getDeletedFolders();
      if (response.status === 200) {
        const foldersReverse = response.data.reverse()
        setDeletedFolders(foldersReverse);
        setInitialDeletedFolders(foldersReverse)



      } else {
        console.error("Error fetching folders:", response.message);
      }
    } catch (error) {
      console.error("Error calling backend API:", error.message);
    }
  };


  const handleFolderClick = (id) => {
    setFolderId(id)
    setFolderOpen(true)
    dispatch(shareFolderId(id))
  }

  const handleDocsBackClick = () => {
    setFolderId('')
    setFolderOpen(false)
    dispatch(shareFolderId(''))
  }

  const handleNewProject = () => {
    history.push('/coauthor-newproject')
  }

  const openRestrictCollaboration = () => {
    setSubReqOpen(true)
  }


  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  return (
    <>
      <Loader loading={loading} />
      <SubscriptionRequired open={subReqOpen} setOpen={setSubReqOpen}/>
      <TemplatesModal isOpen={isTemplatesModalOpen} onClose={handleCloseModal} />
      <div className='container-fluid'>
        <div className='row d-flex justify-content-between'>
          <div className='col-12 col-md-5 p-0' style={{ marginBottom: "10px" }}>
            <TextField label="Search" variant="outlined" className='InputStyle' size="small" style={{ width: '100%' }} onChange={handleSearchChange} />
          </div>
          <div className='col-5 col-md-2 p-0' style={{ marginBottom: "10px" }}>
            
              <Button 
              // className='FilledDarkBtn' 
              className='diabledInDarkMode'
              disabled={!accessToNewProject}
              variant="contained" color="primary"
              startIcon={<AddIcon style={{ fontSize: windowWidth > 959 && windowWidth < 1093 && leftNavbarOpen ? '0.8rem' : '1.3rem' }} />}
                style={{
                  whiteSpace: 'nowrap', width: "100%",
                  fontSize: windowWidth > 959 && windowWidth < 1093 && leftNavbarOpen ? '0.7rem' : '0.9rem',
                  lineHeight: windowWidth > 959 && windowWidth < 1093 && leftNavbarOpen ? '2.5 ' : '1.75'
                }}
                onClick={()=>handleNewProject()}
                >
                New Project
              </Button>
            
          </div>

          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none', }}
            onChange={handleUploadFile}
          />
          <Button
            className='col-6 col-md-2 OutlinedDarkBtn diabledInDarkMode'
            startIcon={<UploadIcon style={{ fontSize: windowWidth > 959 && windowWidth < 1093 && leftNavbarOpen ? '0.8rem' : '1.3rem' }} />}
            style={{ whiteSpace: 'nowrap', marginBottom: "10px", fontSize: windowWidth > 959 && windowWidth < 1093 && leftNavbarOpen ? '0.7rem' : '0.9rem' }}
            onClick={handleUploadButtonClick}
            disabled={!accessToUpload}
          >
            Upload Doc
          </Button>

          <Button
            className='col-5 col-md-2 OutlinedDarkBtn diabledInDarkMode'
            startIcon={<TemplatesIcon style={{ fontSize: windowWidth > 959 && windowWidth < 1093 && leftNavbarOpen ? '0.8rem' : '1.3rem' }} />}
            onClick={handleTemplatesButtonClick}
            style={{ whiteSpace: 'nowrap', marginBottom: "10px", fontSize: windowWidth > 959 && windowWidth < 1093 && leftNavbarOpen ? '0.7rem' : '0.9rem' }}
            disabled={!accesstoUsetemplates}
          >
            Use Templates
          </Button>
          {windowWidth < 767 &&
          <div className='col-6 d-flex justify-content-between p-0 align-items-center'>
            {!folderOpen &&
                <LightTooltip title="New folder">
                  <CreateNewFolderIcon style={{ color: accessToCreateFolder ? "gold" : "gray", fontSize: "1.3rem" }} onClick={() => accessToCreateFolder && handleCreateNewFolder()} />
                </LightTooltip>

            }
            <LightTooltip title={listView ? "Grid View" : "List View"}>
                {listView ? <WindowIcon style={{ color: "deepskyblue",fontSize: "1.3rem" }}onClick={() => setListView(!listView)} /> : <TableRowsIcon onClick={() => setListView(!listView)} style={{ color: "deepskyblue",fontSize: "1.3rem" }} />}
            </LightTooltip>


              <div><LightTooltip title="Sort"><SortByAlphaIcon style={{ color: "coral",fontSize: "1.3rem" }} onClick={() => handleSortClick()} /></LightTooltip></div>


              <div
                
              > <LightTooltip title="Download"><DownloadIcon style={{ color: "green",fontSize: "1.3rem" }}onClick={handleDownloadDropdownClick} /></LightTooltip></div>

              <div ><LightTooltip title="Delete"><DeleteIcon style={{ color: accessToDeleteAllMyDocs ? "red" : "gray",fontSize: "1.3rem" }} onClick={() => accessToDeleteAllMyDocs && handleDeleteAllMyDocs()} /></LightTooltip></div>
          </div>
          }
        </div>
        {windowWidth >= 767 &&
        <div className='row' style={{ marginTop: '10px' }}>
          <div className='col-md-7 mt-2'>
            <FormControl>
              {/* <FormControlLabel
                style={{ marginLeft: '10px' }}
                control={<Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />}
                label={<Typography>Select All</Typography>}
              /> */}
            </FormControl>
          </div>
          <div className='col-md-5 d-flex justify-content-end'>
            {!folderOpen &&
              <IconButton>
                <LightTooltip title="New folder">
                  <CreateNewFolderIcon style={{ color: accessToCreateFolder ? "gold" : "gray" }} onClick={() => accessToCreateFolder && handleCreateNewFolder()} />
                </LightTooltip>
              </IconButton>

            }

            <NewFolderModal show={isCreateNewFolderModalOpen} onHide={closeCreateNewFolderModal} onCreateClick={createNewFolderClick} folderName={folderName} setFolderName={setFolderName} />



            {/* <IconButton>
              {listView ? <div><WindowIcon onClick={() => setListView(false)} /></div> : <div><TableRowsIcon onClick={() => setListView(true)} /></div>}
            </IconButton> */}
            <LightTooltip title={listView ? "Grid View" : "List View"}>
              <IconButton onClick={() => setListView(!listView)}>
                {listView ? <WindowIcon style={{ color: "deepskyblue" }} /> : <TableRowsIcon style={{ color: "deepskyblue" }} />}
              </IconButton>
            </LightTooltip>


            <IconButton>
              <div><LightTooltip title="Sort"><SortByAlphaIcon style={{ color: "coral" }} onClick={() => handleSortClick()} /></LightTooltip></div>
            </IconButton>


            <IconButton>
              <div
                onClick={handleDownloadDropdownClick}
              > <LightTooltip title="Download"><DownloadIcon style={{ color: "green" }} /></LightTooltip></div>
            </IconButton>



            <IconButton>
              <div ><LightTooltip title="Delete"><DeleteIcon style={{ color: accessToDeleteAllMyDocs ? "red" : "gray" }} onClick={() => accessToDeleteAllMyDocs && handleDeleteAllMyDocs()} /></LightTooltip></div>
            </IconButton>
            <DeleteAllMyDocsModal show={isDeleteAllMyDocsModalOpen} onHide={closeDeleteAllMyDocsModal} ids={selectedIdsDeleteMyDocs} getAllDocuments={getAllDocuments} />

            <Menu
              anchorEl={downloadAnchorElDropdown}
              open={Boolean(downloadAnchorElDropdown)}
              onClose={handleDownloadDropdownClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >

              <MenuItem onClick={() => handleDropdownSelectforDownload("MS-Word")}>
                <ListItemText primary="MS-Word" />
              </MenuItem>

              <MenuItem onClick={() => handleDropdownSelectforDownload("PDF")}>
                <ListItemText primary="PDF" />
              </MenuItem>


            </Menu>

          </div>
        </div>
        }

        {(getMyDocsCurrentPage().length > 0) ?
          <>
            <div className='row'>
              <div className='col-md-12 mt-1 mb-3'><hr/><div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className='headingh2'>My Documents ({myDocsCount})</h2>

                <LightTooltip title="Refresh">
                  <IconButton onClick={handleRefresh} style={{ position: 'absolute', fontSize: '0.875rem', color: '#3f51b5', right: "20px" }} >
                    <LoopIcon />
                  </IconButton>
                </LightTooltip>
              </div></div>
              <div className='row'>
                <div className='col-md-12'>
                  <FormControl>
                    <FormControlLabel
                      style={{ marginLeft: '5px' }}
                      control={<Checkbox checked={selectAllChecked} onChange={handleSelectAllChange} />}
                      label={<Typography>Select All </Typography>}
                    />
                  </FormControl>
                </div>
              </div>
              <div className='col-md-12 mb-3'>
                {folderOpen &&
                  <Button className='FilledDarkBtn' onClick={() => { handleDocsBackClick() }}><KeyboardBackspaceIcon /></Button>
                }
              </div>


              {getMyDocsCurrentPage().map((document, index) => {
                // const parsedJson = document.onlyDocumentContent ? JSON.parse(document.onlyDocumentContent) : { blocks: [{ text: '' }] };
                let description = document.onlyDocumentContent?.replace(/<[^>]*>/g, '')
                description = description?.replace(/&nbsp;/g, ' ');
                let toStatus = document.tostatus

                return (
                  <div className={listView ? 'col-md-12' : 'col-md-6 col-lg-4 mt-3 pl-0'} key={index}>
                    {listView ? (
                      <DocumentListCard
                        description={description}
                        onCardCheckboxChange={() => handleMyDocsCardChange(index)}
                        getAllDocuments={getAllDocuments}
                        type={'MyDocuments'}
                        document={document}
                        toStatus={toStatus}
                        handleFolderClick={handleFolderClick}
                        isCoAuthor={true}
                        restrictCollaboration={openRestrictCollaboration}
                      />
                    ) : (

                      <DocumentGridCard

                        description={description}
                        onCardCheckboxChange={() => handleMyDocsCardChange(index)}
                        getAllDocuments={getAllDocuments}
                        type={'MyDocuments'}
                        pendingDocuments={document.pendingDocuments}
                        document={document}

                        toStatus={toStatus}
                        setMyDocs={setMyDocs}

                        handleFolderClick={handleFolderClick}
                        isCoAuthor={true}
                        restrictCollaboration={openRestrictCollaboration}

                      />
                    )}
                  </div>
                )

              })}

            </div>

            {combinedMyDocs.length > itemsPerPage && (

              <div className="pagination-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination
                  count={Math.ceil(combinedMyDocs.length / itemsPerPage)}
                  page={myDocsCurrentPage}
                  onChange={handleMyDocsPageChange}
                  color="primary"
                  size="large"
                  showFirstButton
                  showLastButton
                  shape="rounded"

                />
              </div>

            )
            }
          </>
          :
          <>
            <div className='row'>
              <div className='col-md-12 mt-1 mb-3'><hr className='headingh2' /><div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <h2 className='headingh2'>My Documents ({myDocsCount})</h2>

                <LightTooltip title="Refresh">
                  <IconButton onClick={handleRefresh} style={{ position: 'absolute', fontSize: '0.875rem', color: '#3f51b5', right: "20px" }} >
                    <LoopIcon />
                  </IconButton>
                </LightTooltip>
              </div></div>


              <div className='col-md-12'>
                {folderOpen &&
                  <Button variant="contained" color="primary" onClick={() => { handleDocsBackClick() }}><KeyboardBackspaceIcon /></Button>
                }
              </div>
              {/* <div className='col-md-12 mt-4 mb-3'><h3>No Documents present</h3></div> */}

            </div>

          </>
        }

        {(getSharedDocsCurrentPage().length > 0) &&
          <>
            <div className='row'>
              <div className='col-md-12 mt-1 mb-3 headingh2'><hr /><h2>Shared With Me ({sharedDocsCount})</h2></div>

              {/* <div className='col-md-12 mt-2 mb-3'>
                <div className='row'>
                  <div className='col-md-7'>
                    <FormControl>
                      <FormControlLabel
                        style={{ marginLeft: '10px' }}
                        control={<Checkbox checked={selectAllSharedChecked} onChange={handleSelectAllSharedChange} />}
                        label={<Typography>Select All </Typography>}
                      />
                    </FormControl>
                  </div>
                  <div className='col-md-5 d-flex justify-content-end'>
                    <LightTooltip title={"Delete"}>
                      <IconButton onClick={() => handleDeleteAllSharedDocs()}>
                        <DeleteIcon style={{ color: "red" }} />
                      </IconButton>
                    </LightTooltip>

                    <DeleteAllMyDocsModal show={isDeleteAllSharedDocsModalOpen} onHide={closeDeleteAllSharedDocsModal} ids={selectedIdsDeleteSharedDocs} getAllDocuments={getAllDocuments} />
                  </div>
                </div>
              </div> */}

              {getSharedDocsCurrentPage().map((document, index) => {
                // const parsedJson = document.onlyDocumentContent ? JSON.parse(document.onlyDocumentContent) : { blocks: [{ text: '' }] };
                let description = document.onlyDocumentContent?.replace(/<[^>]*>/g, '')
                description = description?.replace(/&nbsp;/g, ' ');
                let toStatus = document.tostatus

                return (
                  <div className={listView ? 'col-md-12' : 'col-md-6 col-lg-4 mt-3 pl-0'} key={index}>
                    {listView ? (
                      <DocumentListCard
                        description={description}
                        onCardCheckboxChange={() => handleSharedDocsCardChange(index)}
                        getAllDocuments={getAllDocuments}
                        type={'SharedWithMe'}
                        toStatus={toStatus}
                        document={document}
                        isCoAuthor={true}
                      />
                    ) : (
                      <DocumentGridCard
                        description={description}
                        onCardCheckboxChange={() => handleSharedDocsCardChange(index)}
                        getAllDocuments={getAllDocuments}
                        type={'SharedWithMe'}
                        toStatus={toStatus}
                        isCoAuthor={true}
                        document={document}
                      />


                    )}
                  </div>
                )

              })}

            </div>


            {sharedDocs.length > itemsPerPage && (

              <div className="pagination-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination
                  count={Math.ceil(sharedDocs.length / itemsPerPage)}
                  page={sharedDocsCurrentPage}
                  onChange={handleSharedDocsPageChange}
                  color="primary"
                  size="large"
                  showFirstButton
                  showLastButton
                  shape="rounded"

                />
              </div>

            )
            }

          </>}

        {(getDeletedDocsCurrentPage().length > 0) &&

          <>
            <div className='row'>
              <div className='col-md-12 mt-1 mb-3 headingh2'><hr /><h2>Deleted Documents ({deletedDocsCount})</h2></div>
              <div className='col-md-12 mt-2 mb-3'>
                <div className='row'>
                  <div className='col-md-7'>
                    <FormControl>
                      <FormControlLabel
                        style={{ marginLeft: '6px' }}
                        control={<Checkbox checked={selectAllDeleteChecked} onChange={handleSelectAllDeleteChange} />}
                        label={<Typography>Select All </Typography>}
                      />
                    </FormControl>
                  </div>
                  <div className='col-md-5 d-flex justify-content-end'>
                    <LightTooltip title={"Delete Permanently"}>
                      <IconButton disabled={!accessToPermanantDelete} onClick={() => handleDeleteAllPermanentlyClick()}>
                        <DeleteIcon style={{ color: accessToPermanantDelete ? "red" : "gray"}} />
                      </IconButton>
                    </LightTooltip>

                    <PermanentDeleteAllModal show={isPermanentDeleteAllModalOpen} onHide={closePermanentDeleteAllModal} ids={selectedIdsDeletePermanently} getAllDocuments={getAllDocuments} />
                  </div>
                </div>
              </div>

              {getDeletedDocsCurrentPage().map((document, index) => {
                // const parsedJson = document.onlyDocumentContent ? JSON.parse(document.onlyDocumentContent) : { blocks: [{ text: '' }] };
                let description = document.onlyDocumentContent?.replace(/<[^>]*>/g, '')
                description = description?.replace(/&nbsp;/g, ' ');
                let toStatus = document.tostatus

                return (
                  <div className={listView ? 'col-md-12' : 'col-md-6 col-lg-4 mt-3 pl-0'} key={index}>
                    {listView ? (
                      <DocumentListCard
                        description={description}
                        getAllDocuments={getAllDocuments}
                        type={'DeletedDocuments'}
                        onCardCheckboxChange={() => handleDeletedDocsCardChange(index)}
                        toStatus={toStatus}
                        document={document}

                      />
                    ) : (
                      <DocumentGridCard
                        description={description}
                        getAllDocuments={getAllDocuments}
                        type={'DeletedDocuments'}
                        onCardCheckboxChange={() => handleDeletedDocsCardChange(index)}
                        toStatus={toStatus}
                        document={document}
                      />
                    )}
                  </div>
                )

              })}

            </div>

            {combinedDeletedDocs.length > itemsPerPage && (

              <div className="pagination-container" style={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Pagination
                  count={Math.ceil(combinedDeletedDocs.length / itemsPerPage)}
                  page={deletedDocsCurrentPage}
                  onChange={handleDeletedDocsPageChange}
                  color="primary"
                  size="large"
                  showFirstButton
                  showLastButton
                  shape="rounded"

                />
              </div>

            )
            }

          </>
        }
      </div>
    </>
  )
}


const NewFolderModal = ({ show, onHide, onCreateClick, folderName, setFolderName }) => {


  const handleFolderNameChange = (e) => {
    setFolderName(e.target.value);
  };



  return (
    <M_Modal show={show} onHide={onHide} centered id="permanentDeleteAllModal">
      <M_Modal.Header closeButton>
        <M_Modal.Title id="contained-modal-title-vcenter">
          Create New Folder
        </M_Modal.Title>
      </M_Modal.Header>
      <M_Modal.Body>
        <Form.Control type="text" placeholder="Enter folder name" value={folderName} maxLength={25} onChange={handleFolderNameChange} />
      </M_Modal.Body>
      <M_Modal.Footer>
        <B_Button variant="secondary" onClick={onHide}>
          Cancel
        </B_Button>
        <B_Button variant="primary" onClick={() => onCreateClick(folderName)}>
          Create New Folder
        </B_Button>
      </M_Modal.Footer>
    </M_Modal>
  );
};


// for template modal

const TemplatesModal = ({ isOpen, onClose }) => {
  const [selectedCategory, setSelectedCategory] = useState('All Templates'); // 'all' is the default category
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };
  const getCategoryIcon = () => {
    switch (selectedCategory) {
      case 'All Templates':
        return <SchoolIcon />;
      case 'Academic':
        return <SchoolIcon />;
      case 'Professional':
        return <WorkIcon />;
      case 'Personal':
        return <ComputerIcon />;
      case 'Email & Letters':
        return <MailOutlineIcon />;
      default:
        return <SchoolIcon />;
    }
  };


  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >



      <div className='useTemplateModal'>
        <IconButton style={{ position: 'absolute', top: '10px', right: '10px' }} onClick={onClose}>
          <CloseIcon className='useTemplateModalIcon' />
        </IconButton>

        {/* Category Buttons */}
        <Container>
          <Button
            variant={selectedCategory === 'All Templates' ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '5px',  ...(windowWidth < 375 && { width: '100%' })}}
            onClick={() => handleCategoryClick('All Templates')}
          >
            All Templates
          </Button>

          <Button
            variant={selectedCategory === 'Academic' ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '5px',  ...(windowWidth < 375 && { width: '100%' }), }}
            onClick={() => handleCategoryClick('Academic')}
          >
            Academic
          </Button>

          <Button
            variant={selectedCategory === 'Professional' ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '5px',  ...(windowWidth < 375 && { width: '100%' }), }}
            onClick={() => handleCategoryClick('Professional')}
          >
            Professional
          </Button>

          <Button
            variant={selectedCategory === 'Personal' ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '5px',  ...(windowWidth < 375 && { width: '100%' }), }}
            onClick={() => handleCategoryClick('Personal')}
          >
            Personal
          </Button>

          <Button
            variant={selectedCategory === 'Email & Letters' ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '5px',  ...(windowWidth < 375 && { width: '100%' }), }}
            onClick={() => handleCategoryClick('Email & Letters')}
          >
            Email & Letters
          </Button>
        </Container>

        <Container style={{ marginTop: '15px' }}><h4 className='headingh2'>{getCategoryIcon()} {selectedCategory}</h4></Container>

        <Container><TemplateGallery templateData={templateData} selectedCategory={selectedCategory} /></Container>
      </div>

    </Modal>
  );
};


const TemplateGallery = ({ templateData, selectedCategory }) => {




  const templateData1 = [
    {
      name: "Cold-Email",
      data: `
      <p><h3>Subject Line:</h3> Compelling Subject That Grabs Attention</p>

      <p><h3>Greeting:</h3> Hi [Recipient's Name],</p>

      <p><h3>Introduction:</h3> I hope this email finds you well. My name is [Your Name], and I am reaching out to you. [Briefly introduce yourself and mention a common interest or connection.]</p>

      <p><h3>Value Proposition:</h3> I want to share with you the exciting value [Your Product/Service/Proposition] brings. [Explain how it can benefit the recipient and address a pain point.]</p>

      <p><h3>Relevance:</h3> Considering [Recipient's Company/Needs/Challenges], I believe our [Product/Service/Proposition] is particularly relevant. [Customize and explain why it aligns with their specific situation.]</p>

      <p><h3>Call to Action:</h3> I would love to discuss this further with you. Could we schedule a brief call at your earliest convenience? [Provide options for scheduling or another clear CTA.]</p>

      <p><h3>Social Proof (Optional):</h3> We've had success working with companies like [Client Name] and achieved [mention any notable achievements]. [Optional: Include a brief testimonial or success story.]</p>

      <p><h3>Closing:</h3> Thank you for considering [Your Product/Service/Proposition]. I look forward to the possibility of working together.</p>

      <p><h3>Best regards:</h3> <br> [Your Full Name] <br> [Your Position] <br> [Your Company]</p>

      <p><h3>Contact Information:</h3> <br> [Your Email Address] <br> [Your Phone Number]</p>


      </div>
      `    },
    {
      name: "Resume",
      data: `
      <div style={{margin:"10px"}}>
      <span><strong>Your Name :</strong>       Lorem ipsum dolor sit amet, consectetuer adipiscing elit </span> <br />

    <span>  <strong>Address :</strong>

      123 Your Street <br />
      Your City, ST 12345 <br />
      (123) 456-7890 <br />
      no_reply@example.com
    </span>

    <div class="section" style="margin-top: 20px">
      <h3>EXPERIENCE</h3>
      <p>
        <strong>Company, Location — Job Title</strong><br />
        MONTH 20XX - PRESENT<br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh.
      </p>

      <p>
        <strong>Company, Location — Job Title</strong><br />
        MONTH 20XX - MONTH 20XX<br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh.
      </p>

      <p>
        <strong>Company, Location — Job Title</strong><br />
        MONTH 20XX - MONTH 20XX<br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh.
      </p>
    </div>

    <div class="section" style="margin-top: 20px">
      <h3>EDUCATION</h3>
      <p>
        <strong>School Name, Location — Degree</strong><br />
        MONTH 20XX - MONTH 20XX<br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore.
      </p>

      <p>
        <strong>School Name, Location — Degree</strong><br />
        MONTH 20XX - MONTH 20XX<br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh.
      </p>
    </div>

    <div class="section" style="margin-top: 20px">
      <h3>PROJECTS</h3>
      <p>
        <strong>Project Name — Detail</strong><br />
        Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
      </p>
    </div>

    <div class="section" style="margin-top: 20px">
      <h3>SKILLS</h3>
      <ul>
        <li>Lorem ipsum dolor sit amet.</li>
        <li>Consectetuer adipiscing elit.</li>
        <li>Sed diam nonummy nibh euismod tincidunt.</li>
        <li>L​​​‌​aoreet dolore magna aliquam erat volutpat.</li>
      </ul>
    </div>

    <div class="section" style="margin-top: 20px">
      <h3>AWARDS</h3>
      <p>
        <span>Lorem ipsum dolor sit amet Consectetuer adipiscing elit, Sed diam nonummy Nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</span>
      </p>

      <p>
        <span>Lorem ipsum dolor sit amet Consectetuer adipiscing elit, Sed diam nonummy Nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat.</span>
      </p>
    </div>

    <div class="section" style="margin-top: 20px">
      <h3>LANGUAGES</h3>
      <p>Lorem ipsum, Dolor sit amet, Consectetuer</p>
    </div>  <div>

      `
    },
    {
      name: "Essay",
      data: `<div style={{margin:"10px"}}>



      <h3>Introduction</h3>

      <p>
        Your essay begins with your introduction. It is important to capture your
        reader’s interest, so make sure you have an attention-grabbing hook. Your
        introduction is a paragraph that provides your reader with the relevant
        general background information on your topic. Using this context to ease
        your reader into the subject, you should then state your thesis statement.
        This is the main focus of your essay. A thesis statement is not a fact but
        something that can be discussed and analyzed. After stating your thesis,
        you should include an organizational statement. This functions as a
        roadmap that introduces the points you will make to support your thesis in
        the order in which they will appear in your body paragraphs.
      </p>

      <br />

      <h3>Body Paragraphs:</h3>

      <p>
        Body paragraphs are the bulk of your essay that provides substance to your
        argument with core analysis. These paragraphs are composed of points to
        support your thesis statement. It is helpful to separate your main claims
        into different paragraphs, making each claim a topic sentence. A topic
        sentence begins a body paragraph, introducing what your paragraph will be
        about. You must provide evidence (such as researched quotes or statistics)
        to back up and explore your topic sentence. You must provide the
        appropriate references for any evidence you cite. The evidence provided
        must be analyzed to explain how this idea proves or backs up your thesis.
        It is important that everything in your essay should be relevant to your
        thesis. This link is made at the end of the paragraph, tying back to the
        thesis statement and leading into the next point as a transition sentence.
        It is important to cite and use transition words in your essay to help
        create a smooth flow of your ideas. You may have as many body paragraphs
        as you’d like, but each body paragraph will follow the same format
        (beginning with a topic sentence, providing evidence and analysis to back
        up this claim, before linking your idea with your thesis statement).
      </p>

      <br />

      <h3>Conclusion</h3>

      <p>
        Your conclusion is the wrap-up of your essay. Here you must summarize the
        main points of your essay. You are to review your topic sentences and main
        argument, but you must not introduce any new ideas to your essay at this
        stage. Instead, re-emphasize your thesis statement and how your evidence
        and analysis support your points. In your concluding thoughts, you could
        appeal to the readers to see that you have come to a logical conclusion.
        You could also show why your argument matters, or simply end your essay
        with a memorable final statement.
      </p>

      <br />

      <h3>References:</h3>

      <p>
        If you have included evidence via in-text citations in your essay, you
        must cite it fully in a References section at the end of your essay. It is
        recommended to use either APA or MLA citations.
      </p>

      <br />
      <br />

      <h3>Structure:</h3>

      <p>An essay can be broken down into three main sections.</p>

      <br />

      <h3>Introduction</h3>

      <ul>
        <li>Background information</li>
        <li>Thesis statement</li>
        <li>Organizational statement/roadmap</li>
      </ul>

      <br />

      <h3>Body Paragraphs (repeat as many times as needed)</h3>

      <ul>
        <li>Topic Sentence</li>
        <li>Evidence</li>
        <li>Analysis</li>
        <li>Link to the thesis statement</li>
      </ul>

      <br />

      <h3>Conclusion</h3>

      <ul>
        <li>To sum up the main points,</li>
        <li>Then tie it back to the thesis statement.</li>
        <li>Final thoughts</li>
      </ul>

      <br />

      <p>
        If needed, include a references section at the end for any works cited.
      </p>



      </div>`
    },
    {
      name: "Business Letter",
      data: `<div style={{margin:"10px"}}>
      <section>
      <h3>Your Company's Letterhead</h3>
      <p>
        If applicable, include your company's letterhead at the top of the
        letter, which typically features the company's logo, name, address, and
        contact information.
      </p>

      <h3>Date</h3>
      <p>
        Below the letterhead or your address, add the current date. Format it as
        Month Day, Year (e.g., August 15, 2023).
      </p>

      <h3>Recipient's Address</h3>
      <p>
        Leave a few lines and then include the recipient's name, title, company
        name, street address, city, and postal code.
      </p>

      <h3>Salutation</h3>
      <p>
        Start with a formal salutation, addressing the recipient with their
        appropriate title and last name (e.g., 'Dear Mr. Smith,').
      </p>

      <h3>Body of the Letter</h3>
      <p>
        In the body of the letter, maintain a professional and formal tone.
        Address the purpose of the letter, provide details, and communicate your
        message clearly.
      </p>

      <h3>Closing</h3>
      <p>
        Close the letter with a formal closing (e.g., 'Sincerely,' 'Yours
        faithfully,') followed by your full name and title.
      </p>

      <h3>Signature (If Applicable)</h3>
      <p>
        Leave space for your physical signature above your typed name. If the
        letter is being sent digitally, you can skip this step.
      </p>

      <h3>Enclosures (If Applicable)</h3>
      <p>
        If you're enclosing additional documents or materials, list them below
        your signature or closing.
      </p>
    </section>
      </div>
      `

    },
    {
      name: "Meeting-Notes",
      data: `
      <div style={{margin:"10px"}}>
      <!-- Meeting Details -->
<h3>Meeting Date:</h3>
<p>[Insert Meeting Date]</p>

<h3>Time:</h3>
<p>[Insert Meeting Time]</p>

<h3>Location:</h3>
<p>[Insert Meeting Location, if applicable]</p>

<!-- List of Attendees -->
<h3>List of Attendees:</h3>
<p>
  Attendant's name<br />
  Attendant's name<br />
  Attendant's name
</p>

<!-- Agenda -->
<h3>Agenda:</h3>
<p>
  <em>Topic 1:</em> [Insert Topic 1]<br />
  <em>Summary:</em> [Insert Summary]<br />
  <em>Discussion:</em> [Insert Discussion]<br />
  <em>Decisions:</em> [Insert Decisions]<br />

  <em>Topic 2:</em> [Insert Topic 2]<br />
  <em>Summary:</em> [Insert Summary]<br />
  <em>Discussion:</em> [Insert Discussion]<br />
  <em>Decisions:</em> [Insert Decisions]<br />

  <em>Topic 3:</em> [Insert Topic 3]<br />
  <em>Summary:</em> [Insert Summary]<br />
  <em>Discussion:</em> [Insert Discussion]<br />
  <em>Decisions:</em> [Insert Decisions]
</p>

<!-- Discussion and Decisions -->
<h3>Discussion and Decisions:</h3>
<p>
  Provide a summary of the discussions for each agenda item. Include key points, viewpoints, and decisions made.
</p>

<!-- Action Items -->
<h3>Action Items:</h3>
<p>
  - [Action Item 1: Description, Assigned to, Due Date]<br />
  - [Action Item 2: Description, Assigned to, Due Date]<br />
  - [Action Item 3: Description, Assigned to, Due Date]
</p>

<!-- Next Steps -->
<h3>Next Steps:</h3>
<p>
  Outline the next steps to be taken after the meeting, including tasks, responsibilities, and timelines.
</p>

<!-- Open Issues -->
<h3>Open Issues:</h3>
<p>
  Note any issues or topics that require further discussion or resolution. Specify who will address these issues and when.
</p>

<!-- Additional Notes -->
<h3>Additional Notes:</h3>
<p>
  Include any additional notes, comments, or observations relevant to the meeting.
</p>

<!-- Upcoming Meetings -->
<h3>Upcoming Meetings:</h3>
<p>
  Mention the dates and times of upcoming meetings related to the discussed topics, if applicable.
</p>

      </div>

      `
    },
    {
      name: "Article",
      data: `

      <div style={{margin:"10px"}}>
      <!-- Introduction -->
<h3>Introduction</h3>
<p>
  The introduction should engage the reader and provide an overview of the article's main topic. Start with a hook, present the key issue, and state the purpose or thesis of the article.
</p>

<!-- Main Content -->
<h3>Main Content</h3>
<p>
  This section is the heart of your article. Organize the content based on the type of article you're writing:
</p>

<!-- News/Feature Article -->
<h3>News/Feature Article:</h3>
<p>
  Provide relevant facts, details, quotes, and anecdotes to present the story or topic.
</p>

<!-- Opinion/Editorial Article -->
<h3>Opinion/Editorial Article:</h3>
<p>
  Present your opinion, supported by logical reasoning, evidence, and examples.
</p>

<!-- Review Article -->
<h3>Review Article:</h3>
<p>
  Evaluate the subject of the review objectively, discussing its strengths, weaknesses, and overall quality.
</p>

<!-- Research/How-To Article -->
<h3>Research/How-To Article:</h3>
<p>
  Share research findings, instructions, tips, or information on the chosen topic.
</p>

<!-- Interview/Profile Article -->
<h3>Interview/Profile Article:</h3>
<p>
  Share insights from the interviewee or profiled individual, focusing on their experiences, expertise, and perspectives.
</p>

<!-- Conclusion -->
<h3>Conclusion</h3>
<p>
  Summarize the main points of the article and reiterate its significance. Conclude with a thought-provoking statement, a call to action, or a reflection on the topic discussed.
</p>

<!-- Additional Sections (Optional) -->
<h3>Additional Sections (Optional)</h3>
<p>
  Depending on the article type, you might include additional sections such as:
</p>

<!-- References/Citations -->
<h3>References/Citations:</h3>
<p>
  List sources cited or referenced in the article.
</p>

<!-- Author Bio -->
<h3>Author Bio:</h3>
<p>
  Provide a brief biography of the author, including relevant credentials.
</p>

<!-- Visuals -->
<h3>Visuals:</h3>
<p>
  Include images, graphs, charts, or infographics to enhance the content.
</p>

      </div>
      `
    },
    {
      name: "Blog",
      data: `

      <div style={{margin:"10px"}}>

      <h3>The Blog Post</h3>
      <p>
        This is a classic blog post template, often known as the 'Listicle'. It
        usually comprises a list of anywhere between 10–30 items that have a
        reason to be listed (such as tips, techniques, myths, strategies, etc.).
        This type of list is usually used for displaying non-chronological
        information.
      </p>

      <!-- To use this template -->
      <h3>To use this template:</h3>
      <p>
        Your title should be numbered for the number of items listed in the
        article. There must be a specific benefit or purpose for your article to
        make it compelling. Your title should be attention-grabbing. Possible
        examples:
      </p>

      <!-- Title Examples -->
      <ul>
        <li>'X Important Tips to Achieve [Desired Outcome]'</li>
        <li>
          'X Reasons Why [Problem] Still Persists Despite All Your Attempts'
        </li>
        <li>'X Myths You Need to Stop Believing'</li>
      </ul>

      <!-- Introduction -->
      <h3>Introduction</h3>
      <p>
        Your article should begin by establishing trust with the reader by
        introducing the problem the reader has and proving the benefit the reader
        will get from reading your article. Your introduction should be as short
        as possible. It is advised to include a linked table of contents with jump
        links so readers can skip right to the tip that interests them if they
        would like to.
      </p>

      <!-- Subheadings -->
      <h3>Subheadings</h3>
      <p>
        These are important to give readers an overview of what that specific
        section is about. They also break up your content into manageable chunks.
        Subheadings should be descriptive and reveal a clear benefit. This will
        boost the perceived value of each section to help grab readers’ attention
        to read the section. It is up to you to choose whether or not to number
        your subheadings. Subheadings should be in head level 2 format.
      </p>

      <!-- Action Items -->
      <h3>Action Items</h3>
      <p>
        Action items are sometimes overlooked in how important they can be. These
        are the steps a reader will have to take to complete or achieve each item
        on your list. Including action items is simple: just include the
        step-by-step information to help readers follow your steps. Often it is
        helpful to include photos or video visuals.
      </p>

      <!-- Conclusion -->
      <h3>Conclusion</h3>
      <p>
        It is important to wrap up your blog post. You can decide how you want to
        do this, but most lists end by listing a few more final tips. Your
        conclusion should leave readers wanting to actually use the information
        they have received from your blog. You can also encourage them to interact
        with your post, such as liking, sharing, and commenting.
      </p>

      </div>
      `
    },
    {
      name: "Appendix",
      data: `
      <div style={{margin:"10px"}}>


      <!-- Appendix A: Protocol -->
      <h2>Appendix A: Protocol</h2>
      <p>
        This example shows how you could format a protocol for your research
        paper, thesis, or dissertation.
      </p>

      <!-- Materials -->
      <h3>Materials</h3>
      <p>
        The materials for this study can be subdivided into five different types:
      </p>
      <ol>
        <li>Sustained vowel [a:] by COPD speakers during exacerbation;</li>
        <li>Sustained vowel [a:] by COPD speakers during stable COPD;</li>
        <li>Storytelling of ‘The King’ by COPD speakers during exacerbation;</li>
        <li>Storytelling of ‘The King’ by COPD speakers in stable condition;</li>
        <li>Storytelling of ‘Mary and Adam’ by healthy speakers.</li>
      </ol>

      <!-- Type 1: Sustained vowels -->
      <h3>Type 1: Sustained vowels</h3>
      <p>
        The sustained vowels were recorded shortly before or after reading aloud
        ‘The King’. The vowels were isolated and saved as a separate recording,
        but this type of speech did not require further annotation before
        analysis.
      </p>

      <!-- Type 1-4: Storytelling of COPD speakers and healthy speakers -->
      <h3>Type 1-4: Storytelling of COPD speakers and healthy speakers</h3>
      <p>
        Speech types 1-4 were prepared and annotated following the same steps:
      </p>
      <ul>
        <li>
          Each file was clipped to remove the instructions preceding the reading
          task and the commentary after the reading task.
        </li>
        <li>
          The files were then converted using Praat, changing the sample size from
          44 kHz to 16 kHz to prepare the files for forced alignment.
        </li>
        <li>
          The transcript of each file was added in the first interval tier
          ‘transcript’ to prepare the files for forced alignment.
        </li>
        <li>
          Each file was processed using the forced aligner according to its manual
          (Xue, De Reus, & Merkus, 2019; see Appendix D), creating two additional
          interval tiers ‘wordsegmentation’ and ‘phoneticsegmentation’ containing
          the segmentations of the transcripts on word and phoneme level (in
          SAMPA).
        </li>
        <li>
          These segmentations on both levels were then manually checked and
          corrected.
        </li>
        <li>
          After finalizing the segmentation process, three more tiers were
          manually added to each file for the annotation process. The first
          (interval) tier ‘respiratory’ was added for respiratory-related
          annotations, such as ‘inhalation’, ‘exhalation’, ‘pause’. This way,
          information about the respiratory pattern could be obtained.
        </li>
        <li>
          The second (point) tier ‘speakernoise’ was added for voice
          quality-related annotations, such as ‘cough’, ‘creaky’, ‘slimy’, to
          obtain information regarding the voice quality of the speaker. A point
          tier was preferred over an interval tier because the study focused on
          the number of occurrences.
        </li>
        <li>
          The third (interval) tier ‘commentary’ was added to clarify the speaker
          noises or to keep a record of additional observations. The respiratory
          tier and the speakernoise tier were annotated separately.
        </li>
        <li>
          The first tier to be annotated was the respiratory tier. Each silence
          interval was studied to determine if the speaker was inhaling (in),
          exhaling (ex), or if there was an audible pause (p). This way, the
          transcript could be subdivided into different ‘breathing groups’. The
          commentary tier was used to specify the annotations.
        </li>
        <li>
          The second tier to be annotated was the speakernoise tier. The fragment
          was replayed to obtain information about the condition of the voice.
          Audible disturbances, such as hoarseness, creakiness, and sliminess of
          the voice, were addressed using the speakernoise tier to indicate the
          occurrence, and the commentary tier to describe the nature of the
          disturbance.
        </li>
        <li>
          The fragment was then replayed a final time to check the annotations.
        </li>
      </ul>

      <!-- Appendix B: Survey Questions -->
      <h2>Appendix B: Survey Questions</h2>
      <p>
        This example shows how you could format survey questions for your research
        paper, thesis, or dissertation.
      </p>

      <!-- General questions -->
      <h3>General questions</h3>
      <ol>
        <li>What is your age?</li>
        <li>
          What is the gender you identify with?
          <ul>
            <li>Female</li>
            <li>Male</li>
            <li>Non-binary</li>
            <li>Other</li>
            <li>Don’t want to say</li>
          </ul>
        </li>
        <li>
          What is the highest degree or level of school you have completed? If
          currently enrolled, the highest degree received.
          <ul>
            <li>...</li>
            <li>....</li>
            <li>...</li>
          </ul>
        </li>
      </ol>



      <!-- Appendix C: Interview Transcript 1 -->
      <h2>Appendix C: Interview Transcript 1</h2>
      <p>
        Interviewer: Thank you very much for your time today and for agreeing to
        participate in this interview. May I have your consent to video-record?
      </p>
      <p>Participant 1: Yes.</p>
      <p>Interviewer: Great, thank you. I’ll begin the recording now.</p>
      <p>Participant 1: Sounds good.</p>
      <p>
        Interviewer: Okay, we’re ready to get started. My first question is about
        what you would say your motivation was when you first decided to volunteer
        for the Army.
      </p>
      <p>
        Participant 1: I wouldn’t say I had motivation, I was against the war. I
        was drafted, which meant that I had to go regardless of if I wanted to. I
        had recently gotten married to my first wife and was enjoying helping my
        dad out at the family hardware store, but when my number got called up I
        had to go.
      </p>

      <!-- Appendix C: Interview Transcript 2 -->
      <h2>Appendix C: Interview Transcript 2</h2>
      <p>
        Interviewer: Thank you very much for your time today and for agreeing to
        participate in this interview. May I have your consent to video-record?
      </p>
      <p>Participant 2: I would prefer that you only audio-recorded.</p>
      <p>
        Interviewer: Okay, no problem, look, you can see here I am just pressing
        audio, not video.
      </p>
      <p>Participant 2: I appreciate that.</p>
      <p>
        Interviewer: Okay, so let’s get started. My first question is about what
        you would say your motivation was when you first decided to join the Army.
      </p>
      <p>
        Participant 2: Well, the war in Vietnam had just started, and my twin
        brother and I were freshmen in college at the time. We decided to join on
        our own terms, rather than wait to be drafted. He ended up in the Airborne
        Division, while I ended up in the Cavalry Division.
      </p>


      </div>
      `
    }

  ]






  const history = useHistory()
  const dispatch = useDispatch();
  const [selectedTemplateName, setSelectedTemplateName] = useState(null)
  const [selectedTemplateData, setSelectedTemplateData] = useState(null);

  const filteredData =
    (selectedCategory === 'All Templates'
      ? templateData
      : templateData.filter((template) => template.type === selectedCategory));


  const handleImageClick = (templateName) => {
    setSelectedTemplateName(templateName)
    console.log("templateName_", templateName)
    // Navigate to the CoAuthorNewProjectPage with the template name in the URL
    // history.push(`/coauthor-newproject/${templateName}`)
    dispatch(setTemplateName(templateName));
    history.push(`/coauthor-newproject`)
  };


  const handleCardHover = (event) => {
    event.currentTarget.style.transform = 'scale(1.1)';
    event.currentTarget.style.transition = 'transform 0.3s';
  };

  const handleCardLeave = (event) => {
    event.currentTarget.style.transform = 'scale(1)';
  };




  const handlePreviewClick = (templateName) => {
    // Find the template data based on templateName
    // console.log("templateName",templateName,templateData1)
    const templateData = templateData1.filter((data) => data.name === templateName);

    console.log(templateData)
    setSelectedTemplateName(templateName);

    // Check if a template was found
    if (templateData.length > 0) {
      // Set the selected template data
      setSelectedTemplateData(templateData[0].data)



    } else {
      // Handle the case when the template is not found
      console.error(`Template not found for name: ${templateName}`);
    }

    // setSelectedTemplateData(template);

    setOpenModal(true);


  };







  // State to manage the modal open/close
  const [openModal, setOpenModal] = React.useState(false);


  return (
    <Container style={{maxHeight: "600px", overflowY: "auto"}}>
      <Grid container spacing={2}>
        {filteredData.map((template, index) => (
          <Grid key={index} item xs={12} sm={6} md={3}>
            <div
              style={{
                margin: '0 10px',
                marginTop: '10px',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Card
                style={{
                  height: 170,
                  width: 120,
                  display: 'flex',
                  flexDirection: 'column',
                  cursor: 'pointer',
                  overflow: 'hidden',
                }}
                // onClick={() => handleImageClick(template.name)}
                onMouseOver={handleCardHover}
                onMouseLeave={handleCardLeave}
              >
                <CardMedia
                  component="img"
                  alt={template.name}
                  height="140"
                  image={template.imageUrl}
                  onClick={() => handleImageClick(template.name)}
                />
                <Button onClick={() => handlePreviewClick(template.name, template.data)} variant="contained" color="primary">
                {/* <Button onClick={() => handlePreviewClick(template.name, template.data)} className='FilledDarkBtn'> */}
                  Preview
                </Button>
              </Card>
              <Typography variant="body2" component="p" style={{ marginTop: '10px' }}>
                <b className='headingh2'>{template.name}</b>
              </Typography>
            </div>
          </Grid>
        ))}
      </Grid>

      {/* Modal for Preview */}
      <TemplatePreviewModal handleImageClick={handleImageClick} open={openModal} onClose={() => setOpenModal(false)} selectedTemplateName={selectedTemplateName} selectedTemplateData={selectedTemplateData} />
    </Container>
  );
};

// TemplatePreviewModal component
const TemplatePreviewModal = ({ open, onClose, selectedTemplateName, selectedTemplateData, handleImageClick }) => {

  const getTemplateData = () => {
    // Find the template based on selectedTemplateName or selectedTemplateData
    const selectedTemplate = templateData.find(
      (template) =>
        template.name === selectedTemplateName && template.data === selectedTemplateData
    );

    return selectedTemplate ? selectedTemplate.data : '';
  };



  return (
    <Modal
      keepMounted
      open={open}
      onClose={onClose}
      // aria-labelledby="template-preview-modal-title"
      // aria-describedby="template-preview-modal-description"
      style={{ backgroundColor: 'transparent' }}
    >
      <Box className="boxStyle">
        <div className='changeBg' style={{ height: '10%', overflowY: 'auto', fontSize: "25px", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <b className='headingh2'>{selectedTemplateName}</b>
        </div>
        <div className='innerboxStyle'>
            <div style={{ padding: "30px", height: '100%', overflowY: 'auto', backgroundColor: "white" }}
              dangerouslySetInnerHTML={{ __html: selectedTemplateData }} >
              {/* Preview Data: {selectedTemplateData} */}
            </div>
        </div>
        <div className='changeBg' style={{ height: "10%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {getTemplateData()}
          <Button onClick={onClose} style={{ margin: "10px" }} 
          // className='FilledDarkBtn'
          variant="contained" color="primary"
          >
            Cancel
          </Button>
          <Button onClick={() => handleImageClick(selectedTemplateName)} style={{margin: "10px" }} 
          // className='FilledDarkBtn'
          variant="contained" color="primary"
          >
            Use Template
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default CoAuthor;