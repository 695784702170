import React, { useState } from "react";
import Loader from "../utils/loader";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import image1 from "../static/Group5702x.png";
import bgImage from "../static/Group244.png";
import { toast, ToastContainer } from "react-toastify";
import CryptoJS from 'crypto-js';
import authService from "../services/authService.js";
import { Link, withRouter } from "react-router-dom";
// import FormControl from "@material-ui/core/FormControl";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import "./LoginForm.css";
import { useEffect } from "react";
import { connect } from "react-redux";
import { setCurrentUser } from "../redux/user/user.actions";
import { setUserFeatures } from "../redux/features/features.action";
import { getHomeCMS } from "../services/adminServices";
import { useDispatch, useSelector } from 'react-redux';
import {
    Card,
    InputGroup,
    FormControl,
    Form,
    Button,
    ButtonGroup,
    Carousel,
} from "react-bootstrap";
import loginBackground from "../static/loginBackground.png";
import Login1 from "../static/login-3.png";
import Login2 from "../static/login-2.png";
import Login3 from "../static/login-1.png";
import PetLogo from "../static/POET_Logo_Mid.png"
import googleSignin from "../static/signup_login/googleSignin.png"
import facebookSignin from "../static/signup_login/facebookSignin.png"
import microsoft365Signin from "../static/signup_login/microsoft365Signin.png"
import InputBoxMui from "./ReusableComponents/InputBoxMui.jsx";
import CheckboxMui from "./ReusableComponents/CheckBoxMui.jsx";
import InputBoxPasswordMui from "./ReusableComponents/InputBoxPasswordMui.jsx";
import configdata from "../services/config.json";
import { useParams } from "react-router-dom/cjs/react-router-dom.min.js";
import { userIsConfirmed } from "../services/userConfirmationServices.js";
import ChatServices from "../services/ChatServices.js";
import { addUserLogs } from "../services/userLogServices.js";
import { getFeatures } from "../services/roleServices.js";
import { setRoleFeatures } from "../redux/roleFeatures/roleFeatures.action.js";
import { setSubscriptionPlan } from "../redux/subscriptionPlan/subscriptionPlan.action.js";
// import lastVisitedReducer from "../redux/lastVisited/lastVisited.reducer.js";
import PoetNewlogopen from '../static/newlogoPoet.png'

const useStyles = makeStyles((theme) => ({
    // container: {
    //   display: "flex",
    //   flexWrap: "wrap",
    // },
    // textField: {
    //   marginLeft: theme.spacing(1),
    //   marginRight: theme.spacing(1),
    //   width: "100%",
    // },
    // dense: {
    //   marginTop: 19,
    // },
}));

function Login({ history, setCurrentUser, currentUser, setUserFeatures }) {
    const classes = useStyles();
    const facebookUrl = `${configdata.apiUrl}/api/auth/facebook`;
    let [email, setEmail] = useState("");
    const [values, setValues] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading, showPassword] = useState(false);
    const [isRememberMe, setRemeberMe] = useState(false);
    const [emailerrors, setEmailErrors] = useState("");
    const [passerrors, setPassErrors] = useState("");
    const [cms, setCms] = useState({});
    // const { confirmEmail, token } = useParams()

    const { loginParam } = useParams()
    const dispatch = useDispatch();

    useEffect(() => {
        console.log("loginformvalue", loginParam)
        if (undefined !== loginParam && loginParam !== "") {
            toast.success("Email Verification is Successful")
        }
    }, [loginParam])
    

    // useEffect(() => {
    //     if (undefined !== confirmEmail && undefined !== token) {
    //         handleVerify(confirmEmail, token)
    //     }
    // }, [confirmEmail])

    // const handleVerify = async () => {
    //     setLoading(true)
    //     try {
    //         let response = await userIsConfirmed(confirmEmail, token)
    //         if (response.status == 200) {
    //             // toast.success(response.message)
    //             toast.success("Email Verification is Successful")
    //         }
    //         else {
    //             toast.info(response.message)
    //         }
    //     }
    //     catch (error) {
    //         console.log("error", error)
    //     }
    //     setLoading(false)
    // }

    async function handleEmail(e) {
        let email = e.target.value;
        setEmail(e.target.value)
        console.log(email);
        // var reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const reg = /^[^\s@]+@[^\s@]+\.[^\s@]+[a-zA-Z]$/;
        if (email === "") {
            setEmailErrors("Email is not allowed to be empty.");
        } else {
            setEmailErrors("");
            console.log("email_", reg.test(email));
            if (reg.test(email) === false) {
                setEmailErrors("Invalid Email.");
            } else {
                setEmailErrors(" ");
            }
            //  setPassword(password);
        }
    }
    async function handlePassword(e) {
        let password = e.target.value;
        if (password === "") {
            setPassErrors("Password is not allowed to be empty.");
        } else {
            setPassErrors("");
            setPassword(password);
        }
    }
   

    async function handleLogin() {
        setLoading(true);
        const hashedPassword = CryptoJS.SHA256(password).toString();
        let userEntry = {
            email: email.toLowerCase(),
            password: hashedPassword,
            // password: password,
        };
        let response = await authService.login(userEntry);
        console.log("handleLoginResponse", response)
        if (response.status !== 200) {
            setLoading(false);
            toast.error(response.message);
        }
        else {
            const { user, features, subscriptionPlan } = response;
            setCurrentUser(user);
            setUserFeatures(subscriptionPlan?.features);
            console.log(subscriptionPlan,"subscriptionPlan");
            
            dispatch(setSubscriptionPlan(subscriptionPlan));
          
            // toast.info(response.message);
            // console.log(currentUser);
            localStorage.setItem("organizationId", user.organizationId)
            localStorage.setItem("userId", user._id)
            localStorage.setItem("accountType", user.accountType)
            localStorage.setItem("Role", user.Role)
            localStorage.setItem("token", response.token);
            localStorage.setItem("currentUserEmail", userEntry.email)
            await ChatServices.setStatus(user._id, 'Online');
            if (localStorage.getItem("rememberMe")) {
                localStorage.setItem("token", response.token);
            }
            sessionStorage.setItem("organizationId", user.organizationId)
            sessionStorage.setItem("userId", user._id)
            sessionStorage.setItem("accountType", user.accountType)
            sessionStorage.setItem("Role", user.Role)
            sessionStorage.setItem("token", response.token);
            sessionStorage.setItem("currentUserEmail", userEntry.email)
            if (sessionStorage.getItem("rememberMe")) {
                sessionStorage.setItem("token", response.token);
            }
            sessionStorage.setItem('subscriptionPlan', JSON.stringify(subscriptionPlan));

            const id=user?._id;
            const featureResponse=await getFeatures(id);
            if (featureResponse) {
                console.log(featureResponse?.Features, "featurescheck_");
               
                // Stringify the Features array before saving to sessionStorage
                if(featureResponse?.Features){
                    sessionStorage.setItem("Features", JSON.stringify(featureResponse?.Features));
                    dispatch(setRoleFeatures(featureResponse?.Features));
                }
                
            }

            // Retrieve and parse the Features data from sessionStorage
            if(featureResponse?.Features){
                const featureData = JSON.parse(sessionStorage.getItem("Features"));
                console.log(featureData, "featureData");
            }
           

            try {
                const data = {
                    userId : user._id,
                    organizationId : user.organizationId,
                    actionType : "login"
                }
                const addLogs = await addUserLogs(data)
                if(addLogs.status !== 200) {
                    toast.error(addLogs.message)
                }
            } catch (error) {
                toast.error("Error in saving user logs.")
            }


            // const user = await authService.getCurrentUser();
            setLoading(false);
            if (user.isAdmin === true && user.isSubAdmin === false) {
                history.push({
                    pathname: `/admindashboard`,
                });
            } else if (user.isAdmin === false && user.isSubAdmin === false) {
                history.push({
                    pathname: `/dashboard`,
                    // pathname: `/otpverification`,
                    // pathname:user.is2Factor?`/otpverification`:`/dashboard`,
                    state: user
                });
            } else {
                history.push({
                    // pathname: `/subAdminManageUser`,
                    pathname: `/subAdminDashboard`,
                });
            }
        }
    }

    const getCms = async () => {
        let cms = await getHomeCMS();
        setCms(cms.data);
        console.log(cms);
    };

    useEffect(() => {
        if (localStorage.getItem("rememberMe")) {
            history.push("/login");
        }
        getCms();
        // return () => toast.dismiss();
    }, []);

    function _handleKeyDown(e) {
        if (e.key === "Enter") {
            handleLogin();
        }
    }

    const handleClickShowPassword = () => {
        setValues({ ...values, showPassword: !values.showPassword });
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const handleRememberMe = (e) => {
        const isRememberMe = e.target.checked;
        // if (isRememberMe) {
        //     localStorage.setItem("rememberMe", true);
        // } else {
        //     localStorage.setItem("rememberMe", false);
        // }
        setRemeberMe(isRememberMe);
    };
    function statusChangeCallback(response) {
        // Called with the results from FB.getLoginStatus().
        console.log("statusChangeCallback");
        console.log(response); // The current login status of the person.
        if (response.status === "connected") {
            // Logged into your webpage and Facebook.
            testAPI();
        } else {
            // Not logged into your webpage or we are unable to tell.
            document.getElementById("status").innerHTML =
                "Please log " + "into this webpage.";
        }
    }

    function checkLoginState() {
        // Called when a person is finished with the Login Button.
        window.FB.getLoginStatus(function (response) {
            // See the onlogin handler
            statusChangeCallback(response);
        });
    }

    // window.fbAsyncInit = function () {
    //     window.FB.init({
    //         appId: "2516433218621372",
    //         cookie: true, // Enable cookies to allow the server to access the session.
    //         xfbml: true, // Parse social plugins on this webpage.
    //         version: "v8.0", // Use this Graph API version for this call.
    //     });

    //     window.FB.getLoginStatus(function (response) {
    //         // Called after the JS SDK has been initialized.
    //         statusChangeCallback(response); // Returns the login status.
    //     });
    // };

    (function (d, s, id) {
        // Load the SDK asynchronously
        var js,
            fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");

    function testAPI() {
        // Testing Graph API after login.  See statusChangeCallback() for when this call is made.
        console.log("Welcome!  Fetching your information.... ");
        window.FB.api("/me", function (response) {
            console.log("Successful login for: " + response.name);
            document.getElementById("status").innerHTML =
                "Thanks for logging in, " + response.name + "!";
        });
    }



    return (
        <div className="container-fluid"
            style={{
                backgroundImage: `url(${Login1})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                height: "auto",
                minHeight: "-webkit-fill-available",
                position: "absolute",
                top: 0,
                left: 0
                // position: "relative"
            }}
        >
            <Loader loading={loading} />
            <div className="row mt-2 px-3 py-2">
                <Link to="/">
                    <img
                        // src={image1}
                        // alt="image1"
                        className="petLogo"
                        alt="Group570"
                        src={PoetNewlogopen}
                        style={{
                            width: "35%",
                            marginTop:"-60px"
                            // height: "10%",
                            // objectFit: "contain",
                        }}
                    ></img>
                </Link>
            </div>

            <div
                className="row justify-content-end"
            >
                <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6 d-flex justify-content-center text-center">
                    <Card
                        className="justify-content-center card-comp px-5 p-4"
                    >
                        <div className="text-center">
                            <h3 className="mainHeading">Login</h3>
                        </div>

                        <div className="mt-3 py-2">
                            <InputBoxMui
                                fullWidth
                                type="email"
                                label="Email"
                                value={email}
                                onChange={(e) => handleEmail(e)}
                                autoComplete="off"
                            // placeholder="Username or Email"
                            />
                            <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
                                {emailerrors}
                            </p>
                        </div>

                        <div className="py-2">
                            <InputBoxPasswordMui
                                fullWidth
                                label="Password"
                                type={values.showPassword ? "text" : "password"}
                                value={values.password}
                                onChange={(e) => handlePassword(e)}
                                autoComplete="off"
                            // placeholder="Password"
                            />
                            <p className="p-2" style={{ color: "red", fontSize: "10px", textAlign: "left" }}>
                                {passerrors}
                            </p>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <CheckboxMui
                                    label="Remember Me"
                                    isChecked={isRememberMe}
                                    onChange={handleRememberMe}
                                />
                            </div>
                            <div className="mt-2">
                                <Link to="/forgotpassword" className="forgotpass" style={{ whiteSpace: "nowrap" }}>
                                    Forgot Password?
                                </Link>
                            </div>
                        </div>

                        <div className="text-center">
                            <Button
                                variant="primary"
                                className="loginBtn"
                                onClick={handleLogin}
                            >
                                Login
                            </Button>
                        </div>



                        <p className="hr-lines py-2">OR</p>

                        <div className="text-center">
                            <Button
                                variant="primary"
                                className="smBtn"
                                href={`${configdata.apiUrl}/auth/google/callback`}
                            >
                                <img
                                    className="smImg"
                                    src={googleSignin}
                                />
                                <a
                                    // href={`${apiUrl}/auth/google`}
                                    className="googlefblink"
                                >Login with <strong>Google</strong></a>

                            </Button>
                        </div>

                        <div className="mt-2 text-center">
                            <Button
                                variant="primary"
                                className="smBtn"
                                href={`${configdata.apiUrl}/auth/facebook`}
                            >
                                <img
                                    className="smImg"
                                    src={facebookSignin}
                                />
                                <a
                                    // href="https://www.professionaleditingtool.com/api/auth/facebook"
                                    // href={`${apiUrl}/api/auth/facebook`}
                                    className="googlefblink"
                                >Login with <strong>Facebook</strong></a>

                            </Button>
                        </div>

                        <div className="mt-2 text-center">
                            <Button
                                variant="primary"
                                className="smBtn"
                                href={`${configdata.apiUrl}/auth/microsoft`}
                            >
                                <img
                                    className="smImg"
                                    src={microsoft365Signin}
                                    style={{ width: '30px' }}
                                />
                                <a className="googlefblink">Login with <strong>Microsoft 365</strong></a>
                            </Button>
                        </div>
                        <div className="text-center mt-2">
                            <p
                                style={{
                                    // fontSize: "10px",
                                    fontSize: "0.9rem",
                                    lineHeight: "1.67",
                                    textAlign: "center",
                                    color: "#43425d",
                                }}
                            >
                                Don't have an account?{" "}
                                <Link
                                    to="/signup"
                                    className="signinLink"
                                    style={{ cursor: "pointer", fontSize: "0.9rem" }}
                                >
                                    Sign-up
                                </Link>
                            </p>
                        </div>

                        <div className="text-center mt-3">
                            <Link to="/privacypolicy" target={"_blank"} className="privacyLink">
                                Term of use. Privacy policy
                            </Link>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    setCurrentUser: (user) => dispatch(setCurrentUser(user)),
    setUserFeatures: (features) => dispatch(setUserFeatures(features)),
    setRoleFeatures : (roleFeatures) => dispatch(setRoleFeatures(roleFeatures)),
    setSubscriptionPlan : (plan) => dispatch(setSubscriptionPlan(plan))
});

export default connect(null, mapDispatchToProps)(withRouter(Login));
