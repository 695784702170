import React from 'react'

const SubscriptionCard = ({planName, price, onEdit, onDelete,isPopup}) => {
  return (
    <>

<div 
// style={styles.card}
>
      <h3 style={styles.planName}>{planName}</h3>
      <div style={styles.actions}>
        {/* <button style={isPopup ? styles.bgEditButton : styles.editButton} onClick={onEdit}>Edit</button>
        <button style={isPopup ? styles.bgDeleteButton : styles.deleteButton} onClick={onDelete}>Delete</button> */}
        <button style={ styles.bgEditButton } onClick={onEdit}>Edit</button>
        <button style={ styles.bgDeleteButton } onClick={onDelete}>Delete</button>
      </div>
    </div>
    </>
  )
}

const styles = {
    card: {
      backgroundColor: '#1976D2',
      color: '#fff',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      textAlign: 'center',
      width: '200px',
    },
    planName: {
      margin: '0',
      fontSize: '18px',
      fontWeight: 'bold',
    },
    price: {
      margin: '10px 0',
      fontSize: '16px',
    },
    actions: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: '30px',
      width:"100%"
    },
    editButton: {
      backgroundColor: '#fff',
      color: '#007bff',
      border: 'none',
      borderRadius: '4px',
      padding: '5px 10px',
      cursor: 'pointer',
      width:"45%"
    },
    bgEditButton: {
      backgroundColor:'#007bff',
      color:'#fff',
      border: 'none',
      borderRadius: '4px',
      padding: '5px 10px',
      cursor: 'pointer',
      width:"45%"
    },
    deleteButton: {
      backgroundColor: '#fff',
      color: '#ff4d4d',
      border: 'none',
      borderRadius: '4px',
      padding: '5px 10px',
      cursor: 'pointer',
      width:"45%"
    },
    bgDeleteButton:{
      backgroundColor: '#fff',
      border:'1px solid #ff4d4d',
      color: '#ff4d4d',
      // border: 'none',
      borderRadius: '4px',
      padding: '5px 10px',
      cursor: 'pointer',
      width:"45%"
    }
  };
  

export default SubscriptionCard